import { ApiLoaderService } from './../../_services/api-loader/api-loader.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-pre-loader',
  templateUrl: './api-pre-loader.component.html',
  styleUrls: ['./api-pre-loader.component.scss']
})
export class ApiPreLoaderComponent implements OnInit {

    loading: boolean;

    constructor(private loaderService: ApiLoaderService) {

      this.loaderService.isLoading.subscribe((v) => {
        this.loading = v;
      });

    }

  ngOnInit(): void {
  }

}
