import { ProfileService } from './../../_services/profile/profile.service';
import { Component, OnInit, Input } from '@angular/core';
import axios from 'axios';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StaticDataService } from 'src/app/_services/static-data/static-data.service';

@Component({
    selector: 'app-profile-list',
    templateUrl: './profile-list.component.html',
    styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
    @Input() profileList: any[];
    @Input() pagination: any;
    objectKeys = Object.keys;

    constructor(
        public profileService: ProfileService,
        public staticData: StaticDataService,
        public storageService: StorageService
    ) {}

    ngOnInit(): void {}

    setViewed(toId) {
        const fromId = this.storageService.getUser().id;
        this.profileService
            .postStateUpdate(fromId, toId, 'VIEWED')
            .subscribe((data) => {});
    }
}
