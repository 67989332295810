<div class="container" id="file-upload-container">
    <div class="row p-3">
        <div class="col-md-6">
            <label for="custom-file-upload" class="filupp">
                <input
                    type="file"
                    (change)="fileChangeEvent($event)"
                    name="attachment-file"
                    id="custom-file-upload"
                />
            </label>
        </div>
        <div class="col-md-6 pt-3">
            <button class="btn btn-success btn-sm" (click)="uploadImage()" style="width: 45%;">
                Upload
            </button>
            <button class="btn btn-danger btn-sm" (click)="closeModal()" style="width: 45%;float: right;">
                Cancel
            </button>
        </div>
    </div>
    <div class="row">
         <div class="col-lg-5 col-md-12">
            <div
                class="image-preview d-flex justify-content-center align-items-center h-100"
            >
                <img [src]="croppedImage" />
            </div>
        </div>
        <div class="col-lg-7 col-md-12 mb-3">
            <div
                id="image-cropper"
                class="d-flex justify-content-center align-items-center"
            >
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4"
                    [resizeToWidth]="230"
                    [imageQuality]="100"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                    style="height:300px"
                >
                </image-cropper>
            </div>
        </div>
       
    </div>
</div>
