<div class="otp-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="otp-content">
                    <h3>OTP Verification</h3>
                    <div class="instruction">
                        Please enter your otp code sent to your mobile device
                    </div>
                    <div class="otp-form-wrapper pt-3">
                        <ng-otp-input
                            (onInputChange)="onOtpChange($event)"
                            [config]="{ length: 6, allowNumbersOnly: true }"
                        ></ng-otp-input>
                        <p class="text-red" *ngIf="otpError">
                            {{ otpErrorMessage }}
                        </p>
                    </div>
                    <div class="text-center mt-4">
                        <p *ngIf="seconds" class="mt-3 reg-hint-txt">
                            If you don't receive the OTP, please wait {{ seconds }}
                        </p>
                        <p *ngIf="!seconds" class="mt-3 reg-hint-txt">
                            Didn't receive the OTP ?
                            <a href="javascript:void(0)" (click)="resendOtp()">
                                Resend
                            </a>
                        </p>
                        <button
                            type="button"
                            (click)="verifiy()"
                            class="btn btn-primary btn-sm otp-btn"
                        >
                            Verify
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button
        type="button"
        class="close close-button topright"
        data-dismiss="modal"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <span aria-hidden="true">×</span>
    </button>
</div>
