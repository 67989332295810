import { StaticDataService } from './../_services/static-data/static-data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ISignUpReauest } from 'src/app/_models';
import { Router } from '@angular/router';
import { StorageService } from './../_services/local-storage/storage.service';
import {
    FormGroup,
    Validators,
    FormBuilder,
    AbstractControl,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { OtpComponent } from './otp/otp.component';
import { IOnBehalf } from '../_models/on-behalf';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    public homeRegForm!: FormGroup;
    isLoggedIn: any = false;
    submitted = false;
    closeResult: string;

    onBehalfList: IOnBehalf[] = [];
    countriesList: IOnBehalf[] = [];
    statesList: IOnBehalf[] = [];
    citiesList: IOnBehalf[] = [];
    qualificationsList: IOnBehalf[] = [];
    professionList: IOnBehalf[] = [];
    religionsList: IOnBehalf[] = [];
    castesList: IOnBehalf[] = [];
    subCastesList: IOnBehalf[] = [];
    economicStatusList: IOnBehalf[] = [];

    castesForSelectedReligion: IOnBehalf[] = [];
    subCastesForSelectedCaste: IOnBehalf[] = [];

    statesForSelectedCountry: IOnBehalf[] = [];
    citiesForSelectedState: IOnBehalf[] = [];

    subCasteList: any = [
        { id: 1, name: 'Tamil Yadav', icon: 'icon1' },
        { id: 2, name: 'Golla(Yadav) Naidu', icon: 'icon1' },
        { id: 3, name: 'Anuppa Goundar (Krishna Golla)', icon: 'icon1' },
        { id: 4, name: 'Asthanthra Golla Nayakar', icon: 'icon1' },
        { id: 5, name: 'Rajakambala Golla Nayakar', icon: 'icon1' },
        { id: 6, name: 'Mond,Pokkisa,Tumati,Puja', icon: 'icon1' },
        { id: 7, name: 'Krishna Vaka', icon: 'icon1' },
        { id: 8, name: 'Kuruba Goundar', icon: 'icon1' },
    ];

    constructor(
        private formBuilder: FormBuilder,
        private storegeService: StorageService,
        private router: Router,
        private authService: AuthService,
        private staticDataService: StaticDataService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.isLoggedIn = false;
        let token = this.storegeService.getToken();
        if (token) this.router.navigate(['/my-dashboard']);
        this.homeRegForm = this.formBuilder.group({
            createProfileFor: ['1', [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: [''],
            gender: ['', [Validators.required]],
            dateOfBirth: ['', [Validators.required]],
            motherTongue: ['', [Validators.required]],
            caste: ['', [Validators.required]],
            subCaste: ['', [Validators.required]],
            education: ['', [Validators.required]],
            profession: ['', [Validators.required]],
            currentCountry: ['', [Validators.required]],
            currentState: ['', [Validators.required]],
            currentDistrict: ['', [Validators.required]],
            monthlyIncome: ['', [Validators.required]],
            economicStatus: ['', [Validators.required]],
            mobile: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(10),
                    Validators.minLength(10),
                    Validators.pattern('^[0-9]*$'),
                ],
            ],
        });
        this.loadInitialValues()
    }

    loadInitialValues() {
        const listNames = ['onBehalOf', 'countries', 'states', 'cities', 'qualifications', 'profession', 'religions', 'castes', 'subCastes', 'economicStatus'];
        for (const listName of listNames) {
            const methodName = 'get' + listName.charAt(0).toUpperCase() + listName.slice(1);
            if (!sessionStorage.getItem(listName))
                this.staticDataService[methodName]().subscribe((data) => {
                    this[listName + 'List'] = data;
                    console.log(listName + 'List', data);
                    sessionStorage.setItem(listName, JSON.stringify(data));
                });
            else this[listName + 'List'] = JSON.parse(sessionStorage.getItem(listName));

        }
    }

    onReligionChange() {
        const { motherTongue } = this.homeRegForm.value;
        this.homeRegForm.controls.caste.setValue('');
        this.homeRegForm.controls.subCaste.setValue('');
        this.subCastesForSelectedCaste = [];
        this.castesForSelectedReligion = this.castesList.filter((caste: any) => caste.religionId == motherTongue);
    }

    onCasteChange() {
        const { caste } = this.homeRegForm.value;
        this.homeRegForm.controls.subCaste.setValue('');
        this.subCastesForSelectedCaste = this.subCastesList.filter((subCaste: any) => subCaste.casteId == caste);
    }

    onCountryChange() {
        const { currentCountry } = this.homeRegForm.value;
        this.homeRegForm.controls.currentState.setValue('');
        this.homeRegForm.controls.currentDistrict.setValue('');
        this.citiesForSelectedState = [];
        this.statesForSelectedCountry = this.statesList.filter((state: any) => state.countryId == currentCountry);
    }

    onStateChange() {
        const { currentState } = this.homeRegForm.value;
        this.homeRegForm.controls.currentDistrict.setValue('');
        this.citiesForSelectedState = this.citiesList.filter((city: any) => city.stateId == currentState);
    }

    get f(): { [key: string]: AbstractControl } {
        return this.homeRegForm.controls;
    }

    onHomeRegFormSubmit(): void {
        this.submitted = true;
        if (this.homeRegForm.invalid) return;
        const { firstName, lastName, mobile } = this.homeRegForm.value;
        const { gender, createProfileFor, dateOfBirth } = this.homeRegForm.value;
        const {
            motherTongue,
            caste,
            subCaste,
            qualification,
            profession,
            country,
            state,
            district,
            salaryDetails
        } = this.homeRegForm.value;
        const onBehalf = createProfileFor;
        const password = '';
        const email = mobile + '@mail.com';
        const countryCode: string = '+91';
        const roles: string[] = ['user'];
        const username: string = mobile;
        const formData: any = {
            onBehalf,
            username,
            firstName,
            lastName,
            gender,
            email,
            mobile,
            password,
            roles,
            dateOfBirth,
            countryCode,
            deviceType: 'WEB',
            motherTongue,
            caste,
            subCaste,
            qualification,
            profession,
            country,
            state,
            district,
            salaryDetails
        };
        this.authService.sendOtp(mobile).subscribe((response) => {
            if (response) this.open(formData);
        });
    }

    open(formData: ISignUpReauest) {
        const modalRef = this.modalService.open(OtpComponent, {
            windowClass: 'modal-mini',
            size: 'lg',
            centered: true,
        });
        modalRef.componentInstance.userData = formData;
        modalRef.result.then(
            (result) => (this.closeResult = `Closed with: ${result}`),
            (reason) => this.setDismissReason(reason)
        );
    }

    private setDismissReason(reason: ModalDismissReasons): void {
        let reasionText = '';
        if (reason === ModalDismissReasons.ESC) reasionText = 'By ESC';
        else if (reason === ModalDismissReasons.BACKDROP_CLICK)
            reasionText = 'by Backdrop';
        else reasionText = `with: ${reason}`;
        this.closeResult = reasionText;
    }
}
