import { ProfileService } from '../_services/profile/profile.service';
import { StorageService } from '../_services/local-storage/storage.service';
import { AlertService } from 'src/app/_alert';
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router, UrlSegment } from '@angular/router';
import { StaticDataService } from '../_services/static-data/static-data.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
    selectedItem: string = '';
    constructor(
        private router: Router,
        public profileService: ProfileService,
        private staticDataService: StaticDataService
    ) {
        this.staticDataService.loadStaticDataIfNotExist();
    }

    ngOnInit(): void {
        this.setSelectedTab();
    }

    setSelectedTab() {
        if (this.router.url.includes('manage-profile'))
            this.ChangeTab('v-pills-home');
        if (this.router.url.includes('change-password'))
            this.ChangeTab('v-pills-password');
        if (this.router.url.includes('partner-preference'))
            this.ChangeTab('v-pills-partner');
        if (this.router.url.includes('gallery'))
            this.ChangeTab('v-pills-gallery');
    }

    ChangeTab(tabItem: string) {
        this.selectedItem = tabItem;
    }
}
