import { Component, OnInit } from '@angular/core';
import { IPackage } from 'src/app/_models';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
    packages: IPackage[] = [];
    constructor(
        private storageService: StorageService,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.profileService.getPackages().subscribe((data: IPackage[]) => {
            this.packages = data;
        });
    }
}
