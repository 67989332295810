import { AuthService } from 'src/app/_services/auth/auth.service';
import { Router } from '@angular/router';
import { AlertService } from '../../_alert/alert.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISignUpReauest } from 'src/app/_models';
import { StorageService } from 'src/app/_services/local-storage/storage.service';

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
    @Input() userData: ISignUpReauest;
    public seconds: number = 60;
    constructor(
        public activeModal: NgbActiveModal,
        private alertService: AlertService,
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService,
    ) {}
    otp: string;
    otpError = false;
    otpErrorMessage = '';
    ngOnInit(): void {
        const instance = this;
        const timer = setInterval(() => {
            instance.seconds = instance.seconds - 1;
            if (instance.seconds === 0) clearInterval(timer);
        }, 1000);
    }

    onOtpChange(otp) {
        this.otp = otp;
    }

    resendOtp() {
        this.authService.sendOtp(this.userData.mobile).subscribe((response) => {
            if (response) this.alertService.info('OTP was resent successfully');
            else this.alertService.error('There was an erro to resend OTP');
        });
    }

    verifiy() {
        const userData = { ...this.userData, otp: this.otp };
        if (this.otp) {
            let otpLength = this.otp.length;
            if (otpLength == 6) {
                this.otpError = false;
                this.authService
                    .regOtpVerify(userData)
                    .subscribe((user) => {
                        if(user?.error){
                            this.alertService.error(user?.message, { autoClose: false, keepAfterRouteChange: false });
                            return;
                        }
                        if (user) {
                            this.alertService.success(
                                'Register Successfull, Please Login with Your Credentials!'
                            );
                            this.activeModal.dismiss('After Register');
                            this.storageService.saveUser(user);
                            this.storageService.saveToken(user.accessToken);
                            this.router.navigate(['/my-dashboard']);
                        }
                    });
            } else {
                this.otpErrorMessage = 'Please Enter Full OTP';
                this.otpError = true;
            }
        } else {
            this.otpErrorMessage = 'Please Enter OTP';
            this.otpError = true;
        }
    }
}
