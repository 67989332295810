<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->
<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Payment</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>payment</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->
<!-- <div class="col-md-12">
    <div class="card card-container">
        <form
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
        >
            <div class="form-group">
                <div class="alert alert-danger" role="alert" *ngIf="error">
                    Payment Failed: {{ error }}
                </div>
                <div class="alert alert-success" role="alert" *ngIf="paymentId">
                    Payment Success Payment ID: {{ paymentId }}
                </div>
            </div>
            <div class="form-group">
                <label for="name">Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="name"
                    [(ngModel)]="form.name"
                    required
                    minlength="3"
                    maxlength="20"
                    #name="ngModel"
					disabled="true"
                />
                <div class="alert-danger" *ngIf="f.submitted && name.invalid">
                    <div *ngIf="name.errors.required">Name is required</div>
                    <div *ngIf="name.errors.minlength">
                        Name must be at least 3 characters
                    </div>
                    <div *ngIf="name.errors.maxlength">
                        Name must be at most 20 characters
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="mobile">Mobile</label>
                <input
                    type="number"
                    class="form-control"
                    name="mobile"
                    [(ngModel)]="form.mobile"
                    required
                    minlength="10"
                    maxlength="10"
                    #mobile="ngModel"
					disabled="true"
                />
                <div
                    class="alert alert-danger"
                    role="alert"
                    *ngIf="f.submitted && mobile.invalid"
                >
                    <div *ngIf="mobile.errors.required">Mobile is required</div>
                    <div *ngIf="mobile.errors.minlength || mobile.errors.maxlength">
                        Mobile must be 10 digits
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="amount">Amount</label>
                <input
                    type="number"
                    class="form-control"
                    name="amount"
                    [(ngModel)]="form.amount"
                    required
                    #amount="ngModel"
					disabled="true"
                />
                <div
                    class="alert alert-danger"
                    role="alert"
                    *ngIf="f.submitted && amount.invalid"
                >
                    <div *ngIf="amount.errors.required">Amount is required</div>
                </div>
            </div>
            <div class="form-group"><br />
                <button class="btn btn-primary btn-block form-control">Pay Now</button>
            </div>
        </form>
    </div>
</div> -->
<div class="container">
    <div class="card card-container">
        <div class="form-group">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                Payment Failed: {{ error }}
            </div>
            <div class="alert alert-success" role="alert" *ngIf="paymentId">
                Payment Success Payment ID: {{ paymentId }}
            </div>
        </div>
        <div class="row" *ngIf="user">
            <div class="col-xs-6 col-sm-6 col-md-6">
                <address>
                    <strong>
                        {{ user?.firstName }}
                        {{ user?.lastName }}
                    </strong>
                    <br />
                    ({{ user.countryCode }})
                    {{ user.mobile }}
                </address>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 text-right">
                <address style="text-align: right">
                    <em>{{ currentDate | date : "dd-MMMM-YYYY" }}</em>
                    <br />
                    <em>Receipt #: 34522677W</em>
                </address>
            </div>
        </div>
        <div class="row">
            <div class="text-center">
                <h4>Receipt</h4>
            </div>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Package</th>
                        <th></th>
                        <th>#</th>
                        <th class="text-center">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="col-md-9">
                            <em>
                                {{ package?.name }}
                            </em>
                        </td>
                        <td></td>
                        <td class="col-md-1">1</td>
                        <td class="col-md-1 text-center">
                            {{ package?.price }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td class="text-right">
                            <p>Tax:</p>
                        </td>
                        <td class="text-center">
                            <p>
                                {{ percentage(18, package?.price) }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td class="text-right">
                            <h6><strong>Total: </strong></h6>
                        </td>
                        <td class="text-center text-danger">
                            <h6>
                                <strong>
                                    {{
                                        package?.price +
                                            percentage(18, package?.price)
                                    }}
                                </strong>
                            </h6>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="text-center">
                <button
                    [disabled]="!this.package"
                    (click)="onSubmitToCCAvenue()"
                    class="btn btn-sm btn-success btn-block"
                >
                    Pay Now
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
