import { ISignInResponse } from 'src/app/_models';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { StorageService } from './../_services/local-storage/storage.service';
import { AlertService } from 'src/app/_alert';
import {
    FormGroup,
    Validators,
    AbstractControl,
    FormBuilder,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    submitted = false;
    public currentUser: ISignInResponse;
    selectedItem: string = 'my-matches';
    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private storageService: StorageService,
        public profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.currentUser = this.storageService.getUser();
    }

    ChangeTab(tabItem: string) {
        this.selectedItem = tabItem;
    }
}
