import { ProfileService } from './../../_services/profile/profile.service';
import { User } from './../../_models/user';
import { StaticDataService } from './../../_services/static-data/static-data.service';
import { StorageService } from './../../_services/local-storage/storage.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home-new-profiles',
  templateUrl: './home-new-profiles.component.html',
  styleUrls: ['./home-new-profiles.component.scss']
})
export class HomeNewProfilesComponent implements OnInit {

  newProfiles:User[] = [];
  currentUserId;
  isLoggedIn:any = false;
  token;
  public ObjectKey:any = Object.keys;
  customLoader:boolean = true;
  owlCarousalOptions :any = {
		loop: true,
		margin: 15,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		navText: [
			"<i class='flaticon-left-arrow'></i>",
			"<i class='flaticon-right-arrow'></i>"
		],
        nav: true,
		responsive:{
			0:{
				items: 2,
			},
			600:{
				items: 3,
			},
			1000:{
				items: 5,
			}
		}
	}

  constructor(public storageService:StorageService, public profileService:ProfileService) { }

  ngOnInit(): void {


   /*  this.token = this.storageService.getToken();
    setTimeout(() => {
        if(this.token != '' && this.token != null) {
            this.isLoggedIn = true;

            this.profileService.getAllUsers().subscribe(data=> {
                this.currentUserId = this.storageService.getUser().id;
                let filterGender = (this.storageService.getUser().gender == 'MALE')?'FEMALE':'MALE';
                this.newProfiles = data.filter(x => x.id !=this.currentUserId && x.gender == filterGender);
                this.customLoader = false;
            }, error => {
                this.customLoader = false;
            })
        } else {
            this.customLoader = false;
        }

    },3000);
    */
  }

}
