<div class="row">
    <div
        *ngIf="isAuthUserHasPayment || authUserId === user?.id; else elseBlock"
        class="col-md-8"
    >
        <div class="border p-3 contact-box">
            <div class="d-flex mb-3">
                <i class="bx bx-phone"></i>
                <div>
                    <div class="fs-15 fw-bold mb-1">Contact Number</div>
                    <div class="fw-400">{{ user?.mobile }}</div>
                </div>
            </div>
            <div class="d-flex">
                <i class="bx bx-envelope"></i>
                <div>
                    <div class="fs-15 fw-bold mb-1">Email ID</div>
                    <div class="fw-400">{{ user?.email }}</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseBlock>
        <h5 style="text-align: center">Pament required</h5>
        <h6 class="text-center">
            <a [routerLink]="['/order']">Click here to make payment</a>
        </h6>
    </ng-template>
</div>
