import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import axios from 'axios';
import { IGallery } from 'src/app/_models';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {
    @Input() gallery: IGallery;
    @Output() onDeleteEvent = new EventEmitter<any>();
    src: SafeUrl = '../../../assets/img/default-image.gif';
    userId:number = this.storageService.getUser().id;
    constructor(
        private profileService: ProfileService,
        private domSanitizer: DomSanitizer,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        const url = `galleries/file/${this.gallery.userId}/${this.gallery.fileName}`;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + this.storageService.getToken(),
            },
        };
        axios
            .get(this.profileService.apiUrl + url, headers as any)
            .then((res) => {
                if (res.status === 200 && res?.data?.file) {
                    let src = `data:${this.gallery.fileType};base64, ${res.data.file}`;
                    this.src = this.domSanitizer.bypassSecurityTrustUrl(src);
                }
            });
    }

    handleDelete(galleryId: number) {
        this.onDeleteEvent.emit(galleryId);
    }
}
