import { ProfileService } from './../_services/profile/profile.service';
import { StorageService } from './../_services/local-storage/storage.service';
import { User } from './../_models/user';
import { Component, OnInit } from '@angular/core';
import { FooterStyleTwoComponent } from '../shared/footer-style-two/footer-style-two.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public profileList:User[] = [];
  isLoggedIn;
  objectKeys = Object.keys;
  constructor(private storageService:StorageService, public profileService:ProfileService) { }

  ngOnInit(): void {
    this.profileService.getAllUsers().subscribe(data => {
        let filterGender = (this.storageService.getUser().gender == 'MALE')?'FEMALE':'MALE';
        this.profileList = data.filter(x => x.id != this.storageService.getUser().id && x.gender == filterGender);
    });
    this.isLoggedIn = this.storageService.isLoggedIn()
  }

}
