import { StaticDataService } from './../../_services/static-data/static-data.service';
import { StorageService } from './../../_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-detailed-profile',
    templateUrl: './detailed-profile.component.html',
    styleUrls: ['./detailed-profile.component.scss'],
})
export class DetailedProfileComponent implements OnInit {
    @Input() user;
    profile;
    isAuthUserHasPayment: Boolean = false;
    authUserId: number;
    constructor(
        private profileService: ProfileService,
        private storageService: StorageService,
    ) {
    }

    ngOnInit(): void {
        this.loadProfile();
        this.authUserId = this.storageService.getUser().id;
        this.profileService.getHasPayment(this.authUserId).subscribe((data) => {
            this.isAuthUserHasPayment = data;
        });
    }

    loadProfile() {
        this.profileService
            .getProfileDetailsByUserId(this.user.id)
            .subscribe((data) => {
                this.profile = data;
            });
    }

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
        return age;
    }

    getMotherTongue() {
        return this.user.languages.find((l) => l.type === 'MOTHER_TONGUE')
            ?.language.name;
    }

    getKnownLanguage() {
        return this.user.languages
            .filter((l) => l.type !== 'MOTHER_TONGUE')
            .map((l) => l.language.name)
            .toString();
    }
}
