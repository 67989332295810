<div class="row mt-3 mb-5" *ngIf="objectKeys(profileList).length > 0">
    <div
        class="col-lg-4 col-md-6 col-sm-12"
        *ngFor="let profile of profileList | paginate : pagination"
    >
        <a
            (click)="this.setViewed(profile.id)"
            class="row align-items-start employer-item m-1"
            [routerLink]="['/profile/' + profile.userId]"
            target="_blank"
            style="cursor: hand"
        >
            <div class="col-6">
                <app-profile-image
                    style="margin-left: 10px"
                    [userId]="profile.userId"
                    [gender]="profile.gender"
                ></app-profile-image>
            </div>
            <div class="col-6 p-0">
                <h3 class="mb-1 mt-1">
                    {{ profile.firstName }}
                    {{ profile.lastName }}
                </h3>
                <ul class="mb-0">
                    <li class="reg-date">
                        {{ profile.created_at | datesince }}
                    </li>
                </ul>
                <div class="row align-items-center">
                    <div class="col-md-12 member-details-list">
                        <ul>
                            <li style="font-weight: 600">
                                Reg.No -
                                <span style="color: #fe4a55; font-size: 12px">
                                    {{ profile.memberId }}
                                </span>
                            </li>
                            <li *ngIf="profile.age || profile.motherTongue">
                                <i class="bx bx-message-square-check"></i>
                                {{ profile.age }} yrs,
                                {{ profile.motherTongue }}
                            </li>
                            <li *ngIf="profile.caste || profile.subCaste">
                                <i class="bx bx-message-square-check"></i>
                                {{ profile.caste }},
                                {{ profile.subCaste }}
                            </li>
                            <li
                                *ngIf="
                                    profile.maritalStatus || profile.profession
                                "
                            >
                                <i class="bx bx-message-square-check"></i>
                                {{ profile.maritalStatus }},
                                {{ profile.profession }}
                            </li>
                            <li *ngIf="profile.city || profile.state">
                                <i class="bx bx-message-square-check"></i>
                                {{ profile.city }},
                                {{ profile.state }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>

<ng-template #default_img let-profile="profileData">
    <img
        style="width: 190px; padding: 10px"
        [src]="
            profile.gender == 'MALE'
                ? 'assets/img/home-two/banners/male.svg'
                : 'assets/img/home-two/banners/female.svg'
        "
        alt="Employer"
    />
</ng-template>
<ng-template #profileImg let-profile="profileData">
    <img [src]="profileService.apiBase + profile.image" alt="Employer" />
</ng-template>
