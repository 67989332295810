<div class="container mt-10">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Partner Preference</h5>
            </div>
            <div class="card-body">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <th>General</th>
                            <td>{{ expectations.general }}</td>
            
                            <th>Residence Country</th>
                            <td>
                                {{ expectations.residence_country }}
                            </td>
                        </tr>
                        <tr>
                            <th>Height</th>
                            <td>{{ expectations.height }}</td>
            
                            <th>Weight</th>
                            <td>{{ expectations.weight }}</td>
                        </tr>
            
                        <tr>
                            <th>Marital Status</th>
                            <td>{{ expectations.maritalStatus }}</td>
            
                            <th>Children Acceptable</th>
                            <td>dose_not_matter</td>
                        </tr>
            
                        <tr>
                            <th>Religion</th>
                            <td>{{ expectations.religion }}</td>
            
                            <th>Caste</th>
                            <td>{{ expectations.caste }}</td>
                        </tr>
            
                        <tr>
                            <th>Sub Caste</th>
                            <td>{{ expectations.subCaste }}</td>
            
                            <th>Language</th>
                            <td>{{ expectations.language }}</td>
                        </tr>
            
                        <tr>
                            <th>Education</th>
                            <td>{{ expectations.education }}</td>
            
                            <th>Profession</th>
                            <td>{{ expectations.profession }}</td>
                        </tr>
            
                        <tr>
                            <th>Smoking Acceptable</th>
                            <td>{{ expectations.smokingAccept }}</td>
            
                            <th>Drinking Acceptable</th>
                            <td>{{ expectations.drinkingAccept }}</td>
                        </tr>
                        <tr>
                            <th>Diet</th>
                            <td>dose_not_matter</td>
            
                            <th>Body Type</th>
                            <td>{{ expectations.bodyType }}</td>
                        </tr>
                        <tr>
                            <th>Preferred Country</th>
                            <td>
                                {{ expectations.residence_country }}
                            </td>
            
                            <th>preferred_state_id</th>
                            <td>
                                {{ expectations.state }}
                            </td>
                        </tr>
                        <tr>
                            <th>Family Value</th>
                            <td>{{ expectations.familyType }}</td>
            
                            <th>Complexion</th>
                            <td>{{ expectations.complexion }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>