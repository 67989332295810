<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two" *ngIf="user">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <img
                                        [src]="userProfileImage"
                                        class="profile-img"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-12 mt-2">
                                    <h2 *ngIf="user">
                                        {{ user?.firstName }}
                                        {{ user?.lastName }}
                                    </h2>
                                    <ul>
                                        <li *ngIf="user">
                                            <i class="bx bx-id-card"></i>
                                            <span>{{
                                                user?.profile?.memberId
                                            }}</span>
                                        </li>
                                        <li *ngIf="user?.member?.maritalStatus">
                                            <i class="bx bx-heart-square"></i>
                                            {{ profile?.maritalStatus }}
                                        </li>
                                        <li *ngIf="user?.address?.city">
                                            <i class="bx bx-map"></i>
                                            {{ user?.address?.city?.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <i class="bx bx-heart"></i>
                            <p>Express Interest</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="download widget-item">
                        <a
                            class="right mt-2"
                            href="#"
                            (click)="this.updateStatus($event, 'MYBOOK')"
                        >
                            Add To My book
                        </a>
                        <button
                            [disabled]="user?.isInterestSent"
                            class="action-btn mt-2"
                            (click)="this.updateStatus($event, 'SENT')"
                        >
                            {{
                                user?.isInterestSent
                                    ? "ALREADY INTEREST SENT"
                                    : "SEND INTEREST"
                            }}
                        </button>
                        <!-- <a
                            class="right mt-2"
                            href="#"
                            (click)="this.updateStatus($event, 'REJECTED')"
                        >
                            Reject
                        </a> -->
                        <a
                            class="right mt-2"
                            href="#"
                            (click)="this.updateStatus($event, 'REPORTED')"
                        >
                            Report
                        </a>
                        <a
                            class="right mt-2"
                            href="#"
                            (click)="this.updateStatus($event, 'BLOCKED')"
                        >
                            Block
                        </a>
                    </div>
                    <!--<app-similar-profiles></app-similar-profiles>-->
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="profile">
                        <ul
                            style="padding: 0"
                            class="nav nav-tabs justify-content-around mb-3 pb-2"
                            role="tablist"
                        >
                            <li
                                class="nav-item"
                                style="float: left; list-style: none"
                            >
                                <a
                                    class="nav-link active"
                                    style="margin: 0; padding: 5px 11px"
                                    (click)="changeTab('home')"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home"
                                    role="tab"
                                >
                                    <i class="fa fa-user"></i>
                                    Profile
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    class="nav-link"
                                    (click)="changeTab('parner-pref')"
                                    data-bs-toggle="tab"
                                    data-bs-target="#parner-pref"
                                    role="tab"
                                >
                                    <i
                                        class="now-ui-icons shopping_cart-simple"
                                    ></i>
                                    Partner Preference
                                </a>
                            </li> -->
                            <li
                                class="nav-item"
                                style="float: left; list-style: none"
                            >
                                <a
                                    class="nav-link"
                                    style="margin: 0; padding: 5px 11px"
                                    data-bs-toggle="tab"
                                    (click)="changeTab('gallery')"
                                    data-bs-target="#gallery"
                                    role="tab"
                                >
                                    <i class="now-ui-icons shopping_shop"></i>
                                    Gallery
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                style="float: left; list-style: none"
                            >
                                <a
                                    class="nav-link"
                                    style="margin: 0; padding: 5px 11px"
                                    data-bs-toggle="tab"
                                    (click)="changeTab('contact')"
                                    data-bs-target="#contact"
                                    role="tab"
                                >
                                    <i
                                        class="now-ui-icons ui-2_settings-90"
                                    ></i>
                                    Contact
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                style="float: left; list-style: none"
                            >
                                <a
                                    class="nav-link"
                                    style="margin: 0; padding: 5px 11px"
                                    data-bs-toggle="tab"
                                    (click)="changeTab('note')"
                                    data-bs-target="#note"
                                    role="tab"
                                >
                                    <i
                                        class="now-ui-icons ui-2_settings-90"
                                    ></i>
                                    Notes
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content text-left">
                            <div
                                class="tab-pane active"
                                id="home"
                                role="tabpanel"
                                *ngIf="user"
                            >
                                <app-detailed-profile
                                    *ngIf="selectedItem == 'home'"
                                    [user]="user"
                                ></app-detailed-profile>
                            </div>
                            <div
                                class="tab-pane"
                                id="parner-pref"
                                role="tabpanel"
                            >
                                <app-partner-pref
                                    *ngIf="selectedItem == 'parner-pref'"
                                    [user]="user"
                                ></app-partner-pref>
                            </div>
                            <div class="tab-pane" id="gallery" role="tabpanel">
                                <app-profile-gallery
                                    *ngIf="selectedItem == 'gallery'"
                                    [user]="user"
                                ></app-profile-gallery>
                            </div>
                            <div class="tab-pane" id="contact" role="tabpanel">
                                <app-profile-contact
                                    *ngIf="selectedItem == 'contact'"
                                    [user]="user"
                                ></app-profile-contact>
                            </div>
                            <div class="tab-pane" id="note" role="tabpanel">
                                <app-user-note *ngIf="selectedItem == 'note'">
                                </app-user-note>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
