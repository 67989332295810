<div class="job-list-right">
    <div class="job-list-all">
        <div class="search">
            <h3>Search Keywords</h3>

            <form>
                <input type="text" class="form-control" placeholder="Keyword">
                <button type="submit" class="btn"><i class="flaticon-send"></i></button>
            </form>
            <div class="filterSection" *ngIf="ObjectKey(selectedFilters).length > 0">
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <h3 class="mb-0">Filters</h3>
                    <span class="small">CLEAR ALL</span>
                </div>
                <div class="filters mb-4">
                    <ng-container *ngFor="let selectedFilter of selectedFilters">
                        <span (click)="removeFilter(selectedFilter)" class="badge bg-secondary me-2"><span class="me-2">✕</span><span class="filtered-text">{{selectedFilter.name}}</span></span>
                    </ng-container>
                </div>
            </div>
            <h3>Marital Status</h3>

            <form>
                <ul class="job-cmn-cat overflow-auto">
                    <li *ngFor="let mfilter of fltrMaritalStatus">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{mfilter.id}}-{{mfilter.type}}" (change)="updateFilters($event,mfilter)" id="{{mfilter.id}}-{{mfilter.type}}" >
                            <label class="form-check-label" for="{{mfilter.id}}-{{mfilter.type}}">{{mfilter.name}} </label>
                        </div>
                    </li>
                </ul>
            </form>
        </div>

        <div class="search location-list cmn-padding">
            <h3>State List</h3>

            <form>
                <ul class="job-cmn-cat">
                    <li *ngFor="let mfilter of stateFilters">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{mfilter.id}}-{{mfilter.type}}" (change)="updateFilters($event,mfilter)" id="{{mfilter.id}}-{{mfilter.type}}" >
                            <label class="form-check-label" for="{{mfilter.id}}-{{mfilter.type}}">{{mfilter.name}}</label>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
        <div class="search location-list cmn-padding">
            <h3>City List</h3>

            <form>
                <ul class="job-cmn-cat">
                    <li *ngFor="let mfilter of cityFilters">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{mfilter.id}}-{{mfilter.type}}" (change)="updateFilters($event,mfilter)" id="{{mfilter.id}}-{{mfilter.type}}" >
                            <label class="form-check-label" for="{{mfilter.id}}-{{mfilter.type}}">{{mfilter.name}} </label>
                        </div>
                    </li>
                </ul>
            </form>
        </div>

        <div class="search job-type cmn-padding">
            <h3>Sub Caste</h3>
            <form>
                <ul class="job-cmn-cat">
                    <li *ngFor="let mfilter of casteFilters">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{mfilter.id}}-{{mfilter.type}}" (change)="updateFilters($event,mfilter)" id="{{mfilter.id}}-{{mfilter.type}}" >
                            <label class="form-check-label" for="{{mfilter.id}}-{{mfilter.type}}">{{mfilter.name}}</label>
                        </div>
                    </li>
                </ul>
            </form>
        </div>

        <div class="salary cmn-padding">
            <h3>Mother Tongue</h3>

            <form>
                <div class="job-cmn-cat flex-divide">
                    <ul class="left">
                        <li *ngFor="let mfilter of motherTongueFilters">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="{{mfilter.id}}-{{mfilter.type}}" (change)="updateFilters($event,mfilter)" id="{{mfilter.id}}-{{mfilter.type}}" >
                                <label class="form-check-label" for="{{mfilter.id}}-{{mfilter.type}}">{{mfilter.name}} </label>
                            </div>
                        </li>
                    </ul>

                </div>

                <!-- <div class="form-group">
                    <label class="range" for="formControlRangeTwo">$6778 - $9077</label>
                    <input type="range" class="form-control-range" id="formControlRangeTwo">
                </div> -->
            </form>
        </div>

        <div class="date cmn-padding">
            <h3>Date Posted</h3>

            <form>
                <div class="job-cmn-cat flex-divide">
                    <ul class="left">
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck28">
                                <label class="form-check-label" for="defaultCheck28">Last 30 Days</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck29">
                                <label class="form-check-label" for="defaultCheck29">Last 14 Days</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck30">
                                <label class="form-check-label" for="defaultCheck30">Last 7 Days</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck31">
                                <label class="form-check-label" for="defaultCheck31">All</label>
                            </div>
                        </li>
                    </ul>

                    <ul class="right">
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck32">
                                <label class="form-check-label" for="defaultCheck32">Last 24 Hours</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck33">
                                <label class="form-check-label" for="defaultCheck33">Last 14 Days</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck34">
                                <label class="form-check-label" for="defaultCheck34">Last Hour</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>
        </div>

    </div>
</div>
