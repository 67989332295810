<!-- Footer -->
<footer class="footer-area three pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/"><img src="assets/img/logo-three.png" alt="Logo"></a>

                        <ul>
                            <li><span>Address: </span><br>No 4, Thirumazhisai Via, Pudhuchatram Post,
                                Poonamallee Taluk. Chennai -600124</li>
                            <li><span>Phone: </span><a href="tel:+917845381202">+91 7845381202</a></li>
                            <li><span>Open: </span>Mon - Sun / 9:00 AM - 7:00 PM</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <ul>
                            <li routerLink="/terms-conditions" style="cursor: pointer;"><span>Terms & Conditions </span></li>
                            <li routerLink="/privacy-policy" style="cursor: pointer;"><span>Privacy Policy </span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="footer-item three d-flex justify-content-center align-items-center h-100">
                    <div class="footer-service ">
                        <a class='blantershow-chat' target="_blank" href="https://api.whatsapp.com/send?phone=919042528852&text=Hello this is the starting message" title='Show Chat'><svg width="45" viewBox="0 0 24 24"><defs/><path fill="#eceff1" d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"/><path fill="#4caf50" d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"/><path fill="#fafafa" d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"/></svg> Chat with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="copyright-item">
                    <p>Copyright &#169; Satz Techno Solutions</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->
