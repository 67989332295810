import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { v4 as uuidv4 } from 'uuid';

interface Note {
    id: string;
    text: string;
    time: string;
}
@Component({
    selector: 'app-user-note',
    templateUrl: './user-note.component.html',
    styleUrls: ['./user-note.component.scss'],
})
export class UserNoteComponent implements OnInit {
    public noteForm!: FormGroup;
    submitted = false;
    userId: number = this.storageService.getUser().id;
    profileId: number;
    notes: Note[] = [];
    editNote: Note = null;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private storageService: StorageService,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.noteForm = this.formBuilder.group({
            note: ['', []],
        });
        this.route.params.subscribe((params) => {
            this.profileId = params['id'];
        });
        this.profileService
            .getInterest({
                fromId: this.userId,
                toId: this.profileId,
            })
            .subscribe((res: any) => {
                if (res?.data?.note) this.notes = JSON.parse(res.data.note);
                this.noteForm.patchValue({ note: '' });
            });
    }

    onNoteFormSubmit() {
        this.submitted = true;
        if (this.noteForm.invalid) return;
        const { note } = this.noteForm.value;
        if (this.editNote) {
            const i = this.notes.findIndex((x) => x.id === this.editNote.id);
            this.notes[i].text = note;
            this.notes[i].time = new Date().toLocaleString();
            this.editNote = null;
        } else
            this.notes.push({
                id: uuidv4(),
                text: note,
                time: new Date().toLocaleString(),
            });
        this.updateNoteOnServer();
    }

    get f(): { [key: string]: AbstractControl } {
        return this.noteForm.controls;
    }

    removeNote(id: string): void {
        this.notes = this.notes.filter((note) => note.id !== id);
        this.updateNoteOnServer();
    }

    handleEditNote(note: Note): void {
        this.editNote = note;
        this.noteForm.patchValue({ note: note.text });
    }

    updateNoteOnServer(): void {
        const payload = {
            fromId: this.userId,
            toId: this.profileId,
            message: JSON.stringify(this.notes) as string,
            type: 'NOTE',
        };
        this.profileService.postSendNoteOrReport(payload).subscribe((res) => {
            this.noteForm.patchValue({ note: '' });
            this.alertService.success('Note updated successfully');
        });
    }
}
