<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item d-none">
                    <h2>Jobs</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->
<!-- End Navbar -->

<div
    class="container-fluied"
    style="
        background-color: #f8e8e9;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: -50px;
        position: relative;
    "
>
    <div class="row d-flex justify-content-center" style="max-width: 100%">
        <div class="col-md-7 col-sm-12 col-xs-12 mt-3">
            <ngb-accordion #acc="ngbAccordion" [activeIds]="searchPanelActive">
                <ngb-panel id="toggle-1" title="{{ searchTitle }}">
                    <ng-template ngbPanelContent>
                        <div class="card">
                            <div class="card-body me-2 ms-2">
                                <form
                                    [formGroup]="partPrefForm"
                                    (ngSubmit)="onPartnerPrefFormSubmit('web')"
                                >
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label
                                                class="control-label"
                                                for="general"
                                            >
                                                Member Id
                                            </label>
                                            <input
                                                type="text"
                                                name="general"
                                                [formControl]="f.memberId"
                                                class="form-control"
                                                placeholder="Member Id"
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <label>Marital Status</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All'"
                                                [formControl]="f.maritalStatus"
                                                [(ngModel)]="
                                                    selectedMaritalStatus
                                                "
                                                [settings]="dropdownSettings"
                                                [data]="maritalStatusList"
                                                (onSelectAll)="loadCity($event)"
                                                (onDeSelectAll)="
                                                    loadCity($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label
                                                class="control-label"
                                                for="partner_ageFrom"
                                            >
                                                Age From
                                            </label>
                                            <input
                                                type="number"
                                                name="partner_ageFrom"
                                                step="any"
                                                placeholder="Age From"
                                                [formControl]="f.ageFrom"
                                                class="form-control"
                                            />
                                            <div
                                                style="color: red"
                                                *ngIf="f.ageFrom.errors?.min"
                                            >
                                                Minimum required age is 18.
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label
                                                class="control-label"
                                                for="partner_ageTo"
                                            >
                                                Age To
                                            </label>
                                            <input
                                                type="number"
                                                name="partner_ageTo"
                                                step="any"
                                                [formControl]="f.ageTo"
                                                class="form-control"
                                                placeholder="Age To"
                                            />
                                            <div
                                                style="color: red"
                                                *ngIf="f.ageTo.errors?.min"
                                            >
                                                Minimum required age is 18.
                                            </div>
                                            <div
                                                style="color: red"
                                                *ngIf="f.ageTo.errors?.message"
                                            >
                                                {{ f.ageTo.errors?.message }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Current Country</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.country"
                                                [(ngModel)]="selectedCountry"
                                                [settings]="dropdownSettings"
                                                (onSelect)="loadState($event)"
                                                (onDeSelect)="loadState($event)"
                                                [data]="countryList"
                                                (onSelectAll)="
                                                    loadState($event)
                                                "
                                                (onDeSelectAll)="
                                                    loadState($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Current State</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.state"
                                                [(ngModel)]="selectedState"
                                                [settings]="dropdownSettings"
                                                (onSelect)="loadCity($event)"
                                                (onDeSelect)="loadCity($event)"
                                                [data]="stateList"
                                                (onSelectAll)="loadCity($event)"
                                                (onDeSelectAll)="
                                                    loadCity($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Current District/City</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.city"
                                                [settings]="dropdownSettings"
                                                [data]="cityList"
                                                [(ngModel)]="selectedCity"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Native Country</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.nativeCountry"
                                                [(ngModel)]="
                                                    selectedNativeCountry
                                                "
                                                [settings]="dropdownSettings"
                                                (onSelect)="
                                                    loadNativeState($event)
                                                "
                                                (onDeSelect)="
                                                    loadNativeState($event)
                                                "
                                                [data]="nativeCountryList"
                                                (onSelectAll)="
                                                    loadNativeState($event)
                                                "
                                                (onDeSelectAll)="
                                                    loadNativeState($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Native State</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.nativeState"
                                                [(ngModel)]="
                                                    selectedNativeState
                                                "
                                                [settings]="dropdownSettings"
                                                (onSelect)="
                                                    loadNativeCity($event)
                                                "
                                                (onDeSelect)="
                                                    loadNativeCity($event)
                                                "
                                                [data]="nativeStateList"
                                                (onSelectAll)="
                                                    loadNativeCity($event)
                                                "
                                                (onDeSelectAll)="
                                                    loadNativeCity($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Native District/City</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.nativeCity"
                                                [settings]="dropdownSettings"
                                                [data]="nativeCityList"
                                                [(ngModel)]="selectedNativeCity"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Monther Tongue</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.religion"
                                                [settings]="dropdownSettings"
                                                [data]="religionList"
                                                [(ngModel)]="selectedReligion"
                                                (onSelect)="loadCaste($event)"
                                                (onDeSelect)="
                                                    removeCasteList($event)
                                                "
                                                (onSelectAll)="
                                                    loadCaste($event)
                                                "
                                                (onDeSelectAll)="
                                                    loadCaste($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Caste</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.caste"
                                                [settings]="dropdownSettings"
                                                [data]="casteList"
                                                [(ngModel)]="selectedCaste"
                                                (onSelect)="
                                                    loadSubCaste($event)
                                                "
                                                (onDeSelect)="
                                                    loadSubCaste($event)
                                                "
                                                (onSelectAll)="
                                                    loadSubCaste($event)
                                                "
                                                (onDeSelectAll)="
                                                    loadSubCaste($event)
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Sub Caste</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.subCaste"
                                                [settings]="dropdownSettings"
                                                [data]="subCasteList"
                                                [(ngModel)]="selectedSubCaste"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Economic Status</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.economicStatus"
                                                [settings]="dropdownSettings"
                                                [data]="communityValueList"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Qualification</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.qualification"
                                                [settings]="dropdownSettings"
                                                [data]="qualificationList"
                                                [(ngModel)]="
                                                    selectedQualification
                                                "
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Profession</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.profession"
                                                [settings]="dropdownSettings"
                                                [data]="professionList"
                                                [(ngModel)]="selectedProfession"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label class="control-label">
                                                Star
                                            </label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.star"
                                                [settings]="dropdownSettings"
                                                [data]="startList"
                                                [(ngModel)]="selectedStars"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Dhosam </label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.dhosam"
                                                [settings]="dropdownSettings"
                                                [data]="dhosamValues"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Physical Status</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.physicalStatus"
                                                [settings]="
                                                    singleSelectDropdown
                                                "
                                                [data]="[
                                                    'general',
                                                    'Different Talented'
                                                ]"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Disability</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.bodyType"
                                                [settings]="normalDropdown"
                                                [data]="[
                                                    'Differently Abled',
                                                    'Normal',
                                                    'Does Not Matter'
                                                ]"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Complexion</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Select All '"
                                                [formControl]="f.complexion"
                                                [settings]="normalDropdown"
                                                [data]="[
                                                    'Extremely fair skin',
                                                    'Fair skin',
                                                    'Medium skin',
                                                    'Olive skin',
                                                    'Brown skin',
                                                    'Black skin'
                                                ]"
                                            >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div> -->
                                    <div class="text-center mt-3">
                                        <button
                                            type="submit"
                                            class="btn btn-primary btn-sm"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    <div class="container row" style="min-width: 100%">
        <app-profile-list
            [profileList]="profileList"
            [pagination]="pagination"
        ></app-profile-list>
        <pagination-controls
            class="text-center"
            *ngIf="profileList.length"
            (pageChange)="pageChange($event)"
            id="server"
        ></pagination-controls>
        <div *ngIf="!profileList.length" class="p-5">
            <h1 class="text-center mt-5">No Records Found!</h1>
            <h6 class="text-center">Search & Send Interest</h6>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
