import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUpload implements OnInit {
    @Input() userId: number;
    @Input() galleryType: string;
    constructor(
        public activeModal: NgbActiveModal,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {}

    imageChangedEvent: Event;
    croppedImage: string = '';
    selectedFile;

    fileChangeEvent(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        this.selectedFile = inputElement.files[0];
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    closeModal() {
        this.activeModal.dismiss('Cross click');
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    uploadImage() {
        let file = this.selectedFile;
        if (this.croppedImage)
            file = this.dataURLtoFile(this.croppedImage, 'gallery.png');
        this.profileService
            .postGalleryByUserId(this.userId, file, this.galleryType)
            .subscribe((data) => {
                this.activeModal.close(data);
            });
    }
}
