<div class="container mt-10" style="text-align: left">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">About me</h5>
            </div>
            <div class="card-body">
                {{ profile?.aboutYou || "-" }}
            </div>
        </div>
    </div>
    <br />
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Personal Details</h5>
            </div>
            <div class="card-body">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <th class="">First Name:</th>
                            <td class="py-1">
                                {{ profile?.firstName || "-" | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Last Name:</th>
                            <td class="py-1">
                                {{ profile?.lastName || "-" | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Gender:</th>
                            <td class="py-1">
                                {{ profile?.gender || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Caste:</th>
                            <td class="py-1">
                                {{ profile?.caste || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Sub Caste</th>
                            <td class="py-1">
                                {{ profile?.subCaste || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Sub Caste Additional Details</th>
                            <td class="py-1">
                                {{ profile?.subCasteAD || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Economic Status</th>
                            <td class="py-1">
                                {{ profile?.economicStatus || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Salary Details</th>
                            <td class="py-1">
                                {{ profile?.salary || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Body Type</th>
                            <td class="py-1">
                                {{ profile?.bodyType || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Date Of Birth:</th>
                            <td class="py-1">
                                {{ profile?.dateOfBirth || "-" }} ( Age :
                                {{ getAge(profile?.dateOfBirth) }})
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Marital Status:</th>
                            <td class="py-1">
                                {{ profile?.maritalStatus || "-" }}
                            </td>
                        </tr>
                        <!-- <tr>
                            <th class="py-1 fw-600">
                                <span>Sibling Count</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.siblings || "-" }}
                            </td>
                        </tr> -->
                        <tr *ngIf="profile?.maritalStatus === 'Married'">
                            <th class="py-1 fw-600">No. of Children:</th>
                            <td class="py-1">
                                {{ profile?.noOfChildren || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">height</th>
                            <td class="py-1">
                                {{ profile?.height || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Weight</th>
                            <td class="py-1">
                                {{ profile?.weight || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1">Physical Status</th>
                            <td class="py-1">
                                {{ profile?.physicalStatus || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Mother Tongue</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.religion || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Profession</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.profession || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Qualification</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.qualification || "-" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Family Details</h5>
            </div>
            <div class="card-body">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Father Name</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.father || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Father's Occupation</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.fatherOccupation || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Mother Name</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.mother || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Mother's Occupation</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.motherOccupation || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">Birth Order:</th>
                            <td class="py-1">
                                {{ profile?.birthOrder || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">No Of Brothers:</th>
                            <td class="py-1">
                                {{
                                    profile?.noOfBrothers === null
                                        ? "-"
                                        : profile?.noOfBrothers
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">No Of Brothers Married:</th>
                            <td class="py-1">
                                {{
                                    profile?.noOfBrothersMarried === null
                                        ? "-"
                                        : profile?.noOfBrothersMarried
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">No Of Sisters:</th>
                            <td class="py-1">
                                {{
                                    profile?.noOfSisters === null
                                        ? "-"
                                        : profile?.noOfSisters
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="py-1 fw-600">No Of Sisters Married:</th>
                            <td class="py-1">
                                {{
                                    profile?.noOfSistersMarried === null
                                        ? "-"
                                        : profile?.noOfSistersMarried
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Location Details</h5>
            </div>
            <div class="card-body">
                <table
                    *ngIf="
                        isAuthUserHasPayment || authUserId === user?.id;
                        else elseBlock
                    "
                    class="table table-borderless"
                >
                    <tbody>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-map text-danger px-2"></i>
                                <span>Country</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.country || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-map text-danger px-2"></i>
                                <span>State</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.state || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-building text-danger px-2"></i>
                                <span>District</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.city || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-envelope text-danger px-2"></i>
                                <span>Address</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.address || "-" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #elseBlock>
                    <h5 style="text-align: center">Pament required</h5>
                    <h6 class="text-center">
                        <a [routerLink]="['/order']">
                            Click here to make payment
                        </a>
                    </h6>
                </ng-template>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Property Details</h5>
            </div>
            <div class="card-body">
                <table
                    *ngIf="
                        isAuthUserHasPayment || authUserId === user?.id;
                        else elseBlock
                    "
                    class="table table-borderless"
                >
                    <tbody>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-map text-danger px-2"></i>
                                <span>{{ profile?.property || "-" }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #elseBlock>
                    <h5 style="text-align: center">Pament required</h5>
                    <h6 class="text-center">
                        <a [routerLink]="['/order']">
                            Click here to make payment
                        </a>
                    </h6>
                </ng-template>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Alternative Contacts</h5>
            </div>
            <div class="card-body">
                <table
                    *ngIf="
                        isAuthUserHasPayment || authUserId === user?.id;
                        else elseBlock
                    "
                    class="table table-borderless"
                >
                    <tbody>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-phone text-danger px-2"></i>
                                <span>{{ profile?.alt1Contact || "-" }}</span>
                            </td>
                            <td class="py-1 fw-600">
                                <i class="bx bx-phone text-danger px-2"></i>
                                <span>{{ profile?.alt2Contact || "-" }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #elseBlock>
                    <h5 style="text-align: center">Pament required</h5>
                    <h6 class="text-center">
                        <a [routerLink]="['/order']">
                            Click here to make payment
                        </a>
                    </h6>
                </ng-template>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Native Address</h5>
            </div>
            <div class="card-body">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-map text-danger px-2"></i>
                                <span>State</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.nativeState || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <i class="bx bx-building text-danger px-2"></i>
                                <span>District</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.nativeDistrict || "-" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-header">
                <h5 class="text-danger">Astrological Info</h5>
            </div>
            <div class="card-body">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <th class="py-1 fw-600">
                                <span>Sevvai Dhosham</span>
                            </th>
                            <td class="py-1">
                                {{ profile?.sevvai || "-" | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Raghu/Kedhu Dhosham</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.raghuKedhu || "-" | titlecase }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Birth date</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.dateOfBirth || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Birth Time</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.birthTime || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Birth Place</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.birthPlace || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Star</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.star || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Paadham</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.paadham || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Lagnam</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.lagnam || "-" }}
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1 fw-600">
                                <span>Rasi</span>
                            </td>
                            <td class="py-1">
                                {{ profile?.raasi || "-" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
