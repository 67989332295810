<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Dashboard</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area mt-5">
    <div class="container">
        <div class="row dashboard-user-top">
            <div class="col-lg-3 col-md-12">
                <div class="profile-img">
                    <img src="assets/img/dashboard1.jpg" alt="Dashboard">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 position-relative">
                <div class="top-profile-section d-flex justify-content-between">
                    <div class="top-left-section">
                        <h3>Arun Devkan</h3>
                        <p>IT Consultant</p>
                    </div>
                    <div class="top-right-section">
                        <button type="submit" class="btn btn-primary">View Public Profile</button>
                    </div>
                </div>
                <div class="bottom-profile-section">
                    <ul>
                        <li><i class="bx bx-id-card"></i>YD5625467</li>
                        <li><i class="bx bx-heart-square"></i> UnMarried</li>
                    </ul>
                    <ul>
                        <li><i class="bx bx-map"></i> Chennai</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 profile-counts">
                <div class="card  mb-4">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-8">
                                <div class="numbers">
                                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Profile Views</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        +3,462
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end d-flex flex-column justify-content-center">
                                <div class="icon icon-shape bg-gradient-primary text-center border-radius-md d-flex">
                                    <i class='bx bx-happy-heart-eyes'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-8">
                                <div class="numbers">
                                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Interest Sent</p>
                                    <h5 class="font-weight-bolder mb-0">
                                        +3,462
                                    </h5>
                                </div>
                            </div>
                            <div class="col-4 text-end d-flex flex-column justify-content-center">
                                <div class="icon icon-shape bg-gradient-primary text-center border-radius-md d-flex">
                                    <i class='bx bx-happy-heart-eyes'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-4">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home"
                        role="tab" aria-controls="v-pills-home" aria-selected="true"><i class='bx bx-user'></i> My
                        Profile</a>

                    <a class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages"
                        role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <div class="profile-list">
                            <i class='bx bxs-inbox'></i>
                            Shorlisted Profiles
                        </div>
                    </a>

                    <a class="nav-link" id="v-pills-partner-tab" data-bs-toggle="pill" href="#v-pills-partner"
                        role="tab" aria-controls="v-pills-partner" aria-selected="false"><i class='bx bx-user'></i>
                    Partner Preference</a>
                    <a routerLink="/login">
                        <div class="profile-list"><i class='bx bx-log-out'></i> Logout</div>
                    </a>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                        aria-labelledby="v-pills-home-tab">
                        <div class="card mb-3">
                            <div class="card-header">
                                <h5 class="mb-0 h6">Introduction</h5>
                            </div>
                            <div class="card-body">
                                <form  method="POST">
                                         <div class="form-group row">
                                        <label class="col-md-2 col-form-label">Introduction</label>
                                        <div class="col-md-10">
                                            <textarea type="text" name="introduction" class="form-control h-auto" rows="4"  placeholder="Introduction" required=""></textarea>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary btn-sm">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <h5 class="mb-0 h6">Basic Information</h5>
                            </div>
                            <div class="card-body">

                                <form method="POST">

                                    <input type="hidden" name="_token" value="36A6OMY2HbgiJ9n2WFgi7uqi8c9BZQl5eHxt80FF">
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="first_name">First Name
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" name="first_name" value="Manikandan" class="form-control"
                                                placeholder="First Name" required="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="first_name">Last Name
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" name="last_name" value="E" class="form-control"
                                                placeholder="Last Name" required="">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="first_name">Gender
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="dropdown bootstrap-select aiz- dropup">
                                                <select class="form-control aiz-selectpicker" name="gender" required=""
                                                    tabindex="-98">
                                                    <option value="1" selected="">Male</option>
                                                    <option value="2">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="first_name">Date Of Birth
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" class="aiz-date-range form-control" name="date_of_birth"
                                                value=" 2022-04-13 " placeholder="Select Date" data-single="true"
                                                data-show-dropdown="true">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="first_name">Phone Number
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="number" name="phone" value="999999999" class="form-control"
                                                placeholder="Phone" required="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="first_name">On Behalf
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="dropdown bootstrap-select aiz- dropup">
                                                <select class="form-control aiz-selectpicker" name="on_behalf"
                                                    data-live-search="true" required="" tabindex="-98">
                                                    <option value="1" selected="">Groom</option>
                                                    <option value="2">Bride</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="first_name">Marital Status
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="dropdown bootstrap-select aiz- dropup">
                                                <select class="form-control aiz-selectpicker" name="marital_status"
                                                    data-live-search="true" required="" tabindex="-98">
                                                    <option value="1">Married</option>
                                                    <option value="2" selected="">Unmarried</option>
                                                    <option value="3">Divorced</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="first_name">Number Of Children
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" name="children" value="" class="form-control"
                                                placeholder="Number Of Children">
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary btn-sm dash-submit-btn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <h5 class="mb-0 h6">Language</h5>
                            </div>
                            <div class="card-body">
                                <form  method="POST">
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="diet">Mothere Tongue</label>
                                            <div class="dropdown bootstrap-select aiz-">
                                                <select class="form-control aiz-selectpicker" name="mothere_tongue" data-live-search="true" tabindex="-98">
                                                <option value="">Select One</option>
                                                                            <option value="1"> Tamil </option>
                                                                            <option value="2"> English </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                        <div class="col-md-6">
                                            <label for="drink">Known Languages</label>
                                                                <div class="dropdown bootstrap-select show-tick aiz-"><select class="form-control aiz-selectpicker" name="known_languages[]" data-live-search="true" multiple="" tabindex="-98">
                                                <option value="">Select</option>
                                                                            <option value="1">Tamil </option>
                                                                            <option value="2">English </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary btn-sm">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <h5 class="mb-0 h6">Spiritual &amp; Social Background</h5>
                            </div>
                            <div class="card-body">
                              <form action="http://localhost/mnm_caplin/spiritual_backgrounds/2" method="POST">
                                  <input name="_method" type="hidden" value="PATCH">
                                  <input type="hidden" name="_token" value="nmfCEXxcYF4qLzQaezTkQ2gpnGJ3nJ0zKXZZX3bp">          <input type="hidden" name="address_type" value="present">
                                  <div class="form-group row">
                                      <div class="col-md-6">
                                          <label for="member_religion_id">Religion</label>
                                          <div class="dropdown bootstrap-select aiz-"><select class="form-control aiz-selectpicker" name="member_religion_id" id="member_religion_id" data-live-search="true" required="" tabindex="-98">
                                              <option value="">Select One</option>
                                                                        <option value="1"> Hindu </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                      <div class="col-md-6">
                                          <label for="member_caste_id">Caste</label>
                                          <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="member_caste_id" id="member_caste_id" data-live-search="true" required="" tabindex="-98"></select>
                                        </div>
                                                        </div>
                                  </div>
                                  <div class="form-group row">
                                      <div class="col-md-6">
                                          <label for="member_sub_caste_id">Sub Caste</label>
                                          <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="member_sub_caste_id" id="member_sub_caste_id" data-live-search="true" tabindex="-98"></select>
                                            </div>
                                      </div>
                                      <div class="col-md-6">
                                        <label for="family_value_id">Family Value</label>
                                        <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="family_value_id" data-live-search="true" tabindex="-98">
                                            <option value="">Select One</option>
                                                                      <option value="1"> Good Familiy</option>
                                                              </select>
                                                          </div>
                                    </div>
                                  </div>

                                  <div class="text-end">
                                      <button type="submit" class="btn btn-primary btn-sm">Update</button>
                                  </div>
                              </form>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h5 class="mb-0 h6">Family Information</h5>
                            </div>
                            <div class="card-body">
                                <form action="http://localhost/mnm_caplin/families/2" method="POST">
                                    <input name="_method" type="hidden" value="PATCH">
                                    <input type="hidden" name="_token" value="nmfCEXxcYF4qLzQaezTkQ2gpnGJ3nJ0zKXZZX3bp">            <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="father">Father</label>
                                            <input type="text" name="father" value="" class="form-control" placeholder="Father" required="">
                                                            </div>
                                        <div class="col-md-6">
                                            <label for="mother">Mother</label>
                                            <input type="text" name="mother" value="" placeholder="Mother" class="form-control" required="">
                                                            </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label for="sibling">Sibling</label>
                                            <input type="text" name="sibling" value="" class="form-control" placeholder="Sibling" required="">
                                                            </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary btn-sm">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="v-pills-partner" role="tabpanel"
                    aria-labelledby="v-pills-partner-tab">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0 h6">Partner Expectation</h5>
                        </div>
                        <div class="card-body">
                            <form action="http://localhost/mnm_caplin/partner_expectations/2" method="POST">
                                <input name="_method" type="hidden" value="PATCH">
                                <input type="hidden" name="_token" value="3pLmbK3wt2SncSVQ08qipLsLgyCopi4gdgnPc8ih">            <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="general">General Requirement</label>
                                        <input type="text" name="general" value="Govt Employee" class="form-control" placeholder="General" required="">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="residence_country_id">Residence Country</label>
                                                            <div class="dropdown bootstrap-select form-control aiz-"><select class="form-control aiz-selectpicker" name="residence_country_id" data-live-search="true" required="" tabindex="-98">
                                                                        <option value="1">Afghanistan</option>
                                                                        <option value="2">Albania</option>
                                                                        <option value="3">Algeria</option>
                                                                        <option value="4">American Samoa</option>
                                                                        <option value="5">Andorra</option>
                                                                        <option value="6">Angola</option>
                                                                        <option value="7">Anguilla</option>
                                                                        <option value="8">Antarctica</option>
                                                                        <option value="9">Antigua And Barbuda</option>
                                                                        <option value="10">Argentina</option>
                                                                        <option value="11">Armenia</option>
                                                                        <option value="12">Aruba</option>
                                                                        <option value="13">Australia</option>
                                                                        <option value="14">Austria</option>
                                                                        <option value="15">Azerbaijan</option>
                                                                        <option value="16">Bahamas The</option>
                                                                        <option value="17">Bahrain</option>
                                                                        <option value="18">Bangladesh</option>
                                                                        <option value="19">Barbados</option>
                                                                        <option value="20">Belarus</option>
                                                                        <option value="21">Belgium</option>
                                                                        <option value="22">Belize</option>
                                                                        <option value="23">Benin</option>
                                                                        <option value="24">Bermuda</option>
                                                                        <option value="25">Bhutan</option>
                                                                        <option value="26">Bolivia</option>
                                                                        <option value="27">Bosnia and Herzegovina</option>
                                                                        <option value="28">Botswana</option>
                                                                        <option value="29">Bouvet Island</option>
                                                                        <option value="30">Brazil</option>
                                                                        <option value="31">British Indian Ocean Territory</option>
                                                                        <option value="32">Brunei</option>
                                                                        <option value="33">Bulgaria</option>
                                                                        <option value="34">Burkina Faso</option>
                                                                        <option value="35">Burundi</option>
                                                                        <option value="36">Cambodia</option>
                                                                        <option value="37">Cameroon</option>
                                                                        <option value="38">Canada</option>
                                                                        <option value="39">Cape Verde</option>
                                                                        <option value="40">Cayman Islands</option>
                                                                        <option value="41">Central African Republic</option>
                                                                        <option value="42">Chad</option>
                                                                        <option value="43">Chile</option>
                                                                        <option value="44">China</option>
                                                                        <option value="45">Christmas Island</option>
                                                                        <option value="46">Cocos (Keeling) Islands</option>
                                                                        <option value="47">Colombia</option>
                                                                        <option value="48">Comoros</option>
                                                                        <option value="49">Republic Of The Congo</option>
                                                                        <option value="50">Democratic Republic Of The Congo</option>
                                                                        <option value="51">Cook Islands</option>
                                                                        <option value="52">Costa Rica</option>
                                                                        <option value="53">Cote D'Ivoire (Ivory Coast)</option>
                                                                        <option value="54">Croatia (Hrvatska)</option>
                                                                        <option value="55">Cuba</option>
                                                                        <option value="56">Cyprus</option>
                                                                        <option value="57">Czech Republic</option>
                                                                        <option value="58">Denmark</option>
                                                                        <option value="59">Djibouti</option>
                                                                        <option value="60">Dominica</option>
                                                                        <option value="61">Dominican Republic</option>
                                                                        <option value="62">East Timor</option>
                                                                        <option value="63">Ecuador</option>
                                                                        <option value="64">Egypt</option>
                                                                        <option value="65">El Salvador</option>
                                                                        <option value="66">Equatorial Guinea</option>
                                                                        <option value="67">Eritrea</option>
                                                                        <option value="68">Estonia</option>
                                                                        <option value="69">Ethiopia</option>
                                                                        <option value="70">External Territories of Australia</option>
                                                                        <option value="71">Falkland Islands</option>
                                                                        <option value="72">Faroe Islands</option>
                                                                        <option value="73">Fiji Islands</option>
                                                                        <option value="74">Finland</option>
                                                                        <option value="75">France</option>
                                                                        <option value="76">French Guiana</option>
                                                                        <option value="77">French Polynesia</option>
                                                                        <option value="78">French Southern Territories</option>
                                                                        <option value="79">Gabon</option>
                                                                        <option value="80">Gambia The</option>
                                                                        <option value="81">Georgia</option>
                                                                        <option value="82">Germany</option>
                                                                        <option value="83">Ghana</option>
                                                                        <option value="84">Gibraltar</option>
                                                                        <option value="85">Greece</option>
                                                                        <option value="86">Greenland</option>
                                                                        <option value="87">Grenada</option>
                                                                        <option value="88">Guadeloupe</option>
                                                                        <option value="89">Guam</option>
                                                                        <option value="90">Guatemala</option>
                                                                        <option value="91">Guernsey and Alderney</option>
                                                                        <option value="92">Guinea</option>
                                                                        <option value="93">Guinea-Bissau</option>
                                                                        <option value="94">Guyana</option>
                                                                        <option value="95">Haiti</option>
                                                                        <option value="96">Heard and McDonald Islands</option>
                                                                        <option value="97">Honduras</option>
                                                                        <option value="98">Hong Kong S.A.R.</option>
                                                                        <option value="99">Hungary</option>
                                                                        <option value="100">Iceland</option>
                                                                        <option value="101" selected="">India</option>
                                                                        <option value="102">Indonesia</option>
                                                                        <option value="103">Iran</option>
                                                                        <option value="104">Iraq</option>
                                                                        <option value="105">Ireland</option>
                                                                        <option value="106">Israel</option>
                                                                        <option value="107">Italy</option>
                                                                        <option value="108">Jamaica</option>
                                                                        <option value="109">Japan</option>
                                                                        <option value="110">Jersey</option>
                                                                        <option value="111">Jordan</option>
                                                                        <option value="112">Kazakhstan</option>
                                                                        <option value="113">Kenya</option>
                                                                        <option value="114">Kiribati</option>
                                                                        <option value="115">Korea North</option>
                                                                        <option value="116">Korea South</option>
                                                                        <option value="117">Kuwait</option>
                                                                        <option value="118">Kyrgyzstan</option>
                                                                        <option value="119">Laos</option>
                                                                        <option value="120">Latvia</option>
                                                                        <option value="121">Lebanon</option>
                                                                        <option value="122">Lesotho</option>
                                                                        <option value="123">Liberia</option>
                                                                        <option value="124">Libya</option>
                                                                        <option value="125">Liechtenstein</option>
                                                                        <option value="126">Lithuania</option>
                                                                        <option value="127">Luxembourg</option>
                                                                        <option value="128">Macau S.A.R.</option>
                                                                        <option value="129">Macedonia</option>
                                                                        <option value="130">Madagascar</option>
                                                                        <option value="131">Malawi</option>
                                                                        <option value="132">Malaysia</option>
                                                                        <option value="133">Maldives</option>
                                                                        <option value="134">Mali</option>
                                                                        <option value="135">Malta</option>
                                                                        <option value="136">Man (Isle of)</option>
                                                                        <option value="137">Marshall Islands</option>
                                                                        <option value="138">Martinique</option>
                                                                        <option value="139">Mauritania</option>
                                                                        <option value="140">Mauritius</option>
                                                                        <option value="141">Mayotte</option>
                                                                        <option value="142">Mexico</option>
                                                                        <option value="143">Micronesia</option>
                                                                        <option value="144">Moldova</option>
                                                                        <option value="145">Monaco</option>
                                                                        <option value="146">Mongolia</option>
                                                                        <option value="147">Montserrat</option>
                                                                        <option value="148">Morocco</option>
                                                                        <option value="149">Mozambique</option>
                                                                        <option value="150">Myanmar</option>
                                                                        <option value="151">Namibia</option>
                                                                        <option value="152">Nauru</option>
                                                                        <option value="153">Nepal</option>
                                                                        <option value="154">Netherlands Antilles</option>
                                                                        <option value="155">Netherlands The</option>
                                                                        <option value="156">New Caledonia</option>
                                                                        <option value="157">New Zealand</option>
                                                                        <option value="158">Nicaragua</option>
                                                                        <option value="159">Niger</option>
                                                                        <option value="160">Nigeria</option>
                                                                        <option value="161">Niue</option>
                                                                        <option value="162">Norfolk Island</option>
                                                                        <option value="163">Northern Mariana Islands</option>
                                                                        <option value="164">Norway</option>
                                                                        <option value="165">Oman</option>
                                                                        <option value="166">Pakistan</option>
                                                                        <option value="167">Palau</option>
                                                                        <option value="168">Palestinian Territory Occupied</option>
                                                                        <option value="169">Panama</option>
                                                                        <option value="170">Papua new Guinea</option>
                                                                        <option value="171">Paraguay</option>
                                                                        <option value="172">Peru</option>
                                                                        <option value="173">Philippines</option>
                                                                        <option value="174">Pitcairn Island</option>
                                                                        <option value="175">Poland</option>
                                                                        <option value="176">Portugal</option>
                                                                        <option value="177">Puerto Rico</option>
                                                                        <option value="178">Qatar</option>
                                                                        <option value="179">Reunion</option>
                                                                        <option value="180">Romania</option>
                                                                        <option value="181">Russia</option>
                                                                        <option value="182">Rwanda</option>
                                                                        <option value="183">Saint Helena</option>
                                                                        <option value="184">Saint Kitts And Nevis</option>
                                                                        <option value="185">Saint Lucia</option>
                                                                        <option value="186">Saint Pierre and Miquelon</option>
                                                                        <option value="187">Saint Vincent And The Grenadines</option>
                                                                        <option value="188">Samoa</option>
                                                                        <option value="189">San Marino</option>
                                                                        <option value="190">Sao Tome and Principe</option>
                                                                        <option value="191">Saudi Arabia</option>
                                                                        <option value="192">Senegal</option>
                                                                        <option value="193">Serbia</option>
                                                                        <option value="194">Seychelles</option>
                                                                        <option value="195">Sierra Leone</option>
                                                                        <option value="196">Singapore</option>
                                                                        <option value="197">Slovakia</option>
                                                                        <option value="198">Slovenia</option>
                                                                        <option value="199">Smaller Territories of the UK</option>
                                                                        <option value="200">Solomon Islands</option>
                                                                        <option value="201">Somalia</option>
                                                                        <option value="202">South Africa</option>
                                                                        <option value="203">South Georgia</option>
                                                                        <option value="204">South Sudan</option>
                                                                        <option value="205">Spain</option>
                                                                        <option value="206">Sri Lanka</option>
                                                                        <option value="207">Sudan</option>
                                                                        <option value="208">Suriname</option>
                                                                        <option value="209">Svalbard And Jan Mayen Islands</option>
                                                                        <option value="210">Swaziland</option>
                                                                        <option value="211">Sweden</option>
                                                                        <option value="212">Switzerland</option>
                                                                        <option value="213">Syria</option>
                                                                        <option value="214">Taiwan</option>
                                                                        <option value="215">Tajikistan</option>
                                                                        <option value="216">Tanzania</option>
                                                                        <option value="217">Thailand</option>
                                                                        <option value="218">Togo</option>
                                                                        <option value="219">Tokelau</option>
                                                                        <option value="220">Tonga</option>
                                                                        <option value="221">Trinidad And Tobago</option>
                                                                        <option value="222">Tunisia</option>
                                                                        <option value="223">Turkey</option>
                                                                        <option value="224">Turkmenistan</option>
                                                                        <option value="225">Turks And Caicos Islands</option>
                                                                        <option value="226">Tuvalu</option>
                                                                        <option value="227">Uganda</option>
                                                                        <option value="228">Ukraine</option>
                                                                        <option value="229">United Arab Emirates</option>
                                                                        <option value="230">United Kingdom</option>
                                                                        <option value="231">United States</option>
                                                                        <option value="232">United States Minor Outlying Islands</option>
                                                                        <option value="233">Uruguay</option>
                                                                        <option value="234">Uzbekistan</option>
                                                                        <option value="235">Vanuatu</option>
                                                                        <option value="236">Vatican City State (Holy See)</option>
                                                                        <option value="237">Venezuela</option>
                                                                        <option value="238">Vietnam</option>
                                                                        <option value="239">Virgin Islands (British)</option>
                                                                        <option value="240">Virgin Islands (US)</option>
                                                                        <option value="241">Wallis And Futuna Islands</option>
                                                                        <option value="242">Western Sahara</option>
                                                                        <option value="243">Yemen</option>
                                                                        <option value="244">Yugoslavia</option>
                                                                        <option value="245">Zambia</option>
                                                                        <option value="246">Zimbabwe</option>
                                                                </select>
                                                                </div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_height">Min Height  (In Feet)</label>
                                        <input type="number" name="partner_height" value="6.9" step="any" placeholder="Height" class="form-control" required="">
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="partner_weight">Max Weight  (In Kg)</label>
                                        <input type="number" name="partner_weight" value="67" step="any" class="form-control" placeholder="Weight" required="">
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_marital_status">Marital Status</label>
                                                            <div class="dropdown bootstrap-select aiz-"><select class="form-control aiz-selectpicker" name="partner_marital_status" data-live-search="true" required="" tabindex="-98">
                                            <option value="">Choose One</option>
                                                                    <option value="1">Married</option>
                                                                    <option value="2" selected="">Unmarried</option>
                                                                    <option value="3">Divorced</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                    <div class="col-md-6">
                                        <label for="partner_children_acceptable">Children Acceptable</label>
                                                            <div class="dropdown bootstrap-select  aiz-"><select class="form-control aiz-selectpicker" name="partner_children_acceptable" required="" tabindex="-98">
                                            <option value="">Choose One</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            <option value="dose_not_matter" selected="">Dose not matter</option>
                                        </select>
                                    </div>
                                                        </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_religion_id">Religion</label>
                                        <div class="dropdown bootstrap-select  aiz-"><select class="form-control aiz-selectpicker" name="partner_religion_id" id="partner_religion_id" data-live-search="true" required="" tabindex="-98">
                                            <option value="">Select One</option>
                                                                        <option value="1" selected=""> Hindu </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="partner_caste_id">Caste</label>
                                        <div class="dropdown bootstrap-select  aiz-"><select class="form-control aiz-selectpicker" name="partner_caste_id" id="partner_caste_id" data-live-search="true" tabindex="-98"><option value="1">Yadava</option></select>
                                        </div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_sub_caste_id">Sub Caste</label>
                                        <div class="dropdown bootstrap-select aiz-"><select class="form-control aiz-selectpicker" name="partner_sub_caste_id" id="partner_sub_caste_id" data-live-search="true" tabindex="-98"><option value="1">Yadav</option></select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="language_id">Language</label>
                                                            <div class="dropdown bootstrap-select  aiz-"><select class="form-control aiz-selectpicker" name="language_id" data-live-search="true" required="" tabindex="-98">
                                            <option value="">Select One</option>
                                                                        <option value="1" selected=""> Tamil </option>
                                                                        <option value="2"> English </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="pertner_education">Education</label>
                                        <input type="text" name="pertner_education" value="B.Tech" class="form-control" placeholder="Education">
                                                        </div>

                                    <div class="col-md-6">
                                        <label for="partner_profession">Profession</label>
                                        <input type="text" name="partner_profession" value="IT" class="form-control" placeholder="Profession">
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="smoking_acceptable">Smoking Acceptable</label>
                                                            <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="smoking_acceptable" required="" tabindex="-98">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            <option value="dose_not_matter" selected="">Dose not matter</option>
                                        </select>
                                    </div>
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="drinking_acceptable">Drinking Acceptable</label>
                                                            <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="drinking_acceptable" required="" tabindex="-98">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            <option value="dose_not_matter" selected="">Dose not matter</option>
                                        </select>
                                    </div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_diet">Dieting Acceptable</label>
                                                            <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="partner_diet" required="" tabindex="-98">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            <option value="dose_not_matter" selected="">Dose not matter</option>
                                        </select>
                                    </div>
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="partner_body_type">Body Type</label>
                                        <input type="text" name="partner_body_type" value="Normal" class="form-control" placeholder="Body Type">
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_personal_value">Personal Value</label>
                                        <input type="text" name="partner_personal_value" value="Mercy" class="form-control" placeholder="Personal Value">
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="partner_manglik">Manglik</label>
                                                            <div class="dropdown bootstrap-select  aiz- dropup"><select class="form-control aiz-selectpicker" name="partner_manglik" required="" tabindex="-98">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            <option value="dose_not_matter" selected="">Dose not matter</option>
                                        </select>
                                    </div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="partner_country_id">Preferred Country</label>
                                        <div class="dropdown bootstrap-select aiz- dropup"><select class="form-control aiz-selectpicker" name="partner_country_id" id="partner_country_id" data-live-search="true" required="" tabindex="-98">
                                            <option value="">Select One</option>
                                                                        <option value="1">Afghanistan</option>
                                                                        <option value="2">Albania</option>
                                                                        <option value="3">Algeria</option>
                                                                        <option value="4">American Samoa</option>
                                                                        <option value="5">Andorra</option>
                                                                        <option value="6">Angola</option>
                                                                        <option value="7">Anguilla</option>
                                                                        <option value="8">Antarctica</option>
                                                                        <option value="9">Antigua And Barbuda</option>
                                                                        <option value="10">Argentina</option>
                                                                        <option value="11">Armenia</option>
                                                                        <option value="12">Aruba</option>
                                                                        <option value="13">Australia</option>
                                                                        <option value="14">Austria</option>
                                                                        <option value="15">Azerbaijan</option>
                                                                        <option value="16">Bahamas The</option>
                                                                        <option value="17">Bahrain</option>
                                                                        <option value="18">Bangladesh</option>
                                                                        <option value="19">Barbados</option>
                                                                        <option value="20">Belarus</option>
                                                                        <option value="21">Belgium</option>
                                                                        <option value="22">Belize</option>
                                                                        <option value="23">Benin</option>
                                                                        <option value="24">Bermuda</option>
                                                                        <option value="25">Bhutan</option>
                                                                        <option value="26">Bolivia</option>
                                                                        <option value="27">Bosnia and Herzegovina</option>
                                                                        <option value="28">Botswana</option>
                                                                        <option value="29">Bouvet Island</option>
                                                                        <option value="30">Brazil</option>
                                                                        <option value="31">British Indian Ocean Territory</option>
                                                                        <option value="32">Brunei</option>
                                                                        <option value="33">Bulgaria</option>
                                                                        <option value="34">Burkina Faso</option>
                                                                        <option value="35">Burundi</option>
                                                                        <option value="36">Cambodia</option>
                                                                        <option value="37">Cameroon</option>
                                                                        <option value="38">Canada</option>
                                                                        <option value="39">Cape Verde</option>
                                                                        <option value="40">Cayman Islands</option>
                                                                        <option value="41">Central African Republic</option>
                                                                        <option value="42">Chad</option>
                                                                        <option value="43">Chile</option>
                                                                        <option value="44">China</option>
                                                                        <option value="45">Christmas Island</option>
                                                                        <option value="46">Cocos (Keeling) Islands</option>
                                                                        <option value="47">Colombia</option>
                                                                        <option value="48">Comoros</option>
                                                                        <option value="49">Republic Of The Congo</option>
                                                                        <option value="50">Democratic Republic Of The Congo</option>
                                                                        <option value="51">Cook Islands</option>
                                                                        <option value="52">Costa Rica</option>
                                                                        <option value="53">Cote D'Ivoire (Ivory Coast)</option>
                                                                        <option value="54">Croatia (Hrvatska)</option>
                                                                        <option value="55">Cuba</option>
                                                                        <option value="56">Cyprus</option>
                                                                        <option value="57">Czech Republic</option>
                                                                        <option value="58">Denmark</option>
                                                                        <option value="59">Djibouti</option>
                                                                        <option value="60">Dominica</option>
                                                                        <option value="61">Dominican Republic</option>
                                                                        <option value="62">East Timor</option>
                                                                        <option value="63">Ecuador</option>
                                                                        <option value="64">Egypt</option>
                                                                        <option value="65">El Salvador</option>
                                                                        <option value="66">Equatorial Guinea</option>
                                                                        <option value="67">Eritrea</option>
                                                                        <option value="68">Estonia</option>
                                                                        <option value="69">Ethiopia</option>
                                                                        <option value="70">External Territories of Australia</option>
                                                                        <option value="71">Falkland Islands</option>
                                                                        <option value="72">Faroe Islands</option>
                                                                        <option value="73">Fiji Islands</option>
                                                                        <option value="74">Finland</option>
                                                                        <option value="75">France</option>
                                                                        <option value="76">French Guiana</option>
                                                                        <option value="77">French Polynesia</option>
                                                                        <option value="78">French Southern Territories</option>
                                                                        <option value="79">Gabon</option>
                                                                        <option value="80">Gambia The</option>
                                                                        <option value="81">Georgia</option>
                                                                        <option value="82">Germany</option>
                                                                        <option value="83">Ghana</option>
                                                                        <option value="84">Gibraltar</option>
                                                                        <option value="85">Greece</option>
                                                                        <option value="86">Greenland</option>
                                                                        <option value="87">Grenada</option>
                                                                        <option value="88">Guadeloupe</option>
                                                                        <option value="89">Guam</option>
                                                                        <option value="90">Guatemala</option>
                                                                        <option value="91">Guernsey and Alderney</option>
                                                                        <option value="92">Guinea</option>
                                                                        <option value="93">Guinea-Bissau</option>
                                                                        <option value="94">Guyana</option>
                                                                        <option value="95">Haiti</option>
                                                                        <option value="96">Heard and McDonald Islands</option>
                                                                        <option value="97">Honduras</option>
                                                                        <option value="98">Hong Kong S.A.R.</option>
                                                                        <option value="99">Hungary</option>
                                                                        <option value="100">Iceland</option>
                                                                        <option value="101" selected="">India</option>
                                                                        <option value="102">Indonesia</option>
                                                                        <option value="103">Iran</option>
                                                                        <option value="104">Iraq</option>
                                                                        <option value="105">Ireland</option>
                                                                        <option value="106">Israel</option>
                                                                        <option value="107">Italy</option>
                                                                        <option value="108">Jamaica</option>
                                                                        <option value="109">Japan</option>
                                                                        <option value="110">Jersey</option>
                                                                        <option value="111">Jordan</option>
                                                                        <option value="112">Kazakhstan</option>
                                                                        <option value="113">Kenya</option>
                                                                        <option value="114">Kiribati</option>
                                                                        <option value="115">Korea North</option>
                                                                        <option value="116">Korea South</option>
                                                                        <option value="117">Kuwait</option>
                                                                        <option value="118">Kyrgyzstan</option>
                                                                        <option value="119">Laos</option>
                                                                        <option value="120">Latvia</option>
                                                                        <option value="121">Lebanon</option>
                                                                        <option value="122">Lesotho</option>
                                                                        <option value="123">Liberia</option>
                                                                        <option value="124">Libya</option>
                                                                        <option value="125">Liechtenstein</option>
                                                                        <option value="126">Lithuania</option>
                                                                        <option value="127">Luxembourg</option>
                                                                        <option value="128">Macau S.A.R.</option>
                                                                        <option value="129">Macedonia</option>
                                                                        <option value="130">Madagascar</option>
                                                                        <option value="131">Malawi</option>
                                                                        <option value="132">Malaysia</option>
                                                                        <option value="133">Maldives</option>
                                                                        <option value="134">Mali</option>
                                                                        <option value="135">Malta</option>
                                                                        <option value="136">Man (Isle of)</option>
                                                                        <option value="137">Marshall Islands</option>
                                                                        <option value="138">Martinique</option>
                                                                        <option value="139">Mauritania</option>
                                                                        <option value="140">Mauritius</option>
                                                                        <option value="141">Mayotte</option>
                                                                        <option value="142">Mexico</option>
                                                                        <option value="143">Micronesia</option>
                                                                        <option value="144">Moldova</option>
                                                                        <option value="145">Monaco</option>
                                                                        <option value="146">Mongolia</option>
                                                                        <option value="147">Montserrat</option>
                                                                        <option value="148">Morocco</option>
                                                                        <option value="149">Mozambique</option>
                                                                        <option value="150">Myanmar</option>
                                                                        <option value="151">Namibia</option>
                                                                        <option value="152">Nauru</option>
                                                                        <option value="153">Nepal</option>
                                                                        <option value="154">Netherlands Antilles</option>
                                                                        <option value="155">Netherlands The</option>
                                                                        <option value="156">New Caledonia</option>
                                                                        <option value="157">New Zealand</option>
                                                                        <option value="158">Nicaragua</option>
                                                                        <option value="159">Niger</option>
                                                                        <option value="160">Nigeria</option>
                                                                        <option value="161">Niue</option>
                                                                        <option value="162">Norfolk Island</option>
                                                                        <option value="163">Northern Mariana Islands</option>
                                                                        <option value="164">Norway</option>
                                                                        <option value="165">Oman</option>
                                                                        <option value="166">Pakistan</option>
                                                                        <option value="167">Palau</option>
                                                                        <option value="168">Palestinian Territory Occupied</option>
                                                                        <option value="169">Panama</option>
                                                                        <option value="170">Papua new Guinea</option>
                                                                        <option value="171">Paraguay</option>
                                                                        <option value="172">Peru</option>
                                                                        <option value="173">Philippines</option>
                                                                        <option value="174">Pitcairn Island</option>
                                                                        <option value="175">Poland</option>
                                                                        <option value="176">Portugal</option>
                                                                        <option value="177">Puerto Rico</option>
                                                                        <option value="178">Qatar</option>
                                                                        <option value="179">Reunion</option>
                                                                        <option value="180">Romania</option>
                                                                        <option value="181">Russia</option>
                                                                        <option value="182">Rwanda</option>
                                                                        <option value="183">Saint Helena</option>
                                                                        <option value="184">Saint Kitts And Nevis</option>
                                                                        <option value="185">Saint Lucia</option>
                                                                        <option value="186">Saint Pierre and Miquelon</option>
                                                                        <option value="187">Saint Vincent And The Grenadines</option>
                                                                        <option value="188">Samoa</option>
                                                                        <option value="189">San Marino</option>
                                                                        <option value="190">Sao Tome and Principe</option>
                                                                        <option value="191">Saudi Arabia</option>
                                                                        <option value="192">Senegal</option>
                                                                        <option value="193">Serbia</option>
                                                                        <option value="194">Seychelles</option>
                                                                        <option value="195">Sierra Leone</option>
                                                                        <option value="196">Singapore</option>
                                                                        <option value="197">Slovakia</option>
                                                                        <option value="198">Slovenia</option>
                                                                        <option value="199">Smaller Territories of the UK</option>
                                                                        <option value="200">Solomon Islands</option>
                                                                        <option value="201">Somalia</option>
                                                                        <option value="202">South Africa</option>
                                                                        <option value="203">South Georgia</option>
                                                                        <option value="204">South Sudan</option>
                                                                        <option value="205">Spain</option>
                                                                        <option value="206">Sri Lanka</option>
                                                                        <option value="207">Sudan</option>
                                                                        <option value="208">Suriname</option>
                                                                        <option value="209">Svalbard And Jan Mayen Islands</option>
                                                                        <option value="210">Swaziland</option>
                                                                        <option value="211">Sweden</option>
                                                                        <option value="212">Switzerland</option>
                                                                        <option value="213">Syria</option>
                                                                        <option value="214">Taiwan</option>
                                                                        <option value="215">Tajikistan</option>
                                                                        <option value="216">Tanzania</option>
                                                                        <option value="217">Thailand</option>
                                                                        <option value="218">Togo</option>
                                                                        <option value="219">Tokelau</option>
                                                                        <option value="220">Tonga</option>
                                                                        <option value="221">Trinidad And Tobago</option>
                                                                        <option value="222">Tunisia</option>
                                                                        <option value="223">Turkey</option>
                                                                        <option value="224">Turkmenistan</option>
                                                                        <option value="225">Turks And Caicos Islands</option>
                                                                        <option value="226">Tuvalu</option>
                                                                        <option value="227">Uganda</option>
                                                                        <option value="228">Ukraine</option>
                                                                        <option value="229">United Arab Emirates</option>
                                                                        <option value="230">United Kingdom</option>
                                                                        <option value="231">United States</option>
                                                                        <option value="232">United States Minor Outlying Islands</option>
                                                                        <option value="233">Uruguay</option>
                                                                        <option value="234">Uzbekistan</option>
                                                                        <option value="235">Vanuatu</option>
                                                                        <option value="236">Vatican City State (Holy See)</option>
                                                                        <option value="237">Venezuela</option>
                                                                        <option value="238">Vietnam</option>
                                                                        <option value="239">Virgin Islands (British)</option>
                                                                        <option value="240">Virgin Islands (US)</option>
                                                                        <option value="241">Wallis And Futuna Islands</option>
                                                                        <option value="242">Western Sahara</option>
                                                                        <option value="243">Yemen</option>
                                                                        <option value="244">Yugoslavia</option>
                                                                        <option value="245">Zambia</option>
                                                                        <option value="246">Zimbabwe</option>
                                                                </select>
                                                            </div>                                                        </div>
                                    <div class="col-md-6">
                                        <label for="partner_state_id">Preferred State</label>
                                        <div class="dropdown bootstrap-select aiz- dropup"><select class="form-control aiz-selectpicker" name="partner_state_id" id="partner_state_id" data-live-search="true" tabindex="-98"><option value="1">Andaman and Nicobar Islands</option><option value="2">Andhra Pradesh</option><option value="3">Arunachal Pradesh</option><option value="4">Assam</option><option value="5">Bihar</option><option value="6">Chandigarh</option><option value="7">Chhattisgarh</option><option value="8">Dadra and Nagar Haveli</option><option value="9">Daman and Diu</option><option value="10">Delhi</option><option value="11">Goa</option><option value="12">Gujarat</option><option value="13">Haryana</option><option value="14">Himachal Pradesh</option><option value="15">Jammu and Kashmir</option><option value="16">Jharkhand</option><option value="17">Karnataka</option><option value="18">Kenmore</option><option value="19">Kerala</option><option value="20">Lakshadweep</option><option value="21">Madhya Pradesh</option><option value="22">Maharashtra</option><option value="23">Manipur</option><option value="24">Meghalaya</option><option value="25">Mizoram</option><option value="26">Nagaland</option><option value="27">Narora</option><option value="28">Natwar</option><option value="29">Odisha</option><option value="30">Paschim Medinipur</option><option value="31">Pondicherry</option><option value="32">Punjab</option><option value="33">Rajasthan</option><option value="34">Sikkim</option><option value="35">Tamil Nadu</option><option value="36">Telangana</option><option value="37">Tripura</option><option value="38">Uttar Pradesh</option><option value="39">Uttarakhand</option><option value="40">Vaishali</option><option value="41">West Bengal</option></select></div>
                                                        </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="family_value_id">Family Value</label>
                                        <div class="dropdown bootstrap-select aiz- dropup"><select class="form-control aiz-selectpicker" name="family_value_id" tabindex="-98">
                                            <option value="">Select One</option>
                                                                        <option value="1" selected=""> Good Familiy </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                    <div class="col-md-6">
                                        <label for="pertner_complexion">Complexion</label>
                                        <input type="text" name="pertner_complexion" value="Fair" class="form-control" placeholder="Complexion" required="">
                                                        </div>
                                </div>

                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary btn-sm">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                        aria-labelledby="v-pills-messages-tab">
                        <div class="employer-item">
                            <a routerLink="/job-details">
                                <div class="row align-items-center">
                                    <div class="col-lg-2 col-md-12">
                                        <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                                    </div>
                                    <div class="col-lg-10 col-md-12 p-2">
                                        <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                                <ul>
                                    <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                                    <li>4 months ago</li>
                                </ul>
                                <p class="mb-0">Studied at Ranchi Agriculture College, Ranchi Agriculture College
                                    Works at ICICI Home Finance Company Limited</p>
                                <span class="span-two two">SEND INTEREST</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="employer-item">
                            <a routerLink="/job-details">
                                <div class="row align-items-center">
                                    <div class="col-lg-2 col-md-12">
                                        <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                                    </div>
                                    <div class="col-lg-10 col-md-12 p-2">
                                        <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                                <ul>
                                    <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                                    <li>4 months ago</li>
                                </ul>
                                <p class="mb-0">Studied at Ranchi Agriculture College, Ranchi Agriculture College
                                    Works at ICICI Home Finance Company Limited</p>
                                <span class="span-two two">SEND INTEREST</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="employer-item">
                            <a routerLink="/job-details">
                                <div class="row align-items-center">
                                    <div class="col-lg-2 col-md-12">
                                        <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                                    </div>
                                    <div class="col-lg-10 col-md-12 p-2">
                                        <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                                <ul>
                                    <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                                    <li>4 months ago</li>
                                </ul>
                                <p class="mb-0">Studied at Ranchi Agriculture College, Ranchi Agriculture College
                                    Works at ICICI Home Finance Company Limited</p>
                                <span class="span-two two">SEND INTEREST</span>
                                    </div>
                                </div>
                            </a>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Dashboard -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
