import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
    constructor(
        private storageService: StorageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.storageService.clear();
        this.router.navigate(['/login']);
    }
}
