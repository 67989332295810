import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import axios from 'axios';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';

@Component({
    selector: 'app-profile-image',
    templateUrl: './profile-image.component.html',
    styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImage implements OnInit {
    @Input() userId: number;
    @Input() gender: string;
    @Input() profileImage: string;
    imageUrl: string = 'assets/img/default-image.gif';

    constructor(
        private profileService: ProfileService,
        private storageService: StorageService,
        private domSanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.getProfileImage(this.userId);
    }

    getProfileImage = (userId: number) => {
        
        const headers = {
            headers: {
                Authorization: 'Bearer ' + this.storageService.getToken(),
            },
        };
        axios
            .get(
                this.profileService.apiUrl +
                    'galleries/profile-image/' +
                    userId,
                headers
            )
            .then((res) => {
                if (res.status === 200 && res.data) {
                    let src = `data:${res.data.fileType};base64, ${res.data.content}`;
                    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(
                        src
                    ) as string;
                } else {
                    this.imageUrl = this.gender == 'MALE'
                            ? 'assets/img/home-two/banners/male.svg'
                            : 'assets/img/home-two/banners/female.svg';
                }
            });
    };
}
