<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item d-none">
                    <h2>Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="job-list-right">
                    <div class="job-list-all">
                        <div class="search">
                            <h3>Search Keywords</h3>

                            <form>
                                <input type="text" class="form-control" placeholder="Keyword">
                                <button type="submit" class="btn"><i class="flaticon-send"></i></button>
                            </form>

                            <h3>Marital Status</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                            <label class="form-check-label" for="defaultCheck1">Digital & Creative (5)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                                            <label class="form-check-label" for="defaultCheck2">Sales & Marketing (6)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck3">
                                            <label class="form-check-label" for="defaultCheck3">Marketing & PR (8)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck4">
                                            <label class="form-check-label" for="defaultCheck4">IT Contractor (2)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck5">
                                            <label class="form-check-label" for="defaultCheck5">Accountancy (1)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck6">
                                            <label class="form-check-label" for="defaultCheck6">Retail (9)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck7">
                                            <label class="form-check-label" for="defaultCheck7">Media (3)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck8">
                                            <label class="form-check-label" for="defaultCheck8">SEO (2)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck9">
                                            <label class="form-check-label" for="defaultCheck9">Freelance (8)</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>

                        <div class="location-list cmn-padding">
                            <h3>Location List</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck10">
                                            <label class="form-check-label" for="defaultCheck10">New York (8)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck11">
                                            <label class="form-check-label" for="defaultCheck11">Los Angeles (4)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck12">
                                            <label class="form-check-label" for="defaultCheck12">London (5)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck13">
                                            <label class="form-check-label" for="defaultCheck13">Canada (1)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck14">
                                            <label class="form-check-label" for="defaultCheck14">France (9)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck15">
                                            <label class="form-check-label" for="defaultCheck15">Italy (2)</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>

                        <div class="job-type cmn-padding">
                            <h3>Work Industry</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck16">
                                            <label class="form-check-label" for="defaultCheck16">Temporary (2)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck17">
                                            <label class="form-check-label" for="defaultCheck17">Remote (2)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck18">
                                            <label class="form-check-label" for="defaultCheck18">Part Time (2)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck19">
                                            <label class="form-check-label" for="defaultCheck19">Internship (1)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck20">
                                            <label class="form-check-label" for="defaultCheck20">Full Time (13)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck21">
                                            <label class="form-check-label" for="defaultCheck21">Freelancer (3)</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck22">
                                            <label class="form-check-label" for="defaultCheck22">Contract (0)</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>

                        <div class="salary cmn-padding">
                            <h3>Salary</h3>

                            <form>
                                <div class="job-cmn-cat flex-divide">
                                    <ul class="left">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck23">
                                                <label class="form-check-label" for="defaultCheck23">Monthly</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck24">
                                                <label class="form-check-label" for="defaultCheck24">Daily</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck25">
                                                <label class="form-check-label" for="defaultCheck25">Yearly</label>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="right">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck26">
                                                <label class="form-check-label" for="defaultCheck26">Weekly</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck27">
                                                <label class="form-check-label" for="defaultCheck27">Hourly</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="range" for="formControlRangeTwo">$6778 - $9077</label>
                                    <input type="range" class="form-control-range" id="formControlRangeTwo">
                                </div> -->
                            </form>
                        </div>

                        <div class="date cmn-padding">
                            <h3>Date Posted</h3>

                            <form>
                                <div class="job-cmn-cat flex-divide">
                                    <ul class="left">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck28">
                                                <label class="form-check-label" for="defaultCheck28">Last 30 Days</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck29">
                                                <label class="form-check-label" for="defaultCheck29">Last 14 Days</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck30">
                                                <label class="form-check-label" for="defaultCheck30">Last 7 Days</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck31">
                                                <label class="form-check-label" for="defaultCheck31">All</label>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="right">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck32">
                                                <label class="form-check-label" for="defaultCheck32">Last 24 Hours</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck33">
                                                <label class="form-check-label" for="defaultCheck33">Last 14 Days</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck34">
                                                <label class="form-check-label" for="defaultCheck34">Last Hour</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>

                    </div>

                    <div class="alzo-contact">
                        <img src="assets/img/alzo-conatct.png" alt="alzo">

                        <div class="bottom">
                            <span>Contact Now</span>
                            <h3><a href="tel:+123987334">+123-987-334</a></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <ul class="job-list-item align-items-center mb-3">
                    <li>
                        <a href="#">Results Found <span>(17)</span></a>
                    </li>
                    <li>
                        <span class="sort">Sort By:</span>
                        <form>
                            <div class="form-group">
                                <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>
                            </div>
                        </form>
                    </li>
                </ul>
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                            </div>
                            <div class="col-lg-8 col-md-12 p-2">
                                <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                            <li>4 months ago</li>
                        </ul>
                        <div class="row align-items-center">
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    29 yrs, 167cm - 5ft 6in
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Hindu , Yadava
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    B.Com
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Never Married
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Software Engineer
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Tamil
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    INR 10 Lakhs to 15 Lakhs
                                </li>
                            </ul>
                        </div>
                    </div>
                        <p>Studied at Ranchi Agriculture College, Ranchi Agriculture College
                            Works at ICICI Home Finance Company Limited</p>
                        <span class="span-two two">SEND INTEREST</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                            </div>
                            <div class="col-lg-8 col-md-12 p-2">
                                <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                            <li>4 months ago</li>
                        </ul>
                        <div class="row align-items-center">
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    29 yrs, 167cm - 5ft 6in
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Hindu , Yadava
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    B.Com
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Never Married
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Software Engineer
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Tamil
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    INR 10 Lakhs to 15 Lakhs
                                </li>
                            </ul>
                        </div>
                    </div>
                        <p>Studied at Ranchi Agriculture College, Ranchi Agriculture College
                            Works at ICICI Home Finance Company Limited</p>
                        <span class="span-two two">SEND INTEREST</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                            </div>
                            <div class="col-lg-8 col-md-12 p-2">
                                <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                            <li>4 months ago</li>
                        </ul>
                        <div class="row align-items-center">
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    29 yrs, 167cm - 5ft 6in
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Hindu , Yadava
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    B.Com
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Never Married
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Software Engineer
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Tamil
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    INR 10 Lakhs to 15 Lakhs
                                </li>
                            </ul>
                        </div>
                    </div>
                        <p>Studied at Ranchi Agriculture College, Ranchi Agriculture College
                            Works at ICICI Home Finance Company Limited</p>
                        <span class="span-two two">SEND INTEREST</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                            </div>
                            <div class="col-lg-8 col-md-12 p-2">
                                <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                            <li>4 months ago</li>
                        </ul>
                        <div class="row align-items-center">
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    29 yrs, 167cm - 5ft 6in
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Hindu , Yadava
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    B.Com
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Never Married
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Software Engineer
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Tamil
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    INR 10 Lakhs to 15 Lakhs
                                </li>
                            </ul>
                        </div>
                    </div>
                        <p>Studied at Ranchi Agriculture College, Ranchi Agriculture College
                            Works at ICICI Home Finance Company Limited</p>
                        <span class="span-two two">SEND INTEREST</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <img class="blur-image" src="assets/img/candidate5.jpg" alt="Employer">
                            </div>
                            <div class="col-lg-8 col-md-12 p-2">
                                <h3>Arun XXXX - <span style="color: #fe4a55;">YD5625467</span></h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Chennai, Tamilnadu</li>
                            <li>4 months ago</li>
                        </ul>
                        <div class="row align-items-center">
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    29 yrs, 167cm - 5ft 6in
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Hindu , Yadava
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    B.Com
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Never Married
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 member-details-list">
                            <ul>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Software Engineer
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    Tamil
                                </li>
                                <li>
                                    <i class='bx bx-message-square-check'></i>
                                    INR 10 Lakhs to 15 Lakhs
                                </li>
                            </ul>
                        </div>
                    </div>
                        <p>Studied at Ranchi Agriculture College, Ranchi Agriculture College
                            Works at ICICI Home Finance Company Limited</p>
                        <span class="span-two two">SEND INTEREST</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="pagination-area">
                    <ul>
                        <li><a routerLink="/jobs">Prev</a></li>
                        <li><a routerLink="/jobs">1</a></li>
                        <li><a routerLink="/jobs">2</a></li>
                        <li><a routerLink="/jobs">3</a></li>
                        <li><a routerLink="/jobs">Next</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
