import { ProfileService } from './../profile/profile.service';
import { ISignInResponse } from './../../_models/auth';
import { User } from './../../_models/user';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(
        private lStorage: LocalStorageService,
        private profileService: ProfileService
    ) {}

    saveValue(key: string, value: string) {
        this.lStorage.store(key, value);
    }

    retrieveValue(key: string) {
        return this.lStorage.retrieve(key);
    }

    removeValue(key: string): void {
        this.lStorage.clear(key);
    }

    clear() {
        this.lStorage.clear();
    }

    observeStorageItem(key: string) {
        return this.lStorage.observe(key);
    }

    public saveToken(token: string): void {
        this.removeValue(environment.userTokenStrKey);
        this.saveValue(environment.userTokenStrKey, token);
    }
    public getToken(): string | null {
        return this.retrieveValue(environment.userTokenStrKey);
    }
    public saveUser(user: any): void {
        this.removeValue(environment.UserStrKey);
        this.saveValue(environment.UserStrKey, JSON.stringify(user));
    }
    public saveAuthResponse(response: any): void {
        this.removeValue(environment.authRespKey);
        this.saveValue(environment.authRespKey, response)
    }
    public getAuthResponse(): any {
        return this.retrieveValue(environment.authRespKey)
    }

    public getUser(): ISignInResponse {
        const user = this.retrieveValue(environment.UserStrKey);
        if (user) {
            return JSON.parse(user);
        }
        return null;
    }

    public async isLoggedIn() {
        const token = this.getToken();
        if (token != null && token != '') {
            return await this.updateUserData();
        } else return false;
    }

    public async updateUserData() {
        return this.profileService.getUserById(this.getUser().id).subscribe(
            (data) => {
                this.saveUser(data);
                this.profileService.currentActiveUser = data;
                this.profileService.currentActiveUserSubject.next(data);
                this.profileService.loadProfileImage();
                return true;
            },
            (error) => {
                return false;
            }
        );
    }

    private tokenExpired(token: string) {
        const expiry = JSON.parse(atob(token.split('.')[1])).exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
}
