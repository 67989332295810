import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { AuthGuard } from './_helpers/auth.guard';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { LoginComponent } from './auth/login/login.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { AuthLayoutComponent } from './auth/auth-layout.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { OrderComponent } from './components/order/order.component';
import { OpenOrderComponent } from './components/open-order/order.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
        ],
    },
    {
        path: 'edit-profile',
        component: EditProfileComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/edit-profile/edit-profile.module').then(
                        (m) => m.EditProfileModule
                    ),
            },
        ],
    },
    {
        path: 'my-dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
        ],
    },
    {
        path: 'newprofiles',
        component: SearchComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/search/search.module').then(
                        (m) => m.SearchModule
                    ),
            },
        ],
    },
    {
        path: 'search',
        component: AdvancedSearchComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        'src/app/advanced-search/advanced-search.module'
                    ).then((m) => m.AdvancedSearchModule),
            },
        ],
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/auth/auth-layout.module').then(
                        (m) => m.AuthLayoutModule
                    ),
            },
        ],
    },
    { path: 'home-one', component: HomeOneComponent },
    { path: 'home-two', component: HomeTwoComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'employers', component: EmployersComponent },
    { path: 'employer-details', component: EmployersDetailsComponent },
    { path: 'single-resume', component: ResumeDetailsComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'open', component: OpenOrderComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'about', component: AboutComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'favourite-jobs', component: FavouriteJobsComponent },
    { path: 'job-details', component: JobDetailsComponent },
    { path: 'post-a-job', component: PostAJobComponent },
    { path: 'candidates', component: CandidatesComponent },
    { path: 'candidate-details', component: CandidatesDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'order', component: OrderComponent },

    { path: '**', component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
