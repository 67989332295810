import { ProfileService } from './../../_services/profile/profile.service';
import { StorageService } from './../../_services/local-storage/storage.service';
import { StaticDataService } from './../../_services/static-data/static-data.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from './../../_alert/alert.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-partner-pref',
    templateUrl: './partner-pref.component.html',
    styleUrls: ['./partner-pref.component.scss'],
})
export class PartnerPrefComponent implements OnInit {
    userId: number;
    maritalStatusList: any = [];
    selectedMaritalStatus: string = '';
    stateList: any = [];
    familyTypeList: any = [];
    selectedState: string = '';
    cityList: any = [];
    selectedCity: string = '';
    countryList: any = [];
    selectedCountry: string = '';
    religionList: any = [];
    selectedReligion: string = '';
    casteList: any = [];
    selectedCaste: string = '';
    subCasteList: any = [];
    selectedSubCaste: string = '';
    languageList: any = [];
    selectedLanguage: string = '';
    educationList: any = [];
    selectedEducation: string = '';
    professionList: any = [];
    selectedProfession: string = '';
    expectations = {
        general: 'N/A',
        residence_country: 'N/A',
        state: 'N/A',
        city: 'N/A',
        height: 'N/A',
        weight: 'N/A',
        maritalStatus: 'N/A',
        religion: 'N/A',
        caste: 'N/A',
        subCaste: 'N/A',
        language: 'N/A',
        education: 'N/A',
        profession: 'N/A',
        smokingAccept: 'N/A',
        drinkingAccept: 'N/A',
        bodyType: 'N/A',
        familyType: 'N/A',
        complexion: 'N/A',
    };
    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private staticDataService: StaticDataService,
        private storageService: StorageService,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.userId = params['id'];
        });
        this.educationList = [
            {
                id: 'B.Tech',
                name: 'B.tech',
            },
            {
                id: 'M.tech',
                name: 'M.tech',
            },
            {
                id: 'PHD',
                name: 'PHD',
            },
        ];

        this.professionList = [
            {
                id: 'Software Developer',
                name: 'Software Developer',
            },
            {
                id: 'Beauticion',
                name: 'Beauticion',
            },
            {
                id: 'Manager',
                name: 'Manager',
            },
        ];

        this.familyTypeList = [
            {
                id: 1,
                name: 'Join Famliy',
            },
            {
                id: 2,
                name: 'Nuclear Family',
            },
            {
                id: 3,
                name: 'Does Not Matter',
            },
        ];
        this.loadAllValues();
        setTimeout(() => {
            this.loadPartnerPrefValues();
        }, 1000);
    }

    loadAllValues() {
        this.staticDataService.languageSubject.subscribe((data) => {
            this.languageList = data;
        });

        this.staticDataService.professionSubject.subscribe((data) => {
            this.professionList = data;
        });

        this.staticDataService.casteSubject.subscribe((data) => {
            this.casteList = data;
        });

        this.staticDataService.citySubject.subscribe((data) => {
            this.cityList = data;
        });

        this.staticDataService.stateSubject.subscribe((data) => {
            this.stateList = data;
        });

        this.staticDataService.countrySubject.subscribe((data) => {
            this.countryList = data;
        });

        this.staticDataService.religionSubject.subscribe((data) => {
            this.religionList = data;
        });

        this.staticDataService.maritalStatusSubject.subscribe((data) => {
            this.maritalStatusList = data;
        });

        this.staticDataService.subCasteSubject.subscribe((data) => {
            this.subCasteList = data;
        });
    }

    loadPartnerPrefValues() {
        this.profileService
            .getExpectationsByUserId(this.userId)
            .subscribe((data) => {
                if (data) {
                    this.expectations.height = data.height + 'Feet';
                    this.expectations.weight = data.weight + 'Kg';
                    this.expectations.language =
                        data.languageId > 0
                            ? this.languageList.filter(
                                  (x) => x.id == data.languageId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.general = data.general;
                    this.expectations.residence_country =
                        data.preferredCountryId > 0
                            ? this.countryList.filter(
                                  (x) => x.id == data.preferredCountryId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.state =
                        data.preferredStateId > 0
                            ? this.stateList.filter(
                                  (x) => x.id == data.preferredStateId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.religion =
                        data.religionId > 0
                            ? this.religionList.filter(
                                  (x) => x.id == data.religionId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.caste =
                        data.casteId > 0
                            ? this.casteList.filter(
                                  (x) => x.id == data.casteId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.subCaste =
                        data.subCasteId > 0
                            ? this.subCasteList.filter(
                                  (x) => x.id == data.subCasteId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.familyType =
                        data.familyValueId > 0
                            ? this.familyTypeList.filter(
                                  (x) => x.id == data.familyValueId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.maritalStatus =
                        data.maritialStatusId > 0
                            ? this.maritalStatusList.filter(
                                  (x) => x.id == data.maritialStatusId
                              )[0]?.name
                            : 'N/A';
                    this.expectations.education = data.education
                        ? data.education
                        : 'N/A';
                    this.expectations.profession = data.profession
                        ? data.profession
                        : 'N/A';
                    this.expectations.smokingAccept = data.smokingAcceptable
                        ? data.smokingAcceptable
                        : 'N/A';
                    this.expectations.drinkingAccept = data.drinkingAcceptable
                        ? data.drinkingAcceptable
                        : 'N/A';
                    this.expectations.bodyType = data.bodyType
                        ? data.bodyType
                        : 'N/A';
                    this.expectations.complexion = data.complexion
                        ? data.complexion
                        : 'N/A';
                }
            });
    }
}
