<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>EditProfile</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>EditProfile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- EditProfile -->
<div class="dashboard-area mt-5">
    <app-edit-top></app-edit-top>
    <div class="container mt-4">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                >
                    <a
                        routerLink="manage-profile"
                        class="nav-link {{
                            selectedItem === 'v-pills-home' ? 'active' : ''
                        }}"
                        (click)="ChangeTab('v-pills-home')"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        href="#v-pills-home"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                        ><i class="bx bx-edit"></i> Manage Profile</a
                    >
                    <a
                        routerLink="change-password"
                        class="nav-link {{
                            selectedItem === 'v-pills-password' ? 'active' : ''
                        }}"
                        (click)="ChangeTab('v-pills-password')"
                        id="v-pills-password-tab"
                        data-bs-toggle="pill"
                        href="#v-pills-password"
                        role="tab"
                        aria-controls="v-pills-password"
                        aria-selected="false"
                    >
                        <div class="profile-list">
                            <i class="bx bxs-key"></i>
                            Change Password
                        </div>
                    </a>

                    <!-- <a
                        routerLink="partner-preference"
                        class="nav-link {{
                            selectedItem === 'v-pills-partner' ? 'active' : ''
                        }}"
                        id="v-pills-partner-tab"
                        data-bs-toggle="pill"
                        href="#v-pills-partner"
                        role="tab"
                        (click)="ChangeTab('v-pills-partner')"
                        aria-controls="v-pills-partner"
                        aria-selected="false"
                    >
                        <i class="bx bx-user"></i> Partner Preference</a
                    > -->
                    <a
                        routerLink="gallery"
                        class="nav-link {{
                            selectedItem === 'v-pills-gallery' ? 'active' : ''
                        }}"
                        id="v-pills-gallery-tab"
                        data-bs-toggle="pill"
                        href="#v-pills-gallery"
                        role="tab"
                        (click)="ChangeTab('v-pills-gallery')"
                        aria-controls="v-pills-gallery"
                        aria-selected="false"
                    >
                        <i class="bx bx-landscape"></i>Gallery
                    </a>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End EditProfile -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
