<div *ngIf="token != '' && token != null" class="partner-area home-search ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title text-center">
                    <span class="sub-title">Search</span>
                    <h2>Begin Your Search for an Ideal Match</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label class="mb-1">Gender</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" [formControl]="f.gender"  [settings]="dropdownSettingSingle" [data]="genderList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-3 d-flex justify-content-around align-items-center">
                <div class="form-group w-50">
                    <label class="mb-1">Age From </label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" [formControl]="f.ageFrom"  [settings]="dropdownSettingSingle" [data]="ageFromList">
                    </ng-multiselect-dropdown>
                </div>
                <label class="ms-3 me-3">To</label>
                <div class="form-group w-50">
                    <label class="mb-1">Age To</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" [formControl]="f.ageTo"  [settings]="dropdownSettingSingle" [data]="ageToList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label class="mb-1">Sub Caste</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" [formControl]="f.subCaste"  [settings]="dropdownSettings" [data]="subCasteList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label class="mb-1">Mother Tongue</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" [formControl]="f.language"  [settings]="dropdownSettings" [data]="languageList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mt-1">Search</button>
            </div>
        </div>
    </div>
</div>
