import { FooterStyleTwoComponent } from './footer-style-two/footer-style-two.component';
import { FooterStyleOneComponent } from './footer-style-one/footer-style-one.component';
import { NavbarStyleThreeComponent } from './navbar-style-three/navbar-style-three.component';
import { NavbarStyleTwoComponent } from './navbar-style-two/navbar-style-two.component';
import { NavbarStyleOneComponent } from './navbar-style-one/navbar-style-one.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { ApiPreLoaderComponent } from './api-pre-loader/api-pre-loader.component';
import { ProfileImage } from './profile-image/profile-image.component';



@NgModule({
  declarations: [
      NavbarStyleOneComponent,
      NavbarStyleTwoComponent,
      NavbarStyleThreeComponent,
      FooterStyleOneComponent,
      FooterStyleTwoComponent,
      LoaderComponent,
      ApiPreLoaderComponent,
      ProfileImage,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    LoaderComponent,
    ApiPreLoaderComponent,
    ProfileImage,
]
})
export class SharedModule { }
