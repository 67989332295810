import { StorageService } from '../local-storage/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    ISignInRequest,
    ISignInResponse,
    ISignUpReauest,
    User,
} from '../../_models';

@Injectable({ providedIn: 'root' })
export class AuthService {
    apiUrl = environment.apiUrl;
    static authUser: ISignInResponse | null = null;

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {}

    login(formData: ISignInRequest) {
        return this.http
            .post<any>(this.apiUrl + 'api/auth/sign-in', formData)
            .pipe(
                map((user) => {
                    if (user && user.accessToken) {
                        this.storageService.saveToken(user.accessToken);
                        this.storageService.saveUser(user);
                        this.storageService.saveAuthResponse(user);
                        AuthService.authUser = user;
                    }
                    return user;
                })
            );
    }

    register(formData: ISignUpReauest) {
        return this.http
            .post<any>(this.apiUrl + 'api/auth/sign-up', formData)
            .pipe(map((response) => response));
    }

    getUsers() {
        return this.http.get<User[]>(this.apiUrl + 'users/');
    }

    sendOtp(mobile: any) {
        return this.http
            .post<any>(this.apiUrl + 'api/auth/send/otp/v1', { mobile: mobile })
            .pipe(map((response) => response));
    }

    otpLogin({ mobile, otp }) {
        return this.http
            .post<any>(this.apiUrl + 'api/auth/sign-in/otp/v1', {
                mobile,
                otp,
            })
            .pipe(map((response) => response));
    }

    regOtpVerify(userData) {
        return this.http
            .post<any>(this.apiUrl + 'api/auth/verify/otp/v1', userData)
            .pipe(
                map((user) => {
                    if (user && user.accessToken) {
                        this.storageService.saveToken(user.accessToken);
                        this.storageService.saveUser(user);
                        this.storageService.saveAuthResponse(user)
                        AuthService.authUser = user;
                    }
                    return user;
                })
            );
    }

    logout() {
        localStorage.removeItem('currentUserToken');
        localStorage.removeItem('currentUser');
    }
}
