import { ProfileService } from './../_services/profile/profile.service';
import { StorageService } from './../_services/local-storage/storage.service';
import { StaticDataService } from './../_services/static-data/static-data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { IMaritalStatus } from '../_models/marital-status';
import {
    ICaste,
    ICity,
    ICountry,
    ILanguage,
    IProfession,
    IProfile,
    IQualification,
    IReligion,
    IState,
    ISubCaste,
} from '../_models';

const dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select',
    unSelectAllText: 'UnSelect',
    itemsShowLimit: 3,
    closeDropDownOnSelection: true,
    allowSearchFilter: true,
};

const singleSelectDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select',
    unSelectAllText: 'UnSelect',
    itemsShowLimit: 3,
    closeDropDownOnSelection: true,
    allowSearchFilter: true,
};

@Component({
    selector: 'app-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
    public partPrefForm!: FormGroup;
    submitted = false;
    searchTitle: string = 'Search';
    isNew: boolean = true;
    searchPanelActive: any[] = ['toggle-1'];
    dropdownSettings: IDropdownSettings = dropdownSettings;
    singleSelectDropdownSettings: IDropdownSettings =
        singleSelectDropdownSettings;
    userId: number = this.storageService.getUser().id;
    dhosamValues = [
        { id: 1, name: 'Raghu/Kedhu Dhosam' },
        { id: 2, name: 'Sevvai Dosam' },
    ];

    // country implemention
    selectedNativeCountry: ICountry[] = [];
    selectedNativeState: IState[] = [];
    selectedNativeCity: ICity[] = [];
    selectedCountry: ICountry[] = [];

    selectedMaritalStatus: IMaritalStatus[] = [];
    selectedState: IState[] = [];
    selectedCity: ICity[] = [];
    selectedReligion: IReligion[] = [];
    selectedCaste: ICaste[] = [];
    selectedSubCaste: ISubCaste[] = [];
    // selectedLanguage: ILanguage[] = [];
    selectedQualification: IQualification[] = [];
    selectedProfession: IProfession[] = [];
    selectedStars: any = [];

    maritalStatusList: IMaritalStatus[] = this.staticData.maritalStatusList;
    qualificationList: IQualification[] = this.staticData.qualificationList;
    professionList: IProfession[] = this.staticData.professionList;
    // languageList: ILanguage[] = this.staticData.languageList;
    
    // country implemention
    nativeCountryList: ICountry[] = this.staticData.countryList;
    nativeStateList: IState[] = this.staticData.stateList;
    nativeCityList: ICity[] = [];
    countryList: ICountry[] = this.staticData.countryList;

    stateList: IState[] = this.staticData.stateList;
    cityList: ICity[] = [];
    religionList: IReligion[] = this.staticData.religionList;
    casteList: ICaste[] = [];
    subCasteList: ISubCaste[] = [];
    communityValueList: any = [];
    profileList = [];
    startList: any = this.staticData.starList;

    // pagination
    pagination: any = { id: 'server', itemsPerPage: 15, currentPage: 1, totalItems: 0 };

    constructor(
        private formBuilder: FormBuilder,
        private staticData: StaticDataService,
        private storageService: StorageService,
        private profileService: ProfileService
    ) {
        staticData.loadStaticDataIfNotExist();
        this.loadStaticData();
    }

    loadStaticData() {
        this.staticData.maritalStatusSubject.subscribe((data) => {
            this.maritalStatusList = data;
        });
        this.staticData.qualificationSubject.subscribe((data) => {
            this.qualificationList = data;
        });
        this.staticData.professionSubject.subscribe((data) => {
            this.professionList = data;
        });
        // this.staticData.languageSubject.subscribe((data) => {
        //     this.languageList = data;
        // });
        this.staticData.countrySubject.subscribe((data) => {
            this.countryList = data;
            this.nativeCountryList = data;
        });
        // this.staticData.stateSubject.subscribe((data) => {
        //     this.stateList = data;
        // });
        this.staticData.religionSubject.subscribe((data) => {
            this.religionList = data;
        });
        this.staticData.starSub.subscribe((data) => {
            this.startList = data;
        });
        this.profileService.getCommunityValues().subscribe((data) => {
            this.communityValueList = data;
        });
    }

    listenToAgeChange() {
        this.partPrefForm.controls.ageTo.valueChanges.subscribe((ageTo) => {
            if (this.f.ageFrom.value) {
                if (parseInt(ageTo) < parseInt(this.f.ageFrom.value)) {
                    this.partPrefForm.controls['ageTo'].setErrors({
                        incorrect: true,
                        message: 'AgeTo need to be grater than AgeFrom',
                    });
                } else {
                    this.partPrefForm.controls['ageTo'].setErrors(null);
                }
            }
        });
        this.partPrefForm.controls.ageFrom.valueChanges.subscribe((ageFrom) => {
            if (parseInt(this.f.ageTo.value) < parseInt(ageFrom)) {
                this.partPrefForm.controls['ageTo'].setErrors({
                    incorrect: true,
                    message: 'AgeTo need to be grater than AgeFrom',
                });
            } else {
                this.partPrefForm.controls['ageTo'].setErrors(null);
            }
        });
    }

    ngOnInit(): void {
        this.partPrefForm = this.formBuilder.group({
            userId: [''],
            memberId: [''],
            ageFrom: ['', [Validators.min(18)]],
            ageTo: ['', [Validators.min(18)]],
            // country implemention
            maritalStatus: [[]],
            nativeCountry: [[]],
            nativeState: [[]],
            nativeCity: [[]],
            country: [[]],

            state: [[]],
            city: [[]],
            religion: [[]],
            caste: [[]],
            subCaste: [[]],
            star: [[]],
            qualification: [[]],
            profession: [[]],
            dhosam: [[]],
            physicalStatus: [[]],
            economicStatus: [[]],
            complexion: [[]],
        });
        this.loadAllValues();
        this.partPrefForm.patchValue({ userId: this.userId });
        this.listenToAgeChange();
    }

    get f(): { [key: string]: AbstractControl } {
        return this.partPrefForm.controls;
    }

    loadAllValues() {
        const { itemsPerPage, currentPage } = this.pagination;
        this.profileService.getAllProfiles(itemsPerPage, currentPage).subscribe((response: any) => {
            this.pagination.totalItems = response.totalItems;
            this.pagination.itemsPerPage = response.itemsPerPage;
            this.pagination.currentPage = response.currentPage;
            if (response) this.profileList = response.items;
        });
    }

    loadNativeState(data) {
        const countries = data.length !== undefined ? data : this.selectedNativeCountry;
        const ids = countries.map((item) => item.id);
        this.staticData.getStateByCountryIds(ids).subscribe((data: IState[]) => {
            this.nativeStateList = data;
        });
    }

    loadNativeCity(data) {
        const states = data.length !== undefined ? data : this.selectedNativeState;
        const ids = states.map((item) => item.id);
        this.staticData.getCityByStateIds(ids).subscribe((data: ICity[]) => {
            this.nativeCityList = data;
        });
    }

    loadState(data) {
        const countries = data.length !== undefined ? data : this.selectedCountry;
        const ids = countries.map((item) => item.id);
        this.staticData.getStateByCountryIds(ids).subscribe((data: IState[]) => {
            this.stateList = data;
        });
    }

    loadCity(data) {
        const states = data.length !== undefined ? data : this.selectedState;
        const ids = states.map((item) => item.id);
        this.staticData.getCityByStateIds(ids).subscribe((data: ICity[]) => {
            this.cityList = data;
        });
    }

    loadCaste(data) {
        this.casteList = [];
        if (!this.selectedReligion.length) return;
        this.selectedReligion.forEach(religion => {
            this.staticData.getCastesByReligionId(religion.id)
                .subscribe((data: ISubCaste[]) => {
                    this.casteList = [...this.casteList, ...data];
                });
        });
    }

    removeCasteList(data) {
        this.casteList = [];
    }

    loadSubCaste(data) {
        const castes = data.length !== undefined ? data : this.selectedCaste;
        const ids = castes.map((item) => item.id);
        this.staticData
            .getSubCastesByCasteIds(ids)
            .subscribe((data: ISubCaste[]) => {
                this.subCasteList = data;
            });
    }

    removeToggle() {
        this.searchTitle = 'Click Here to Modify Your Search';
        this.searchPanelActive = [];
    }

    onPartnerPrefFormSubmit(by): void {
        if (by !== 'pageChange')
            this.pagination.currentPage = 1;
        this.submitted = true;
        if (this.partPrefForm.invalid) return;
        const {
            memberId,
            ageFrom,
            ageTo,
            userId,
            dhosam,
            economicStatus,
            physicalStatus,
        } = this.partPrefForm.value;

        const formData = {
            userId,
            memberId: memberId || null,
            ageFrom: ageFrom || null,
            ageTo: ageTo || null,
            maritalStatus: this.selectedMaritalStatus.map((s) => s.id),
            // country implemention
            nativeCountries: this.selectedNativeCountry.map((c) => c.id),
            nativeStates: this.selectedNativeState.map((s) => s.id),
            nativeCities: this.selectedNativeCity.map((s) => s.id),
            countries: this.selectedCountry.map((c) => c.id),
            
            states: this.selectedState.map((s) => s.id),
            cities: this.selectedCity.map((s) => s.id),
            religions: this.selectedReligion.map((r) => r.id),
            castes: this.selectedCaste.map((c) => c.id),
            subCastes: this.selectedSubCaste.map((sc) => sc.id),
            stars: this.selectedStars.map((l) => l.id),
            professions: this.selectedProfession.map((p) => p.id),
            qualifications: this.selectedQualification.map((q) => q.id),
            sevvaiDhosam: dhosam.find((i) => i.id === 2) ? 'Yes' : null,
            raghuKethuDhosam: dhosam.find((i) => i.id === 1) ? 'Yes' : null,
            economicStatuses: economicStatus.map((f) => f.id),
            physicalStatus,
        };
        if (formData) {
            // this.removeToggle();
            const { itemsPerPage, currentPage } = this.pagination;
            this.profileService.postSearch(formData, itemsPerPage, currentPage).subscribe((response: any) => {
                this.pagination.totalItems = response.totalItems;
                this.pagination.itemsPerPage = response.itemsPerPage;
                this.pagination.currentPage = response.currentPage;
                if (response) this.profileList = response.items;
            });
        }
    }

    pageChange(event) {
        this.pagination.currentPage = event;
        if (this.submitted) this.onPartnerPrefFormSubmit('pageChange');
        else this.loadAllValues();
    }
}
