<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item d-none">
                    <h2>Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <app-filters></app-filters>
            </div>
            <div class="col-lg-8 col-md-12">
                <ul class="job-list-item align-items-center mb-3">
                    <li>
                        <a href="#">Results Found <span>(17)</span></a>
                    </li>
                    <li>
                        <span class="sort">Sort By:</span>
                        <form>
                            <div class="form-group">
                                <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>
                            </div>
                        </form>
                    </li>
                </ul>
                <ng-container *ngIf="objectKeys(profileList).length > 0">
                    <ng-container *ngFor="let profile of profileList">
                        <div class="employer-item">
                            <a [routerLink]="isLoggedIn ? ['/profile/'+profile.id] : ['/login']">
                                <div class="row align-items-center">
                                    <div class="col-lg-4 col-md-12">
                                        <ng-container *ngTemplateOutlet="profile.image ? profileImg : default_img; context: { profileData:profile}" ></ng-container>
                                    </div>
                                    <div class="col-lg-8 col-md-12 p-2">
                                            <h3 *ngIf="isLoggedIn">{{profile.firstName}} {{profile.lastName}} - <span style="color: #fe4a55;">{{profile.memberId}}</span></h3>
                                            <h3 *ngIf="!isLoggedIn">XXXXX XXXX - <span style="color: #fe4a55;">{{profile.memberId}}</span></h3>
                                <ul>
                                    <li *ngIf="profile.city || profile.state"><i class="flaticon-send"></i> {{profile.city}}, {{profile.state}}</li>
                                    <li *ngIf="profile.createdDate">{{profile.createdDate | datesince}}</li>
                                </ul>
                                <div class="row align-items-center">
                                <div class="col-md-6 member-details-list">
                                    <ul>
                                        <li *ngIf="profile.age || profile.height">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.age}} yrs {{profile.height}}
                                        </li>
                                        <li *ngIf="profile.religion || profile.caste">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.religion}} , {{profile.caste}}
                                        </li>
                                        <li *ngIf="profile.education">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.education}}
                                        </li>
                                        <li *ngIf="profile.maritalStatus">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.maritalStatus}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6 member-details-list">
                                    <ul>
                                        <li *ngIf="profile.occupation">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.occupation}}
                                        </li>
                                        <li  *ngIf="profile.motherTongue">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.motherTongue}}
                                        </li>
                                        <li *ngIf="profile.package">
                                            <i class='bx bx-message-square-check'></i>
                                            {{profile.package}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                <p *ngIf="profile.about">{{ (profile.about.length>80)? (profile.about | slice:0:100)+'...':(profile.about) }}</p>
                                <span class="span-two two">SEND INTEREST</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        </ng-container>
                        </ng-container>

                <div class="pagination-area">
                    <ul>
                        <li><a routerLink="/jobs">Prev</a></li>
                        <li><a routerLink="/jobs">1</a></li>
                        <li><a routerLink="/jobs">2</a></li>
                        <li><a routerLink="/jobs">3</a></li>
                        <li><a routerLink="/jobs">Next</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #default_img let-profile="profileData">
    <img style="width: 190px; padding: 10px;" [src]="(profile.gender == 'MALE')?'assets/img/home-two/banners/male.svg':'assets/img/home-two/banners/female.svg'" alt="Employer">
</ng-template>
<ng-template #profileImg let-profile="profileData">
    <img [src]="profileService.apiBase+profile.image" alt="Employer">
</ng-template>

<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
