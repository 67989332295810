<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Contact</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Info -->
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row row-eq-height">
            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-map'></i>
                    <h3>Address</h3>
                    <p>No 4, Thirumazhisai Via, Pudhuchatram Post,
                        Poonamallee Taluk. Chennai - 600124</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-phone'></i>
                    <h3>Call</h3>
                    <p>+91 7845381202</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-envelope'></i>
                    <h3>Message</h3>

                    <ul>
                        <li><span>Email:</span> <a href="mailto:info@tnyadavs.com">info@tnyadavs.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Info -->

<!-- Contact -->
<!-- <div class="contact-form-area pb-100">
    <div class="container">
        <div class="form-item">
            <h2>Contact Us</h2>

            <form id="contactForm">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control" required>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" required>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="text" name="phone_number" id="phone_number" required class="form-control">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <button type="submit" class="btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
<!-- End Contact -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
