<form [formGroup]="noteForm" (ngSubmit)="onNoteFormSubmit()">
    <textarea
        [formControlName]="'note'"
        type="text"
        name="introduction"
        class="form-control h-auto"
        rows="4"
        placeholder="Type note ..."
        [ngClass]="{ 'is-invalid': submitted && f.note.errors }"
    ></textarea>
    <br />
    <div class="text-end">
        <button type="submit" class="btn btn-primary btn-sm">
            {{ editNote ? "Update" : "Add" }} Note
        </button>
    </div>
</form>
<br />
<ul class="list-group">
    <li class="list-group-item custom-list" *ngFor="let note of notes">
        <div class="row">
            <div class="col-6 text-justify">{{ note.text }}</div>
            <div class="col-4">
                <button class="btn btn-sm">{{ note.time }}</button>
            </div>
            <div class="col-2">
                <button class="btn btn-sm" (click)="handleEditNote(note)">
                    <i class="bx bx-pencil text-warning"></i>
                </button>
                <button class="btn btn-sm" (click)="removeNote(note.id)">
                    <i class="bx bxs-trash text-danger"></i>
                </button>
            </div>
        </div>
    </li>
</ul>
