import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IOnBehalf } from 'src/app/_models/on-behalf';
import { IMaritalStatus } from 'src/app/_models/marital-status';
import { map } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import {
    IAstroAttribute,
    ICaste,
    ICity,
    ICountry,
    ILanguage,
    IProfession,
    IQualification,
    IReligion,
    IState,
    ISubCaste,
} from 'src/app/_models';

@Injectable({ providedIn: 'root' })
export class StaticDataService {
    apiUrl: string = environment.apiUrl + 'api/v1/';

    maritalStatusSubject: Subject<IMaritalStatus[]> = new Subject<
        IMaritalStatus[]
    >();
    maritalStatusList: IMaritalStatus[] = [];

    onBehalfSubject: Subject<IOnBehalf[]> = new Subject<IOnBehalf[]>();
    onBehalfList: IOnBehalf[] = [];

    countrySubject: Subject<ICountry[]> = new Subject<ICountry[]>();
    countryList: ICountry[] = [];

    stateSubject: Subject<IState[]> = new Subject<IState[]>();
    stateList: IState[] = [];

    citySubject: Subject<ICity[]> = new Subject<ICity[]>();
    cityList: ICity[] = [];

    religionSubject: Subject<IReligion[]> = new Subject<IReligion[]>();
    religionList: IReligion[] = [];

    casteSubject: Subject<ICaste[]> = new Subject<ICaste[]>();
    casteList: ICaste[] = [];

    subCasteSubject: Subject<ISubCaste[]> = new Subject<ISubCaste[]>();
    subCasteList: ISubCaste[] = [];

    professionSubject: Subject<IProfession[]> = new Subject<IProfession[]>();
    professionList: IProfession[] = [];

    languageSubject: Subject<ILanguage[]> = new Subject<ILanguage[]>();
    languageList: ILanguage[] = [];

    qualificationSubject: Subject<IQualification[]> = new Subject<
        IQualification[]
    >();
    qualificationList: IQualification[] = [];

    starSub: Subject<IAstroAttribute[]> = new Subject<IAstroAttribute[]>();
    starList: IAstroAttribute[] = [];

    raasiSub: Subject<IAstroAttribute[]> = new Subject<IAstroAttribute[]>();
    raasiList: IAstroAttribute[] = [];

    lagnamSub: Subject<IAstroAttribute[]> = new Subject<IAstroAttribute[]>();
    lagnamList: IAstroAttribute[] = [];

    paadhamSub: Subject<IAstroAttribute[]> = new Subject<IAstroAttribute[]>();
    paadhamList: IAstroAttribute[] = [];

    constructor(private http: HttpClient) {}

    loadStaticDataIfNotExist() {
        if (!this.maritalStatusList.length) {
            this.getMaritalStatus().subscribe();
            this.getOnBehalOf().subscribe();
            this.getQualifications().subscribe();
            this.getProfession().subscribe();
            this.getLanguages().subscribe();

            this.getCountries().subscribe();
            this.getStates().subscribe();
            this.getCities().subscribe();

            this.getReligions().subscribe();
            this.getCastes().subscribe();
            this.getSubCastes().subscribe();

            this.getStar().subscribe();
            this.getRaasi().subscribe();
            this.getLagnam().subscribe();
            this.getPaadham().subscribe();
            
        }
    }

    getStar() {
        return this.http.get<IAstroAttribute[]>(this.apiUrl + 'star').pipe(
            map((data: IAstroAttribute[]) => {
                this.starList = data;
                this.starSub.next(data);
                return data;
            })
        );
    }
    getRaasi() {
        return this.http.get<IAstroAttribute[]>(this.apiUrl + 'raasi').pipe(
            map((data: IAstroAttribute[]) => {
                this.raasiList = data;
                this.raasiSub.next(data);
                return data;
            })
        );
    }
    getPaadham() {
        return this.http.get<IAstroAttribute[]>(this.apiUrl + 'paadham').pipe(
            map((data: IAstroAttribute[]) => {
                this.paadhamList = data;
                this.paadhamSub.next(data);
                return data;
            })
        );
    }
    getLagnam() {
        return this.http.get<IAstroAttribute[]>(this.apiUrl + 'lagnam').pipe(
            map((data: IAstroAttribute[]) => {
                this.lagnamList = data;
                this.lagnamSub.next(data);
                return data;
            })
        );
    }

    getMaritalStatus() {
        return this.http
            .get<IMaritalStatus[]>(this.apiUrl + 'marital-status')
            .pipe(
                map((data: IMaritalStatus[]) => {
                    this.maritalStatusList = data;
                    this.maritalStatusSubject.next(data);
                    return data;
                })
            );
    }

    getOnBehalOf() {
        return this.http.get<IOnBehalf[]>(this.apiUrl + 'onbehalfs').pipe(
            map((data: IOnBehalf[]) => {
                this.onBehalfList = data;
                this.onBehalfSubject.next(data);
                return data;
            })
        );
    }

    getQualifications() {
        return this.http
            .get<IQualification[]>(this.apiUrl + 'qualifications')
            .pipe(
                map((data: IQualification[]) => {
                    this.qualificationList = data;
                    this.qualificationSubject.next(data);
                    return data;
                })
            );
    }

    getCountries() {
        return this.http.get<ICountry[]>(this.apiUrl + 'countries').pipe(
            map((data: ICountry[]) => {
                this.countryList = data;
                this.countrySubject.next(data);
                return data;
            })
        );
    }

    getStates() {
        return this.http.get<IState[]>(this.apiUrl + 'states').pipe(
            map((data: IState[]) => {
                this.stateList = data;
                this.stateSubject.next(data);
                return data;
            })
        );
    }

    getStateByCuntryId(countryId, fromServer = false) {
        if (this.stateList.length && !fromServer) {
            const states = this.stateList.filter(
                (s) => s.countryId === parseInt(countryId)
            );
            let resultSubject = new ReplaySubject(1);
            resultSubject.next(states);
            return resultSubject;
        }
        return this.http.get<IState[]>(
            this.apiUrl + 'states/getByCuntryId/' + countryId
        );
    }

    getStateByCountryIds(ids: number[], fromServer = false) {
        const subject = new ReplaySubject(1);
        subject.next([]);
        if (ids.length && this.stateList.length) {
            const states = this.stateList.filter((s) =>
                ids.includes(s.countryId)
            );
            subject.next(states);
        }
        return subject;
    }


    getCityByStateIds(ids: number[], fromServer = false) {
        const subject = new ReplaySubject(1);
        subject.next([]);
        if (ids.length && this.cityList.length) {
            const states = this.cityList.filter((c) => ids.includes(c.stateId));
            subject.next(states);
        }
        return subject;
    }

    getCityByStateId(stateId, fromServer = false) {
        if (this.cityList.length && !fromServer) {
            const states = this.cityList.filter(
                (c) => c.stateId === parseInt(stateId)
            );
            let resultSubject = new ReplaySubject(1);
            resultSubject.next(states);
            return resultSubject;
        }
        return this.http.get<ICity[]>(
            this.apiUrl + 'cities/getByStateId/' + stateId
        );
    }

    getCities() {
        return this.http.get<ICity[]>(this.apiUrl + 'cities').pipe(
            map((data: ICity[]) => {
                this.cityList = data;
                this.citySubject.next(data);
                return data;
            })
        );
    }

    getReligions() {
        return this.http.get(this.apiUrl + 'religions').pipe(
            map((data: IReligion[]) => {
                this.religionList = data;
                this.religionSubject.next(data);
                return data;
            })
        );
    }

    getCastes() {
        return this.http.get(this.apiUrl + 'castes').pipe(
            map((data: ICaste[]) => {
                this.casteList = data;
                this.casteSubject.next(data);
                return data;
            })
        );
    }

    getCastesByReligionId(religionId, fromServer = false) {
        if (this.casteList.length && !fromServer) {
            const states = this.casteList.filter(
                (c) => c.religionId === parseInt(religionId)
            );
            let resultSubject = new ReplaySubject(1);
            resultSubject.next(states);
            return resultSubject;
        }
        return this.http.get(
            this.apiUrl + 'castes/getByReligionId/' + religionId
        );
    }

    getSubCastes() {
        return this.http.get<ISubCaste[]>(this.apiUrl + 'sub-castes').pipe(
            map((data: ISubCaste[]) => {
                this.subCasteList = data;
                this.subCasteSubject.next(data);
                return data;
            })
        );
    }

    getSubCastesByCasteIds(ids: number[], fromServer = false) {
        const subject = new ReplaySubject(1);
        subject.next([]);
        if (ids.length && this.casteList.length) {
            const subCastes = this.subCasteList.filter((c) =>
                ids.includes(c.casteId)
            );
            subject.next(subCastes);
        }
        return subject;
    }

    getSubCastesByCasteId(casteId, fromServer = false) {
        if (this.subCasteList.length && !fromServer) {
            const subCastes = this.subCasteList.filter(
                (s) => s.casteId === parseInt(casteId)
            );
            let resultSubject = new ReplaySubject(1);
            resultSubject.next(subCastes);
            return resultSubject;
        }
        return this.http.get(
            this.apiUrl + 'sub-castes/getAllByCasteId/' + casteId
        );
    }

    getLanguages() {
        return this.http.get<ILanguage[]>(this.apiUrl + 'languages').pipe(
            map((data: ILanguage[]) => {
                this.languageList = data;
                this.languageSubject.next(data);
                return data;
            })
        );
    }

    getProfession() {
        return this.http.get<IProfession[]>(this.apiUrl + 'professions').pipe(
            map((data: IProfession[]) => {
                this.professionList = data;
                this.professionSubject.next(data);
                return data;
            })
        );
    }

    getEconomicStatus() {
        return this.http.get(this.apiUrl + 'community-values').pipe(
            map((data: any[]) => {
                return data;
            })
        );
    }
}
