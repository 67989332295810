import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { IGallery } from 'src/app/_models';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { FileUpload } from '../file-upload/file-upload.component';

@Component({
    selector: 'app-profile-gallery',
    templateUrl: './profile-gallery.component.html',
    styleUrls: ['./profile-gallery.component.scss'],
})
export class ProfileGalleryComponent implements OnInit {
    selectedUserId: number;
    loggedInUserId: number;
    galleries: IGallery[] = [];
    closeResult: string;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private storageService: StorageService,
        private profileService: ProfileService,
        private modalService: NgbModal,
        private domSanitizer: DomSanitizer
    ) {
        this.route.params.subscribe((params) => {
            this.selectedUserId = parseInt(params['id']);
        });
        this.loggedInUserId = this.storageService.getUser().id;
        if (!this.selectedUserId) this.selectedUserId = this.loggedInUserId;
    }

    ngOnInit(): void {
        this.loadInitialData();
    }

    loadInitialData() {
        this.profileService
            .getGalleryByUserId(this.selectedUserId)
            .subscribe((data: IGallery[]) => {
                if (data) this.galleries = data;
            });
    }

    openUploadModel() {
        const size = { size: 'xl', centered: true };
        const modalRef = this.modalService.open(FileUpload, size);
        modalRef.componentInstance.userId = this.selectedUserId;
        modalRef.componentInstance.galleryType = 'GALLERY_IMAGE';
        modalRef.result.then(
            (result) => this.galleries.push(result),
            (reason) => this.setDismissReason(reason)
        );
    }

    private setDismissReason(reason: ModalDismissReasons): void {
        let reasonStr = '';
        if (reason === ModalDismissReasons.ESC) {
            reasonStr = 'Dismissed By ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            reasonStr = 'Dismissed By Backdrop';
        } else {
            reasonStr = `Dismissed By with: ${reason}`;
        }
        this.closeResult = reasonStr;
    }

    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) if (arr[i][attr] === value) arr.splice(i, 1);
    }

    deleteGallery(galleryId: number) {
        this.profileService.deleteGalleryById(galleryId).subscribe((data) => {
            if (data) this.removeByAttr(this.galleries, 'id', galleryId);
        });
    }
}
