<!-- Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo-three.png" alt="Logo" />
        </a>
    </div>
    <div class="main-nav" [ngClass]="{ three: !isHome }">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img *ngIf="isHome" src="assets/img/logo.png" alt="Logo" />
                    <img
                        *ngIf="!isHome"
                        src="assets/img/logo-three.png"
                        alt="Logo"
                    />
                </a>

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <span class="tooltip-span">New</span>
                            <a
                                routerLink="/search"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                            >
                                Search
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/my-dashboard"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                            >
                                Dashboard
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                            >
                                Contact
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/pricing"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                            >
                                Pricing
                            </a>
                        </li>
                        <li class="nav-item text-danger">
                            {{ getPaymentDetails() }}
                        </li>
                        <li class="nav-item mobile-dropdown" *ngIf="isLoggedIn">
                            <a href="#" class="nav-link dropdown-toggle">
                                {{ currentUser.firstName }}
                                {{ currentUser.lastName }}
                                <i class="bx bx-chevron-down"></i>
                            </a>
                            <a
                                routerLinkActive="active"
                                [routerLink]="['/edit-profile']"
                                class="nav-link"
                            >
                                <i class="bx bx-user"></i>
                                Edit Profile
                            </a>
                            <a
                                routerLinkActive="active"
                                [routerLink]="['/logout']"
                                class="nav-link"
                            >
                                <i class="bx bx-lock"></i>
                                Logout
                            </a>
                        </li>
                    </ul>

                    <div class="side-nav">
                        <ul
                            *ngIf="isLoggedIn"
                            class="navbar-nav align-items-center d-none d-md-flex"
                        >
                            <li class="nav-item">
                                <a
                                    class="nav-link pr-0 dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div
                                        class="media d-flex align-items-center"
                                    >
                                        <img
                                            [src]="profileService?.profileImage"
                                            class="avatar avatar-sm rounded-circle"
                                        />
                                        <div
                                            class="media-body ml-2 d-none d-lg-block"
                                        >
                                            <span
                                                class="mb-0 ms-2 text-sm font-weight-bold"
                                                >{{ currentUser.firstName }}
                                                {{ currentUser.lastName }}
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <div
                                    class="dropdown-menu dropdown-menu-end my-profile-menu"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <div class="dropdown-header noti-title">
                                        <h6 class="text-overflow m-0">
                                            Welcome !
                                        </h6>
                                    </div>
                                    <a
                                        routerLinkActive="active"
                                        [routerLink]="['/my-dashboard']"
                                        class="dropdown-item"
                                    >
                                        <i class="bx bx-brightness"></i>
                                        <span>Dashboard</span>
                                    </a>
                                    <a
                                        routerLinkActive="active"
                                        [routerLink]="['/edit-profile']"
                                        class="dropdown-item"
                                    >
                                        <i class="bx bx-user"></i>
                                        <span>Edit Profile</span>
                                    </a>
                                    <a
                                        routerLinkActive="active"
                                        [routerLink]="[
                                            '/edit-profile/change-password'
                                        ]"
                                        class="dropdown-item"
                                    >
                                        <i class="bx bx-key"></i>
                                        <span>Change Password</span>
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <button
                                        (click)="logout()"
                                        class="dropdown-item px-2 mb-2"
                                    >
                                        <i class="bx bx-run me-2"></i>
                                        <span class="font-weight-bold">
                                            Logout
                                        </span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <a
                            *ngIf="!isLoggedIn"
                            class="job-right"
                            routerLink="/login"
                        >
                            Login Now <i class="bx bx-plus"></i>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar -->
