import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
declare var Razorpay: any;

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(private http: HttpClient) {}

    encryptPayload(formData) {
        return this.http.post(
            environment.apiUrl + 'api/v1/ccavenue/requestHandler',
            formData,
            httpOptions
        );
    }

    createOrder(order): Observable<any> {
        return this.http.post(
            environment.apiUrl + 'api/v1/orders',
            {
                customerName: order.name,
                email: order.email,
                phoneNumber: order.phone,
                amount: order.amount,
            },
            httpOptions
        );
    }

    updateOrder(order): Observable<any> {
        return this.http.put(
            environment.apiUrl + 'api/v1/orders',
            {
                packageId: order.id,
                paidAmount: order.paidAmount,
                razorpayOrderId: order.razorpay_order_id,
                razorpayPaymentId: order.razorpay_payment_id,
                razorpaySignature: order.razorpay_signature,
            },
            httpOptions
        );
    }
}
