import { ProfileService } from 'src/app/_services/profile/profile.service';
import { StorageService } from './../_services/local-storage/storage.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../_alert';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    private routeSub: Subscription;
    userId: number;
    userProfileImage: string = '';
    selectedItem: string = 'home';
    user: any = null;
    loggedInUserId: number = this.storageService.getUser().id;
    profile;
    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        public profileService: ProfileService,
        private domSanitizer: DomSanitizer,
        private alertService: AlertService
    ) {
        this.profileService.currentActiveUserSubject.subscribe((user) => {
            this.profileService
                .getProfileDetailsByUserId(user.id)
                .subscribe((data) => {
                    this.profile = data;
                });
        });
    }

    ngOnInit(): void {
        this.routeSub = this.route.params.subscribe((params) => {
            this.userId = parseInt(params['id']);
            this.loadInitialData();
        });
    }

    loadInitialData() {
        if (this.userId) {
            this.profileService.getProfileDetailsByUserId(this.userId).subscribe((user) => {
                this.user = user;
                this.userProfileImage =
                    user.gender == 'MALE'
                        ? 'assets/img/home-two/banners/male.svg'
                        : 'assets/img/home-two/banners/female.svg';
                this.loadProfileImage();
            });
        }
    }

    loadProfileImage() {
        this.profileService
            .getProfileImageByUserId(this.userId)
            .subscribe((res: any) => {
                if (res && res.content) {
                    let src = `data:${res.fileType};base64, ${res.content}`;
                    this.userProfileImage =
                        this.domSanitizer.bypassSecurityTrustUrl(src) as string;
                } else {
                    this.userProfileImage =
                        this.user.gender == 'MALE'
                            ? 'assets/img/home-two/banners/male.svg'
                            : 'assets/img/home-two/banners/female.svg';
                }
            });
    }

    changeTab(value: string) {
        this.selectedItem = value;
    }

    updateStatus(event, status: string) {
        alert(status)
        return;
        if (this.loggedInUserId === this.userId) {
            event.preventDefault();
            let alertStatus = '';
            switch (status) {
                case 'MYBOOK':
                    alertStatus = 'Add To My book';
                    break;
                case 'SENT':
                    alertStatus = 'Send Interest';
                    break;
                case 'REJECTED':
                    alertStatus = 'Reject';
                    break;
                case 'REPORTED':
                    alertStatus = 'Send Report';
                    break;
                case 'BLOCKED':
                    alertStatus = 'Block';
                    break;
            }
            this.alertService.error(`Self ${alertStatus} Not Allowed`);
            return;
        }
        if (status === 'SENT') {
            this.profileService
                .postSendInterest(this.storageService.getUser().id, this.userId)
                .subscribe((data) => {
                    this.alertService.success(`Updated successfully`);
                    this.loadInitialData();
                });
        } else {
            this.profileService
                .postStateUpdate(
                    this.storageService.getUser().id,
                    this.userId,
                    status
                )
                .subscribe((data) => {
                    this.alertService.success(`Updated successfully`);
                    this.loadInitialData();
                });
        }
        return false;
    }
}
