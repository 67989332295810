<section *ngIf="ObjectKey(newProfiles).length != 0" class="location-area pb-70 pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Wanted Location</span>
                    <h2>Let's Engage with Our <span class="primary-color">New Profiles</span></h2>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="cmn-link">
                    <a routerLink="/search"><i class="flaticon-right-arrow one"></i> Browse All Profiles <i class="flaticon-right-arrow two"></i></a>
                </div>
            </div>
        </div>
    </div>
    <app-loader *ngIf="this.customLoader"></app-loader>

    <div class="container-fluid">
        <ng-container *ngIf="!this.customLoader">
        <owl-carousel
        [options]="owlCarousalOptions"
        [carouselClasses]="['location-slider owl-theme owl-carousel', 'home-profile-slider', 'owl-carousel']">
        <div class="location-item" *ngFor="let profile of newProfiles">
           <div class="top" [ngClass]="{'blur-image': !isLoggedIn}">
               <a [routerLink]="isLoggedIn ? ['/profile/'+profile.id] : ['/login']"><ng-container *ngTemplateOutlet="profile.image ? profileImg : default_img; context: { profileData:profile}" ></ng-container>
               </a>
           </div>
           <span>{{profile.age}} Yrs</span>
           <div class="basic-info">
               <h3>{{profile.memberId}}</h3>
           <p class="occupation mb-0">{{profile.occupation}}</p>
           </div>
       </div>
    </owl-carousel>
</ng-container>
        <!-- <div class="location-slider owl-theme owl-carousel home-profile-slider">
            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>25yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>

            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>31yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>

            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>21yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>

            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>22yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>
            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>22yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>
            <div class="location-item">
                <div class="top">
                    <a routerLink="/jobs"><img src="assets/img/candidate5.jpg" alt="Location"></a>
                </div>
                <span>22yrs</span>
                <div class="basic-info">
                    <h3>TNYD897</h3>
                <p class="occupation mb-0">IT Professional</p>
                </div>
            </div>
        </div> -->
    </div>
</section>
<ng-template #default_img let-profile="profileData">
    <img style="width: 190px; padding: 10px;" [src]="(profile.gender == 'MALE')?'assets/img/home-two/banners/male.svg':'assets/img/home-two/banners/female.svg'" alt="Employer">
</ng-template>
<ng-template #profileImg let-profile="profileData">
    <img [src]="profileService.apiBase+profile.image" alt="Employer">
</ng-template>
