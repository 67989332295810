import { ProfileService } from 'src/app/_services/profile/profile.service';
import { ISignInResponse } from './../../_models/auth';
import { Router } from '@angular/router';
import { StorageService } from './../../_services/local-storage/storage.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss'],
})
export class NavbarStyleOneComponent implements OnInit {
    isLoggedIn: boolean = false;
    isHome: boolean = false;
    public currentUser: ISignInResponse;
    public authUser: any;

    constructor(
        private storageService: StorageService,
        public profileService: ProfileService,
        private router: Router,
        private location: Location
    ) {}

    ngOnInit(): void {
        if (this.storageService.getToken()) this.isLoggedIn = true;
        this.currentUser = this.storageService.getUser();
        this.profileService.currentActiveUserSubject.subscribe((user) => {
            this.currentUser = user;
            this.authUser = this.storageService.getAuthResponse();
            console.log('authUser: ' + this.authUser.paymentExpiryDate);
        });
        this.checkHome();
    }

    getPaymentDetails(): string {
        if (!this.authUser?.paymentExpiryDate)
            return 'No active payment found.';
        const startDate = new Date();
        const endDate = new Date(this.authUser?.paymentExpiryDate.split('T')[0]);
        const timeDiff = endDate.getTime() - startDate.getTime();
        const days = Math.floor(timeDiff / (1000 * 3600 * 24));
        const expiryDate = this.authUser.paymentExpiryDate
            .split('T')[0]
            .split('-')
            .reverse()
            .join('-');
        return `Expiry ${expiryDate} (${days} days)`;
    }

    logout() {
        this.storageService.clear();
        this.router.navigate(['/login']);
    }

    checkHome() {
        let urlPath = this.location.prepareExternalUrl(this.location.path());
        if (urlPath === '/' || urlPath === '/login' || urlPath === '/register')
            this.isHome = true;
        else this.isHome = false;
    }
}
