import { filter } from 'rxjs/operators';
import { StaticDataService } from './../../_services/static-data/static-data.service';
import { filters } from './../../_models/user';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
    fltrMaritalStatus: filters[] = [];
    cityFilters: filters[] = [];
    stateFilters: filters[] = [];
    casteFilters: filters[] = [];
    motherTongueFilters: filters[] = [];
    selectedFilters: filters[] = [];
    ObjectKey: any = Object.keys;
    constructor(private staticDataService: StaticDataService) {}

    ngOnInit(): void {
        this.staticDataService.getMaritalStatus().subscribe((data) => {
            let filteredData: any = data;
            this.fltrMaritalStatus = filteredData.map((marital) => {
                return { id: marital.id, type: 'marital', name: marital.name };
            });
        });

        this.staticDataService.getStates().subscribe((data) => {
            let filteredData: any = data;
            this.stateFilters = filteredData.map((state) => {
                return { id: state.id, type: 'state', name: state.name };
            });
        });

        this.staticDataService.subCasteSubject.subscribe((data) => {
            let filteredData: any = data;
            this.casteFilters = filteredData.map((caste) => {
                return { id: caste.id, type: 'caste', name: caste.name };
            });
        });

        this.staticDataService.getLanguages().subscribe((data) => {
            let filteredData: any = data;
            this.motherTongueFilters = filteredData.map((motherTongue) => {
                return {
                    id: motherTongue.id,
                    type: 'motherTongue',
                    name: motherTongue.name,
                };
            });
        });
    }

    updateFilters(event: any, filter: filters) {
        if (event.target.checked) {
            this.selectedFilters.push(filter);
            if (filter.type == 'state') {
                this.updateCityFilters();
            }
        } else {
            this.selectedFilters = this.selectedFilters.filter(
                (m) => m.id !== filter.id || m.type !== filter.type
            );
            if (filter.type == 'state') {
                this.updateCityFilters();
            }
        }
    }

    removeFilter(filter: filters) {
        this.selectedFilters = this.selectedFilters.filter(
            (m) => m.id !== filter.id || m.type !== filter.type
        );
        let element = <HTMLInputElement>(
            document.getElementById(filter.id + '-' + filter.type)
        );
        element.checked = false;
    }

    updateCityFilters() {
        let stateIds: number[] = [];
        this.selectedFilters.forEach((x) => {
            if (x.type == 'state') {
                stateIds.push(parseInt(x.id));
            }
        });
        if (stateIds.length > 0) {
            this.addCityFilter(stateIds);
        } else {
            this.selectedFilters = this.selectedFilters.filter(
                (m) => m.type !== 'city'
            );
            this.cityFilters = [];
        }
    }

    addCityFilter(stateIds) {
        this.staticDataService
            .getCityByStateId(stateIds[0])
            .subscribe((data) => {
                if (data) {
                    let filteredData: any = data;
                    let dataGet = filteredData.map((city) => {
                        return { id: city.id, type: 'city', name: city.name };
                    });
                    this.cityFilters = [...dataGet];
                    stateIds.splice(0, 1);
                    if (stateIds.length > 0) {
                        this.addCityFilter(stateIds);
                    }
                }
            });
    }
}
