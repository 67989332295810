<div class="container">
    <div class="row text-center pb-4" *ngIf="selectedUserId === loggedInUserId">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <button class="btn btn-sm btn-danger" (click)="openUploadModel()">
                Click here to uplod image
            </button>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row">
        <app-file-viewer
            *ngFor="let gallery of galleries.reverse()"
            class="col-md-4 mb-4"
            [gallery]="gallery"
            (onDeleteEvent)="deleteGallery($event)"
        ></app-file-viewer>
    </div>
</div>
