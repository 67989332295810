<!-- <img [src]="src" style="min-height: 270px;max-height: 270px;" /> -->
<div class="profile-img">
    <img [src]="src" />
    <div class="edit-icon" *ngIf="gallery.userId == userId">
        <i
            (click)="handleDelete(gallery?.id)"
            class="bx bx-trash"
            title="Delete this file"
            style="
                margin-right: -18px;
                background-color: transparent;
                color: white;
            "
        ></i>
    </div>
</div>
