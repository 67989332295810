import { Router } from '@angular/router';
import { StorageService } from './../_services/local-storage/storage.service';
import { AlertService } from './../_alert/alert.service';
// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiLoaderService } from '../_services/api-loader/api-loader.service';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private loaderService: ApiLoaderService,
        private alertService: AlertService,
        private storageService: StorageService,
        private router: Router
    ) {}

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        //this.loaderService.isLoading.next(this.requests.length > 0);
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        return Observable.create((observer) => {
            const subscription = next.handle(req).subscribe(
                (event) => {
                    if (event instanceof HttpResponse) {
                        this.removeRequest(req);
                        observer.next(event);
                    }
                },
                (err) => {
                    this.removeRequest(req);
                    observer.error(err);
                    if(!this.storageService.getToken() && err.status == 401) {
                        this.alertService.error("Username and password are incorrect!")
                    } else if (this.storageService.getToken() && err.status == 401) {
                        this.alertService.error('Your Session was Expired');
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    } else if (err.status === 500) {
                        this.alertService.error(err.error);
                    } else if (err.status !== 200 || err.status !== 201) {
                        this.alertService.error('Server Error - ' + err.status);
                    } else if (!this.storageService.getToken()) {
                        this.alertService.error('Please login again');
                    } else {
                        this.alertService.error(err.error);
                    }
                },
                () => {
                    this.removeRequest(req);
                    observer.complete();
                }
            );
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
