<div class="container">
    <div class="row dashboard-user-top">
        <div class="col-lg-3 col-md-12">
            <div class="profile-img">
                <img [src]="profileService?.profileImage" height="200" />
                <div class="edit-icon" (click)="imageUpload()">
                    <i class="bx bx-message-square-edit"></i>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 position-relative mt-3">
            <div class="row top-profile-section d-flex justify-content-between">
                <div class="top-left-section col-md-6">
                    <h3>
                        {{ profileService?.currentActiveUser?.firstName }}
                        {{ profileService?.currentActiveUser?.lastName }}
                    </h3>
                    <p>
                        {{ profileService?.currentActiveUser?.profession }}
                    </p>
                </div>
                <div class="top-right-section col-md-6">
                    <button
                        routerLink="/profile/{{
                            profileService?.currentActiveUser?.id
                        }}"
                        type="submit"
                        class="btn btn-primary"
                    >
                        View Public Profile
                    </button>
                </div>
            </div>
            <div class="bottom-profile-section mt-3">
                <ul>
                    <li>
                        <i class="bx bx-id-card"></i>
                        {{ profile?.memberId }}
                    </li>
                    <li>
                        <i class="bx bx-heart-square"></i>
                        {{ getMaritalStatus(profile?.maritalStatus) }}
                    </li>
                </ul>
                <ul>
                    <li *ngIf="profile?.state">
                        <i class="bx bx-map"></i>
                        {{ getState(profile?.state) }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-3 col-md-12 profile-counts" style="display: none">
            <div class="card mb-4">
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-8">
                            <div class="numbers">
                                <p
                                    class="text-sm mb-0 text-capitalize font-weight-bold"
                                >
                                    Profile Views
                                </p>
                                <h5 class="font-weight-bolder mb-0">+3,462</h5>
                            </div>
                        </div>
                        <div
                            class="col-4 text-end d-flex flex-column justify-content-center"
                        >
                            <div
                                class="icon icon-shape bg-gradient-primary text-center border-radius-md d-flex"
                            >
                                <i class="bx bx-happy-heart-eyes"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-8">
                            <div class="numbers">
                                <p
                                    class="text-sm mb-0 text-capitalize font-weight-bold"
                                >
                                    Interest Sent
                                </p>
                                <h5 class="font-weight-bolder mb-0">+3,462</h5>
                            </div>
                        </div>
                        <div
                            class="col-4 text-end d-flex flex-column justify-content-center"
                        >
                            <div
                                class="icon icon-shape bg-gradient-primary text-center border-radius-md d-flex"
                            >
                                <i class="bx bx-happy-heart-eyes"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
