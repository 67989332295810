<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Banner -->
<div class="banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" />
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>
                                Someone Somewhere is <span>Dreaming</span> of
                                You
                            </h1>
                            <p>
                                We have 1000+ active profiles, helping you to
                                find your soulmate
                            </p>

                            <form
                                *ngIf="!isLoggedIn"
                                [formGroup]="homeRegForm"
                                (ngSubmit)="onHomeRegFormSubmit()"
                            >
                                <div class="row">
                                    <div
                                        class="col-lg-3 col-md-12 col-sm-12 p-1"
                                    >
                                        <div class="form-group">
                                            <input
                                                [formControlName]="'firstName'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.firstName.errors
                                                }"
                                                class="form-control"
                                                placeholder="Name"
                                            />
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.firstName.errors?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="'gender'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.gender.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Gender
                                                </option>
                                                <option value="MALE">
                                                    Male
                                                </option>
                                                <option value="FEMALE">
                                                    Female
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.gender.errors?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Gender is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <input
                                                [formControlName]="
                                                    'dateOfBirth'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.dateOfBirth.errors
                                                }"
                                                type="date"
                                                class="form-control"
                                                placeholder="Date of Birth"
                                            />
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.dateOfBirth.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Date Of Birth is required
                                            </div>
                                            <div
                                                style="font-size: 12px"
                                                class="text-danger"
                                            >
                                                Choose Year First, Month Second,
                                                Date Third
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                (change)="onReligionChange()"
                                                [formControlName]="
                                                    'motherTongue'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.motherTongue.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Mother Tongue
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of religionsList
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.motherTongue.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Mother Tongue is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                (change)="onCasteChange()"
                                                [formControlName]="'caste'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.caste.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Caste
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of castesForSelectedReligion
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.caste.errors?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Caste is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="'subCaste'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.subCaste.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Sub Caste
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of subCastesForSelectedCaste
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.subCaste.errors?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Sub Caste is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="'education'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.education.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Education
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of qualificationsList
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.education.errors?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Education is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="'profession'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.profession.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Profession
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of professionList
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.profession.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Profession is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                (change)="onCountryChange()"
                                                [formControlName]="
                                                    'currentCountry'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.currentCountry.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Current Country
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of countriesList
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.currentCountry.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Current Country is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                (change)="onStateChange()"
                                                [formControlName]="
                                                    'currentState'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.currentState.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Current State
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of statesForSelectedCountry
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.currentState.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Current State is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="
                                                    'currentDistrict'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.currentDistrict.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Current District
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of citiesForSelectedState
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.currentDistrict.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Current District is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <input
                                                [formControlName]="
                                                    'monthlyIncome'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.monthlyIncome.errors
                                                }"
                                                type="number"
                                                class="form-control"
                                                placeholder="Monthly Income"
                                            />
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.monthlyIncome.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Monthly Income is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 p-1">
                                        <div class="form-group">
                                            <select
                                                class="form-control"
                                                [formControlName]="
                                                    'economicStatus'
                                                "
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.economicStatus.errors
                                                }"
                                            >
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                >
                                                    Economic Status
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of economicStatusList
                                                    "
                                                    value="{{ item.id }}"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.economicStatus.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Economic Status is required
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-3 col-md-12 col-sm-12 p-1"
                                    >
                                        <div class="form-group">
                                            <input
                                                [formControlName]="'mobile'"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        submitted &&
                                                        f.mobile.errors
                                                }"
                                                type="number"
                                                class="form-control"
                                                placeholder="Mobile Number"
                                            />
                                            <div
                                                *ngIf="
                                                    submitted &&
                                                    f.economicStatus.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Economic Status is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    class="btn btn-sm sign-up-btn mt-3"
                                >
                                    Register
                                    <i class="bx bx-right-arrow-alt"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="banner-img">
                    <img src="assets/img/home-two/couple_with_white_crop.jpg" alt="Shape">
                    <img src="assets/img/home-two/hand_small.jpg" alt="Shape">
                    <img src="assets/img/home-two/hand_flower_img.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner4.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner5.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner6.png" alt="Shape">
                    <img src="assets/img/home-two/matrimonial_hand.png" alt="Shape">
                    <img src="assets/img/home-one/banner/banner8.png" alt="Shape">
                    <img src="assets/img/home-two/thali.png" alt="Shape">
                    <div class="inner">
                        <img src="assets/img/home-one/banner/banner2.png" alt="Shape">
                        <p>Outsourcing all or part of your personnel management function can bring real</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Banner -->

<!-- Work -->
<section class="categories-area pb-70" style="margin-top: -3rem">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title text-center">
                    <span class="sub-title">Sree Krishna</span>
                    <h2>This Site is Exclusive For</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let subCaste of subCasteList">
                <div class="col-sm-6 col-lg-4" routerLink="/login">
                    <div class="cat-item">
                        <img
                            src="assets/img/home-two/banners/krishna.png"
                            alt="Cat"
                        />
                        <h3>
                            <a routerLink="/login">{{ subCaste.name }}</a>
                        </h3>
                        <span>Get Verified Profiles</span>
                        <a class="link" routerLink="/login">
                            <i class="flaticon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Looking for a life partner?</span>
            <h2>Download the TnYadavs app</h2>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-12">
                        <div class="work-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <img
                                        src="../../assets/img/home-one/app1.png"
                                    />
                                </div>
                                <div class="col-md-6 pt-3">
                                    <h5 class="text-center text-danger">
                                        Coming Soon
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-12">
                        <div class="work-item">
                            <div class="row">
                                <div class="col-md-6">
                                    <img
                                        src="../../assets/img/home-one/app2.png"
                                    />
                                </div>
                                <div class="col-md-6 pt-3">
                                    <h5 class="text-center text-danger">
                                        Coming Soon
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Explore -->
<section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Begin a blissful journey!</span>
                <h2>Happiness is Just One Step Away!</h2>
            </div>
            <p>
                Search your perfect life-partner from millions of trusted &
                authentic profiles.
            </p>
            <ul>
                <li>
                    <a class="left-btn" routerLink="/search"
                        >Find Your Match Now <i class="flaticon-right-arrow"></i
                    ></a>
                </li>
            </ul>
        </div>
    </div>
</section>
<!-- End Explore -->

<!-- New Profiles -->
<app-home-new-profiles></app-home-new-profiles>
<!-- End New Profiles -->

<!-- Search Profiles -->
<app-home-search></app-home-search>
<!-- End Search Profiles -->

<!-- Happy Stories -->
<!-- <app-home-happy-stories></app-home-happy-stories> -->
<!-- End Happy Stories -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
