<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Pricing Plans</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Pricing Plans</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Pricing -->
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row" *ngIf="packages.length">
            <a href="/order" class="col-sm-6 col-lg-4" *ngFor="let package of packages">
                <div class="pricing-item">
                    <div class="top">
                        <h3>{{ package.name }}</h3>
                        <span>
                            {{ package.gender }} <br />
                            {{ package.communityValue.name }}
                        </span>
                    </div>
                    <div class="middle">
                        <h4>
                            <span class="span-left">₹</span>
                            {{ package.price }}/
                            <span class="span-right">
                                {{ package.validity || "" }}
                                {{
                                    package.validity === 0
                                        ? "Unlimited"
                                        : "Days"
                                }}
                            </span>
                        </h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>Unlimited views</li>
                            <li *ngIf="package.expressInterest; else interest">
                                {{
                                    package.expressInterest === -1
                                        ? "Unlimited "
                                        : package.expressInterest
                                }}
                                Send Express Interest
                            </li>
                            <ng-template #interest>
                                <li>
                                    <del>Send Express Interest</del>
                                </li>
                            </ng-template>
                            {{package.photoGallery}}
                            <li *ngIf="package.photoGallery; else photoGallery">
                                {{
                                    package.photoGallery === -1
                                        ? "Unlimited "
                                        : package.photoGallery
                                }}
                                Profile Gallary views
                            </li>
                            <ng-template #photoGallery>
                                <li>
                                    <del>Profile Gallery Views</del>
                                </li>
                            </ng-template>
                            <li *ngIf="package.contact; else contact">
                                {{ package.contact }} Contact views
                            </li>
                            <ng-template #contact>
                                <li>
                                    <del>Contact Views</del>
                                </li>
                            </ng-template>
                        </ul>
                        <!-- <a class="cmn-btn" routerLink="/">
                            Pay <i class="bx bx-plus"></i>
                        </a> -->
                    </div>
                </div>
            </a>
        </div>
        <div class="row" *ngIf="!packages.length">
            <div class="col-12">
                <h1 class="text-center">No Pricing Package Found</h1>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
