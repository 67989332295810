import { User } from './../../_models/user';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from 'src/app/_services/local-storage/storage.service';

@Component({
    selector: 'app-profile-contact',
    templateUrl: './profile-contact.component.html',
    styleUrls: ['./profile-contact.component.scss'],
})
export class ProfileContactComponent implements OnInit {
    @Input() user;
    isAuthUserHasPayment: Boolean = false;
    authUserId: number;
    constructor(
        public profileService: ProfileService,
        private storageService: StorageService
    ) {
        this.authUserId = this.storageService.getUser().id;
        this.profileService
            .getHasPayment(this.authUserId)
            .subscribe((data) => {
                this.isAuthUserHasPayment = data;
            });
    }

    ngOnInit(): void {}
}
