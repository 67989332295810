<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="left two">
                            <img src="assets/img/candidate-details1.jpg" alt="Details">
                            <h2>Arun XXXX</h2>
                            <ul>
                                <li><i class='bx bx-id-card'></i> Member Id: <span style="color: #fe4a55;">YD5625467</span></li>
                                <li><i class='bx bx-heart-square'></i> UnMarried</li>
                                <li><i class='bx bx-map'></i> Chennai</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <h5>Like this Profile ?</h5>
                            <i class='bx bx-heart'></i>
                            <p>Express Interest</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="download widget-item">
                        <a routerLink="/">Shortlist</a>
                        <a class="right" routerLink="/contact">Ignore</a>
                        <a class="right mt-2" routerLink="/contact">Report</a>
                    </div>

                    <div class="similar-candidates">
                        <h4>Similar Profiles</h4>
                    </div>

                    <div class="candidate-item">
                        <div class="left">
                            <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                            <span>Web Developer</span>
                            <p>I'm IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                            <ul>
                                <li>Php</li>
                                <li>jQuery</li>
                                <li>WordPress</li>
                                <li>CSS3</li>
                                <li>HTML5</li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="candidate-item">
                        <div class="left">
                            <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                            <span>Senior UX/UI Designer</span>
                            <p>I'm Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                            <ul>
                                <li>CSS</li>
                                <li>Creative</li>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                                <li>HTML5</li>
                            </ul>
                            <div class="cmn-link">
                                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="profile">
                        <ul class="nav nav-tabs justify-content-around mb-3 pb-2" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#home" role="tab">
                                <i class="now-ui-icons objects_umbrella-13"></i> Detailed Profile
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" data-bs-target="#profile" role="tab">
                                <i class="now-ui-icons shopping_cart-simple"></i> Partner Preference
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" data-bs-target="#messages" role="tab">
                                <i class="now-ui-icons shopping_shop"></i> Gallery
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-bs-toggle="tab" data-bs-target="#settings" role="tab">
                                <i class="now-ui-icons ui-2_settings-90"></i> Contact
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content text-left">
                            <div class="tab-pane active" id="home" role="tabpanel">
                                <div class="container mt-10">
                                    <div class="col-md-12">
                                            <div class="col-md-12">
                                                <div class="panel-group" id="accordion8" role="tablist" aria-bs-multiselectable="true">
                                                    <div class="panel panel-default">
                                                        <div class="panel-heading" role="tab" id="headingOne8">
                                                            <h4 class="panel-title">
                                                                <a role="button" data-bs-toggle="collapse" data-bs-parent="#accordion8" data-bs-target="#collapseOne8" aria-bs-expanded="true" aria-bs-controls="collapseOne8">
                                                                    Basic Information
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseOne8" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne8">
                                                            <div class="panel-body">
                                                                <div class="border p-3">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-sm-6">
                                                                            <table class="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">First Name</td>
                                                                                        <td class="py-1">Manikandan</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">Gender</td>
                                                                                        <td class="py-1">
                                                                                                                                                            Male
                                                                                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">age</td>
                                                                                        <td class="py-1">
                                                                                            0
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th class="py-1 fw-600">Religion</th>
                                                                                        <td class="py-1">

                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">First Language
                                                                                        </td>
                                                                                        <td class="py-1">
                                                                                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">No. of  Children
                                                                                        </td>
                                                                                        <td class="py-1"></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div class="col-sm-6 border-sm-left ">
                                                                            <table class="w-100 ml-sm-4">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">Last Name</td>
                                                                                        <td class="py-1">E</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">Height</td>
                                                                                        <td class="py-1">

                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">Date Of Birth
                                                                                        </td>
                                                                                        <td class="py-1">
                                                                                            13/04/2022
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th class="py-1 fw-600">Caste</th>
                                                                                        <td class="py-1">

                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-1 fw-600">Marital Status
                                                                                        </td>
                                                                                        <td class="py-1">
                                                                                            Unmarried
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel panel-default">
                                                        <div class="panel-heading" role="tab" id="headingTwo8">
                                                            <h4 class="panel-title">
                                                                <a class="collapsed" role="button" data-bs-toggle="collapse" data-bs-parent="#accordion8" data-bs-target="#collapseTwo8" aria-bs-xpanded="false" aria-bs-controls="collapseTwo8">
                                                                    Present Address
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseTwo8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                            <div class="panel-body">
                                                                <div class="border p-3">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="py-1 fw-600">
                                                                                    <i class='bx bx-flag text-danger px-2'></i>
                                                                                    <span>Country</span>
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    India
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-1 fw-600">
                                                                                    <i class='bx bx-map text-danger px-2'></i>
                                                                                    <span>State</span>
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    Tamil Nadu
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-1 fw-600">
                                                                                    <i class='bx bx-building text-danger px-2'></i>
                                                                                    <span>City</span>
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    Villupuram
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-1 fw-600">
                                                                                    <i class='bx bx-envelope text-danger px-2'></i>
                                                                                    <span>Postal Code</span>
                                                                                </td>
                                                                                <td class="py-1">
                                                                                    606102
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel panel-default">
                                                        <div class="panel-heading" role="tab" id="headingThree8">
                                                            <h4 class="panel-title">
                                                                <a class="collapsed" role="button" data-bs-toggle="collapse" data-bs-parent="#accordion8" data-bs-target="#collapseThree8" aria-bs-expanded="false" aria-bs-controls="collapseThree8">
                                                                    Life Style
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseThree8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree8">
                                                            <div class="panel-body">
                                                                <div class="border p-3">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-6">
                                                                            <table class="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th class="py-1">Diet</th>
                                                                                        <td class="py-1">yes</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th class="py-1">Smoke</th>
                                                                                        <td class="py-1">yes</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div class="col-sm-6 border-sm-left ">
                                                                            <table class="w-100 ml-sm-4">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th class="py-1">Drink</th>
                                                                                        <td class="py-1">yes</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th class="py-1">Living With</th>
                                                                                        <td class="py-1">Friend</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="profile" role="tabpanel">
                                <h3>Partner Preference</h3>
                                <div class="" style="width: 80%; font-size: 13px;">
                                    <table class="table table-responsive">
                                        <tbody><tr>
                                            <th>General</th>
                                            <td>Govt Employee
                                            </td>

                                            <th>Residence Country</th>
                                            <td>
                                                India                                      </td>
                                        </tr>
                                        <tr>
                                            <th>Height</th>
                                            <td>6.9
                                            </td>

                                            <th>Weight</th>
                                            <td>67
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Marital Status</th>
                                            <td>Unmarried
                                            </td>

                                            <th>Children Acceptable</th>
                                            <td>dose_not_matter
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Religion</th>
                                            <td>Hindu
                                            </td>

                                            <th>Caste</th>
                                            <td>Yadava
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Sub Caste</th>
                                            <td>Yadav
                                            </td>

                                            <th>Language</th>
                                            <td>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Education</th>
                                            <td>B.Tech
                                            </td>

                                            <th>Profession</th>
                                            <td>IT
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Smoking Acceptable</th>
                                            <td>dose_not_matter
                                            </td>

                                            <th>Drinking Acceptable</th>
                                            <td>dose_not_matter
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Diet</th>
                                            <td>dose_not_matter
                                            </td>

                                            <th>Body Type</th>
                                            <td>Normal
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Personal Value</th>
                                            <td>Mercy
                                            </td>

                                            <th>Manglik</th>
                                            <td>dose_not_matter
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Preferred Country</th>
                                            <td>
                                                India                                      </td>

                                            <th>preferred_state_id</th>
                                            <td>
                                                Tamil Nadu                                      </td>
                                        </tr>
                                        <tr>
                                            <th>Family Value</th>
                                            <td>Good Familiy
                                            </td>

                                            <th>Complexion</th>
                                            <td>Fair
                                            </td>
                                        </tr>
                                    </tbody></table>
                              </div>
                            </div>
                            <div class="tab-pane" id="messages" role="tabpanel">
                                <div class="details-inner">
                                    <h3>Image Gallery</h3>

                                    <div class="job-details-slider owl-theme owl-carousel">
                                        <div class="slider-item">
                                            <img src="assets/img/job-details1.jpg" alt="Details">
                                        </div>

                                        <div class="slider-item">
                                            <img src="assets/img/job-details2.jpg" alt="Details">
                                        </div>

                                        <div class="slider-item">
                                            <img src="assets/img/job-details3.jpg" alt="Details">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings" role="tabpanel">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="border p-3 contact-box">
                                                                                          <div class="d-flex mb-3">
                                                <i class='bx bx-phone'></i>
                                                <div>
                                                    <div class="fs-15 fw-bold mb-1">Contact Number
                                                    </div>
                                                                                                            <div class="fw-400">999999999</div>
                                                                                                        </div>
                                            </div>
                                                                                                                                            <div class="d-flex">
                                                <i class='bx bx-envelope'></i>
                                                <div>
                                                    <div class="fs-15 fw-bold mb-1">Email ID</div>
                                                                                                            <div class="fw-400">manikandan.it12@gmail.com</div>
                                                                                                        </div>
                                            </div>

                                                                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
