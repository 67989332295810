import { Subject } from 'rxjs';
import {
    Education,
    Career,
    User,
    BasicInfo,
    IAddress,
} from './../../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPasswordRequest } from 'src/app/_models/password';
import { IMaritalStatus } from 'src/app/_models/marital-status';
import { IOnBehalf } from 'src/app/_models/on-behalf';
import {
    IProfile,
    IProfession,
    IResponse,
    IGallery,
    IPackage,
} from 'src/app/_models';
import { IQualification } from 'src/app/_models/qualification';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    apiBase: string = environment.apiUrl;
    apiUrl: string = environment.apiUrl + 'api/v1/';
    api2Url: string = environment.apiUrl + 'api/v2/';
    apiAuthUrl: string = environment.apiUrl + 'api/v1/';
    currentActiveUserSubject: Subject<any> = new Subject<any>();
    currentActiveUser;
    profileImage: string = 'assets/img/default-image.jpg';

    constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {
        if (this.currentActiveUser) {
            this.loadProfileImage();
        }
    }

    loadProfileImage() {
        this.getProfileImageByUserId(this.currentActiveUser.id).subscribe(
            (res: any) => {
                if (res && res.content) {
                    let src = `data:${res.fileType};base64, ${res.content}`;
                    this.profileImage =
                        this.domSanitizer.bypassSecurityTrustUrl(src) as string;
                } else {
                    this.profileImage =
                        this.currentActiveUser.gender == 'MALE'
                            ? 'assets/img/home-two/banners/male.svg'
                            : 'assets/img/home-two/banners/female.svg';
                }
            }
        );
    }

    getProfileDetailsByUserId(userId) {
        return this.http.get<any>(
            this.api2Url + `getProfileDetailsByUserId/${userId}`
        );
    }

    getHasPayment(userId: number) {
        return this.http.get<any>(this.apiUrl + `orders/has-payment/${userId}`);
    }

    getProfileByUserId(userId) {
        return this.http.get<any>(
            this.apiUrl + `profiles/getByUserId/${userId}`
        );
    }

    postProfile(payload) {
        return this.http.post<any>(this.apiUrl + 'profiles', payload);
    }

    getCommunityValues() {
        return this.http.get<any>(this.apiUrl + 'community-values');
    }

    getPaymentHistory(userId: number) {
        return this.http.get<any>(
            this.apiUrl + `orders/getPaymentHistory/${userId}`
        );
    }

    getPackageByUserId(userId: number) {
        return this.http.get<any>(
            this.apiUrl + `packages/getByUserId/${userId}`
        );
    }

    getMaritalStatuses() {
        return this.http.get<IMaritalStatus[]>(this.apiUrl + 'marital-status');
    }

    getOnBehalf() {
        return this.http.get<IOnBehalf[]>(this.apiUrl + 'onbehalfs');
    }

    getPackages() {
        return this.http.get<IPackage[]>(`${this.apiUrl}packages`);
    }

    getQualifications() {
        return this.http.get<IQualification[]>(this.apiUrl + 'qualifications');
    }

    getProfessions() {
        return this.http.get<IProfession[]>(this.apiUrl + 'professions');
    }

    getGalleryByUserId(userId) {
        return this.http.get<IGallery[]>(
            this.apiUrl + 'galleries/list/' + userId
        );
    }

    postGalleryByUserId(userId, file, galleryType) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('galleryType', galleryType);
        return this.http.post<any>(this.apiUrl + 'galleries', formData);
    }

    deleteGalleryById(galleryId: number) {
        return this.http.delete(this.apiUrl + 'galleries/' + galleryId);
    }

    getFileByPathName(userId, fileName) {
        return this.http.get<String>(
            this.apiUrl + `galleries/file/${userId}/${fileName}`
        );
    }

    getProfileImageByUserId(userId) {
        return this.http.get(this.apiUrl + 'galleries/profile-image/' + userId);
    }

    getEducationsByUserId(userId) {
        return this.http.get<IResponse<Education[]>>(
            this.apiAuthUrl + 'educations/' + userId
        );
    }

    getRecentlyViewed(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `recently-viewed-by-me?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    getWhoViewed(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `who-viewed-me?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        ).pipe(res => {
            console.log(res)
            return res;
        });
    }

    getMyBooks(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `my-book?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    getInterestSent(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `interest-sent?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    getInterestReceived(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `interest-received?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    postSetViewed(toId: number) {
        return this.http.post(this.api2Url + `set-viewed/${toId}`, {});
    }

    postStateUpdate(fromId: number, toId: number, state: string) {
        const payload = { fromId, toId, state };
        return this.http.post(this.apiUrl + 'interests/update-state', payload);
    }

    postSendInterest(fromId: number, toId: number) {
        const payload = { fromId, toId };
        return this.http.post(this.apiUrl + 'interests/send', payload);
    }

    postSendNoteOrReport({ fromId, toId, message, type }) {
        const payload = { fromId, toId, report: '', note: '' };
        if (type === 'REPORT') payload.report = message;
        if (type === 'NOTE') payload.note = message;
        return this.http.post(
            this.apiUrl + 'interests/send-report-or-note/' + type,
            payload
        );
    }

    getInterest({ fromId, toId }) {
        return this.http.get(
            this.apiUrl + `interests/get-interest/${fromId}/${toId}`
        );
    }

    getInterestRejected(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `rejected-profiles?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    getInterestReported(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `reported-profiles?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    getInterestBlocked(pagination) {
        return this.http.get<IProfile[]>(
            this.api2Url + `blocked-profiles?limit=${pagination.itemsPerPage}&page=${pagination.currentPage}`
        );
    }

    postSearch(formData, itemsPerPage, currentPage) {
        return this.http.post<User[]>(
            `${this.api2Url}search?limit=${itemsPerPage}&page=${currentPage}`,
            formData
        );
    }

    getAllUsers() {
        return this.http.get<User[]>(this.apiUrl + 'users/active-members/');
    }

    getAllProfiles(itemsPerPage, currentPage) {
        return this.http.get<any[]>(`${this.api2Url}profiles?limit=${itemsPerPage}&page=${currentPage}`);
    }

    getActiveUserById(userId) {
        return this.http.get<User>(
            this.apiUrl + 'users/active-members/' + userId
        );
    }

    getEducationById(id: any) {
        return this.http.get<Education>(this.apiAuthUrl + 'educations/' + id);
    }

    addEducation(requestData) {
        return this.http.post<IResponse<Education[]>>(
            this.apiAuthUrl + 'educations',
            requestData
        );
    }
    updateEducation(editId, requestData) {
        return this.http.put<IResponse<Education[]>>(
            this.apiAuthUrl + 'educations/' + editId,
            requestData
        );
    }

    deleteEducation({ userId, educationId }) {
        return this.http.delete<IResponse<Education[]>>(
            this.apiAuthUrl + `educations/${userId}/${educationId}`
        );
    }

    getCareersByUserId(userId) {
        return this.http.get<IResponse<Career[]>>(
            this.apiAuthUrl + 'careers/' + userId
        );
    }

    getUserById(userId) {
        return this.http.get<any>(this.apiUrl + 'users/getUserById/' + userId);
    }

    getCareerById(id: any) {
        return this.http.get<Career>(this.apiAuthUrl + 'careers/' + id);
    }

    addCareer(requestData) {
        return this.http.post<IResponse<Career[]>>(
            this.apiAuthUrl + 'careers',
            requestData
        );
    }

    updateCareer(editId, requestData) {
        return this.http.put<IResponse<Career[]>>(
            this.apiAuthUrl + 'careers/' + editId,
            requestData
        );
    }

    deleteCareer({ userId, careerId }) {
        return this.http.delete<IResponse<Career[]>>(
            this.apiAuthUrl + `careers/${userId}/${careerId}`
        );
    }

    updateBasicInfo(requestData: BasicInfo) {
        return this.http.post<BasicInfo>(
            this.apiUrl + 'users/update-basic-info',
            requestData,
            { responseType: 'json' as 'json' }
        );
    }

    updateIntro(userId, about: string) {
        return this.http.post(
            this.apiUrl + 'members/update-inroduction/' + userId,
            about
        );
    }

    updateAddress(requestData: IAddress, isExist: Boolean) {
        if (isExist)
            return this.http.put<IResponse<IAddress>>(
                this.apiAuthUrl + `addresses/${requestData.userId}`,
                requestData
            );
        else
            return this.http.post<IResponse<IAddress>>(
                this.apiAuthUrl + 'addresses',
                requestData
            );
    }

    getAddressesByUserId(userId) {
        return this.http.get<IResponse<IAddress>>(
            this.apiAuthUrl + `addresses/${userId}`
        );
    }

    addMemberLanguages(requestData) {
        return this.http.post(this.apiUrl + 'member-languages', requestData);
    }

    getMemberByUserId(userId) {
        return this.http.get<any>(
            this.apiUrl + 'members/getMemberByUserId/' + userId
        );
    }

    updateMemberLanguages(payload, userId) {
        return this.http.post<any>(
            this.apiUrl + `member-languages/${userId}`,
            payload
        );
    }

    getMemberLanguageByUserId(userId) {
        return this.http.get<any>(this.apiUrl + 'member-languages/' + userId);
    }

    addSpritualInfo(requestData) {
        return this.http.post(this.apiUrl + 'spiritual', requestData);
    }

    updateSpritualInfo(requestData, userId) {
        return this.http.put(this.apiUrl + 'spiritual/' + userId, requestData);
    }

    getSpritualInfoByUserId(userId) {
        return this.http.get<any>(this.apiUrl + 'spiritual/' + userId);
    }

    addFamilyInfo(requestData) {
        return this.http.post(this.apiUrl + 'family-info', requestData);
    }

    updateFamilyInfo(requestData, userId) {
        return this.http.post(
            this.apiUrl + 'family-info/' + userId,
            requestData
        );
    }

    getFamilyInfoByUserId(userId) {
        return this.http.get(this.apiUrl + 'family-info/getByUserId/' + userId);
    }

    addAstrologyInfo(payload) {
        return this.http.post(this.apiUrl + 'astrologies', payload);
    }

    updateAstrologyInfo(payload, userId) {
        return this.http.put(this.apiUrl + 'astrologies/' + userId, payload);
    }

    getAstrologyInfo(userId) {
        return this.http.get(this.apiUrl + 'astrologies/' + userId);
    }

    addPhysical(requestData) {
        return this.http.post(this.apiUrl + 'physical', requestData);
    }

    updatePhysical(requestData, userId) {
        return this.http.put(this.apiUrl + 'physical/' + userId, requestData);
    }

    getPhysicalByUserId(userId) {
        return this.http.get(this.apiUrl + 'physical/get-physical/' + userId);
    }

    addExpectations(requestData) {
        return this.http.post(this.apiUrl + 'expectations', requestData);
    }

    updateExpectations(requestData, userId) {
        return this.http.put(
            this.apiUrl + 'expectations/' + userId,
            requestData
        );
    }

    getExpectationsByUserId(userId) {
        return this.http.get<any>(
            this.apiUrl + 'expectations/getExpectationByUserId/' + userId
        );
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const extension = mime.split('/')[1];
        filename = filename + `.${extension}`;
        return new File([u8arr], filename, { type: mime });
    }

    uploadProfileImage(requestData) {
        const { userId, image } = requestData;
        const file = this.dataURLtoFile(image, 'profile_image');
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(
            this.apiUrl + `files/setProfileImage/${userId}`,
            formData
        );
    }

    changePassword(requestData: IPasswordRequest) {
        return this.http.post(
            this.apiUrl + `users/changePassword`,
            requestData
        );
    }
}
