<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>EditProfile</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>EditProfile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- EditProfile -->
<div class="dashboard-area mt-5">
    <div
        class="container-fluid mt-4 p-4"
        style="background-color: #fff; border-radius: 10px"
    >
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="profile-img">
                    <img [src]="profileService.profileImage" />
                    <button
                        routerLink="/edit-profile/manage-profile"
                        type="submit"
                        class="btn btn-link btn-sm mt-3"
                        style="color:rgb(206, 8, 8);font-size: 16px;font-weight: 900;"
                    >
                        Edit Profile
                    </button>
                </div>

                <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    aria-orientation="vertical"
                >
                    <a
                        class="nav-link"
                        id="my-book-tab"
                        [routerLink]="['my-book']"
                        [routerLinkActive]="['active']"
                    >
                        <i class="bx bx-book-heart"></i> My Book
                    </a>
                    <a
                        class="nav-link"
                        id="interest-sent-tab"
                        [routerLink]="['interest-sent']"
                        [routerLinkActive]="['active']"
                    >
                        <i class="bx bx-export"></i> Interest Sent
                    </a>
                    <a
                        class="nav-link"
                        id="interest-received-tab"
                        [routerLink]="['interest-received']"
                        [routerLinkActive]="['active']"
                    >
                        <i class="bx bx-import"></i> Interest Received
                    </a>
                    <a
                        class="nav-link"
                        id="rejected-profile-tab"
                        [routerLink]="['rejected-profile']"
                        [routerLinkActive]="['active']"
                    >
                        <i class="bx bx-meteor"></i> Rejected Profile
                    </a>
                    <!-- <a
                        class="nav-link"
                        id="my-matches-tab"
                        [routerLink]="['my-matches']"
                        [routerLinkActive]="['active']"
                    >
                        <i class="bx bx-user"></i> My Book
                    </a> -->
                    <!-- <a
                        class="nav-link"
                        [routerLink]="['my-interest']"
                        [routerLinkActive]="['active']"
                    >
                        <div class="profile-list">
                            <i class="bx bxs-heart"></i>
                            My Interest
                        </div>
                    </a> -->
                    <!-- <a
                        class="nav-link"
                        [routerLink]="['short-listed']"
                        [routerLinkActive]="['active']"
                    >
                        <div class="profile-list">
                            <i class="bx bxs-heart"></i>
                            ShortListed Profiles
                        </div>
                    </a> -->
                    <a
                        class="nav-link"
                        [routerLink]="['ignored-list']"
                        [routerLinkActive]="['active']"
                    >
                        <div class="profile-list">
                            <i class="bx bxs-user"></i>
                            Reported Profiles
                        </div>
                    </a>
                    <a
                        class="nav-link"
                        [routerLink]="['blocked-list']"
                        [routerLinkActive]="['active']"
                    >
                        <div class="profile-list">
                            <i class="bx bxs-user"></i>
                            Blocked Profiles
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <div
                        class="tab-pane fade show active"
                        id="my-matches"
                        role="tabpanel"
                        aria-labelledby="my-matches-tab"
                    >
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End EditProfile -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
