import { HostListener, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { AlertService } from 'src/app/_alert';
import { StorageService } from 'src/app/_services/local-storage/storage.service';
import { OrderService } from 'src/app/_services/order.service';
import { ProfileService } from 'src/app/_services/profile/profile.service';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;
declare var document: any;

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css'],
})
export class OpenOrderComponent {
    form: any = {};
    paymentId: string;
    error: string;
    user: any;
    currentDate = new Date();
    package: any;
    payments = [];
    constructor(
        private orderService: OrderService,
        private profileService: ProfileService,
        private storageService: StorageService,
        private router: Router,
        private alert: AlertService,
        private aRoute: ActivatedRoute,
    ) {
        this.aRoute.queryParams.subscribe(params => {
            if (params['token']) {
                const token = params['token'];
                const payload = {};
                const header = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                }
                axios.post(environment.apiUrl + 'api/v1/users/validate-token', payload, header).then((res) => {
                    const { data } = res;
                    this.storageService.saveToken(token);
                    this.storageService.saveUser(data);
                    this.storageService.saveAuthResponse(data);
                    this.user = data;
                    this.loadPackage();
                });
            } else {
                this.user = this.storageService.getUser();
                this.profileService.currentActiveUserSubject.subscribe((user) => {
                    this.user = user;
                    this.loadPackage();
                });
                this.loadPackage();
            }
        });
    }

    loadPackage() {
        if (this.user) {
            this.profileService
                .getPackageByUserId(this.user.id)
                .subscribe((data) => (this.package = data));
            this.profileService
                .getPaymentHistory(this.user.id)
                .subscribe((data) => (this.payments = data));
        }
    }

    options = {
        key: '',
        amount: '',
        name: 'TnYadavs',
        description: 'Matrimonial Service',
        image: 'https://www.javachinna.com/wp-content/uploads/2020/02/android-chrome-512x512-1.png',
        order_id: '',
        handler: function (response) {
            var event = new CustomEvent('payment.success', {
                detail: response,
                bubbles: true,
                cancelable: true,
            });
            window.dispatchEvent(event);
        },
        prefill: {
            name: '',
            email: '',
            contact: '',
        },
        notes: {
            address: '',
        },
        theme: {
            color: '#3399cc',
        },
    };

    onSubmitToCCAvenue() {
        if (!this.package) {
            this.alert.error('Complete your profile detials');
            setTimeout(() => {
                this.router.navigate(['edit-profile/manage-profile']);
            }, 3000);
            return false;
        }
        const amount =
            this.package.price + this.percentage(18, this.package.price);
        const name = this.user.firstName + this.user.lastName;
        const email = this.user.email;
        const mobile = this.user.mobile;
        const country = 'INDIA';
        const state = 'TAMILNADU';
        const order_id = Math.round(new Date().getTime() / 1000) + this.user.id;
        const baseUrl = environment.apiUrl;
        const responseHandler = baseUrl + 'api/v1/ccavenue/responseHandler';
        const payload = {
            merchant_id: '1769853',
            order_id: order_id,
            currency: 'INR',
            amount: amount,
            redirect_url: responseHandler,
            cancel_url: responseHandler,
            language: '',
            billing_name: name,
            billing_address: '',
            billing_city: '',
            billing_state: state,
            billing_zip: '',
            billing_country: country,
            billing_tel: mobile,
            billing_email: email,
            delivery_name: name,
            delivery_address: '',
            delivery_city: '',
            delivery_state: state,
            delivery_zip: '',
            delivery_country: country,
            delivery_tel: mobile,
            merchant_param1: this.user.id,
            merchant_param2: this.package.id,
            merchant_param3: '',
            merchant_param4: '',
            merchant_param5: '',
            promo_code: '',
            customer_identifier: '',
        };
        this.orderService.encryptPayload(payload).subscribe((data: any) => {
            const { encRequest, accessCode, redirectUrl } = data;
            document.getElementById('dynamic-form').innerHTML = `
            <form id="nonseamless" method="post" name="redirect" action="${redirectUrl}"> 
              <input type="hidden" id="encRequest" name="encRequest" value="${encRequest}">
              <input type="hidden" name="access_code" id="access_code" value="${accessCode}">
            </form>`;
            document.redirect.submit();
        });
    }

    onSubmitToRazorPay(): void {
        if (!this.package) {
            this.alert.info('Complete your profile detials');
            this.router.navigate(['edit-profile/manage-profile']);
            return;
        }
        this.paymentId = '';
        this.error = '';
        this.form.amount =
            this.package.price + this.percentage(18, this.package.price);
        this.form.name = this.user.firstName + this.user.lastName;
        this.form.email = this.user.email;
        this.form.mobile = this.user.mobile;
        this.orderService.createOrder(this.form).subscribe(
            (data) => {
                this.options.key = data.secretKey;
                this.options.order_id = data.razorpayOrderId;
                this.options.amount = data.applicationFee; //paise
                this.options.prefill.name = this.form.name;
                this.options.prefill.email = this.form.email;
                this.options.prefill.contact = this.form.mobile;
                var rzp1 = new Razorpay(this.options);
                rzp1.open();
                rzp1.on('payment.failed', function (response) {
                    // Todo - store this information in the server
                    // console.log(response.error.code);
                    // console.log(response.error.description);
                    // console.log(response.error.source);
                    // console.log(response.error.step);
                    // console.log(response.error.reason);
                    // console.log(response.error.metadata.order_id);
                    // console.log(response.error.metadata.payment_id);
                    this.error = response.error.reason;
                });
            },
            (err) => (this.error = err.error.message)
        );
    }

    @HostListener('window:payment.success', ['$event'])
    onPaymentSuccess(event): void {
        const { price, id } = this.package;
        const paidAmount = price + this.percentage(18, price);
        const payload = { id, paidAmount, ...event.detail };
        this.orderService.updateOrder(payload).subscribe(
            (data) => (this.paymentId = data.message),
            (err) => (this.error = err.error.message)
        );
    }

    percentage(partialValue, totalValue) {
        return (totalValue * partialValue) / 100;
    }

    ngOnInit() {
        // if (!this.package && !this.user) this.router.navigate(['/']);
    }
}
