<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Blog Details</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <div class="details-img">
                        <ul>
                            <li>
                                <i class='bx bx-user'></i>
                                <a href="#">Tom Black</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>November 21, 2020</span>
                            </li>
                            <li>
                                <i class='bx bx-message-rounded-detail'></i>
                                <a href="#">3 Comments</a>
                            </li>
                        </ul>
                        <img src="assets/img/blog-details1.jpg" alt="Blog Details">
                        <h2>The Internet Is A Job Seeker Most Crucial Success </h2>
                        <div class="d-content">
                            <span>D</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor indunt ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit asetur aut odit aut quia consequuntur magni </p>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor int ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur au quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inciddunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercittion ullmco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in rep sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        <blockquote>
                            <i class='bx bxs-quote-alt-left'></i>
                            <p>I see becoming a normalized marketing tactic, the same way TV, radio, and print are traditionally</p>
                            <span>HERBERT BAYARD SWOPE</span>
                        </blockquote>
                        <h3>Start With Your Contact Information</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incit ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercittion labore et dolore magnam aliquam quaerat voluptatem.</p>
                    </div>
                    <div class="details-tag">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="left">
                                    <ul>
                                        <li>
                                            <span>Tags:</span>
                                        </li>
                                        <li>
                                            <a href="#">#SEO</a>
                                        </li>
                                        <li>
                                            <a href="#">#Business</a>
                                        </li>
                                        <li>
                                            <a href="#">#Internet</a>
                                        </li>
                                        <li>
                                            <a href="#">#Property</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="right">
                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-instagram'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-pinterest-alt'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class='bx bxl-youtube'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="details-date">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="date-inner">
                                    <span>Jun 12, 2020 / SEO</span>
                                    <h4>Successful digital marketer does first to ensure they get</h4>
                                    <a href="#">
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="date-inner">
                                    <span>April 09, 2020 / Web</span>
                                    <h4>Marketer who knows how to execute their campaigns</h4>
                                    <a href="#">
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="details-comment">
                        <h3>Comment <span>(02)</span></h3>
                        <ul>
                            <li>
                                <img src="assets/img/blog-details2.png" alt="Details">
                                <h4>Devit Killer</h4>
                                <span>January 12, 2020</span>
                                <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                <a href="#">Reply</a>
                            </li>
                            <li>
                                <img src="assets/img/blog-details3.png" alt="Details">
                                <h4>Morah Jein</h4>
                                <span>January 13, 2020</span>
                                <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                <a href="#">Reply</a>
                            </li>
                        </ul>
                    </div>
                    <div class="details-form">
                        <h3>Leave A Comment</h3>
                        <form>
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck59">
                                <label class="form-check-label" for="exampleCheck59">Save my name, email, and website in this browser for the next time I comment.</label>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" class="form-control" placeholder="Name*">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <input type="email" class="form-control" placeholder="Email*">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <i class='bx bx-mail-send'></i>
                                        <textarea id="your-message" rows="10" class="form-control" placeholder="Write message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="btn">Post A Comment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="job widget-item">
                        <h3>Search Result</h3>

                        <form>
                            <label><i class="flaticon-send"></i></label>
                            <input type="text" class="form-control" placeholder="Email address">
                            <button type="submit" class="btn">Submit</button>
                        </form>
                    </div> 

                    <div class="cat widget-item">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog" target="_blank">Design</a> <span>(1)</span></li>
                            <li><a routerLink="/blog" target="_blank">Lifestyle </a><span>(4)</span></li>
                            <li><a routerLink="/blog" target="_blank">Camping</a> <span>(3)</span></li>
                            <li><a routerLink="/blog" target="_blank">Job</a> <span>(2)</span></li>
                            <li><a routerLink="/blog" target="_blank">Device</a> <span>(6)</span></li>
                            <li><a routerLink="/blog" target="_blank">Internet</a> <span>(8)</span></li>
                        </ul>
                    </div>
                    
                    <div class="recent widget-item">
                        <h3>Recent Posts</h3>

                        <div class="recent-inner">
                            <ul>
                                <li><img src="assets/img/blog-details4.jpg" alt="Details"></li>
                                <li>
                                    <span>January 12, 2020</span>
                                    <a routerLink="/blog-details">A Guide To Google SEO</a>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </li>
                            </ul>
                        </div>
                        <div class="recent-inner">
                            <ul>
                                <li><img src="assets/img/blog-details5.jpg" alt="Details"></li>
                                <li>
                                    <span>June 30, 2020</span>
                                    <a routerLink="/blog-details">15 SEO Best Jobs</a>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </li>
                            </ul>
                        </div>
                        <div class="recent-inner">
                            <ul>
                                <li><img src="assets/img/blog-details6.jpg" alt="Details"></li>
                                <li>
                                    <span>Website Architecture</span>
                                    <a routerLink="/blog-details">A Guide To Google SEO</a>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="tags widget-item">
                        <h3>News Tags</h3>

                        <ul>
                            <li><a routerLink="/blog">#SEO</a></li>
                            <li><a routerLink="/blog">#Internet</a></li>
                            <li><a routerLink="/blog">#Web</a></li>
                            <li><a routerLink="/blog">#SASS</a></li>
                            <li><a routerLink="/blog">#IT & Support</a></li>
                            <li><a routerLink="/blog">#Tips</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->