<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Terms & Conditions -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <h3>Acceptance of Terms of Use Agreement.</h3>
            <p>
                In order to use the (TnYadavs.com/IndianYadavs.com) Service you must register as a
                member on the Website/ community sites/ mobile applications
                ("Site"). (TnYadavs.com/IndianYadavs.com) is intermediary as defined under sub-clause
                (w) of Section 2 of the Information Technology Act, 2000. This
                Agreement is an electronic contract that establishes the legally
                binding terms you must accept to use the Site and to become a
                "Member." For purposes of this Agreement, the term "Member"
                means a person who voluntarily submits information to the Site
                whether such person uses the Service as a free member or a paid
                member and User shall mean a person who actively uses the Site
                to promote his/her profile for matchmaking purpose.
                <br /><br />
                By using the Site, you agree to be bound by these Terms of Use
                ("Agreement"). If you wish to become a Member and promote/
                advertise your profile to other Members and make use of the
                (TnYadavs.com/IndianYadavs.com) service ("Service"), read these Terms of Use and
                follow the instructions in the registration process. By using
                the Service, you consent to submit your personal identifiable
                information including sensitive personal data such as your
                financial data if opted for our paid membership, Identity proofs
                if submitted, religion etc, to collect, process, display and use
                the said information to provide the Service and if you have any
                objection in collecting/processing your personal data, we advise
                you not to register with our Site.
                <br /><br />
                If you want to withdraw this consent or if you have any
                objection in continuous collection or storage of your personal
                details, you must discontinue using our Service and delete your
                account by sending an email from your registered email ID to
                <a href="mailto:info@tnyadavs.com" class="link"
                    >info@tnyadavs.com</a
                >
            </p>
            <h3>Eligibility.</h3>
            <p>
                To register as a Member of (TnYadavs.com/IndianYadavs.com) or use this Site, you must
                be legally competent and of legal marriageable age as per the
                laws of India (currently, 18 years or over for females and 21
                years or over for males).
                <br /><br />
                The (TnYadavs.com/IndianYadavs.com) site is only to facilitate personal advertisement
                for lawful marriage alliance between persons who are legally
                competent to enter into matrimony under the laws to which they
                are subject. Membership in the Service is void where prohibited.
                <br /><br />
                By using this Site, you represent and warrant that you have the
                right, consent, authority, and legal capacity to enter into this
                Agreement; submit relevant information to us; and that you are
                not prohibited or prevented by any applicable law for the time
                being in force or any order or decree or injunction from any
                court, tribunal or any such competent authority restraining you
                from entering into matrimony.
                <br /><br />
                You further confirm that you, intent to seek a life partner and
                that you shall not use this site for casual dating. You also
                agree to abide by all of the terms and conditions of this
                Agreement. If at any time (TnYadavs.com/IndianYadavs.com)is of the opinion (in its
                sole discretion) or has any reason to believe that you are not
                eligible to become a member or that you have made any
                misrepresentation, (TnYadavs.com/IndianYadavs.com) reserves the right to forthwith
                terminate your membership and / or your right to use the Service
                without any refund to you of any of your unutilized subscription
                fee.
                <br /><br />
                By using this Site you acknowledge that the match mails or the
                match search results shown to you by the system are based on the
                partner preference details submitted by you in your profile.
            </p>

            <h3>Account Security.</h3>
            <p>
                You are responsible for maintaining the confidentiality of your
                login credentials you designate during the registration process,
                and you are solely responsible for all activities that occur
                under your account. You agree to immediately notify us of any
                disclosure or unauthorized use of your account or any other
                breach of security, and ensure that you log out from your
                account at the end of each session.
            </p>
            <h3>Term and Termination.</h3>
            <ul>
                <li>
                    This Agreement will be effective, valid and subsisting as
                    long as you use our Site.
                </li>
                <br />
                <li>
                    You may terminate your membership at any time, for any
                    reason by writing to (TnYadavs.com/IndianYadavs.com) In the event you terminate
                    your membership, you will not be entitled to a refund of any
                    unutilized subscription fees, if any, paid by you, except
                    where otherwise guaranteed in writing.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) may terminate your access to the Site and/or your
                    membership for any reason including but not limited to
                    breach of the terms of use, using the service for commercial
                    purpose, engaging in prohibited or inappropriate
                    communication or activity, by sending notice to you at the
                    email address as provided by you in your application for
                    membership or such other email address as you may later
                    provide to (TnYadavs.com/IndianYadavs.com). If (TnYadavs.com/IndianYadavs.com) terminates your
                    membership for breach of terms of this Agreement, you will
                    not be entitled to any refund of any Subscription fees, if
                    any, paid by you.
                </li>
                <br />
                <li>
                    Certain provisions of this Agreement including sections 4,
                    5, 6, 7 and sections 9 to 16, of this Agreement shall remain
                    effective even on termination.
                </li>
                <br />
                <li>
                    Term for the paid Membership would be in accordance with the
                    type of membership undertaken.
                </li>
                <br />
            </ul>
            <h3>Non-Commercial Use by Members.</h3>
            <p>
                The Site is for the personal use of individual members to
                advertise and promote their profiles for the purpose of
                generating accepts from relevant matches and cannot be used in
                connection with any commercial endeavors. This includes
                providing links to other web sites, whether deemed competitive
                to (TnYadavs.com/IndianYadavs.com) or otherwise. Organizations, companies, and/or
                businesses cannot become Members of (TnYadavs.com/IndianYadavs.com) and should not
                use the (TnYadavs.com/IndianYadavs.com) Service or Site for any purpose. Illegal
                and/or unauthorized uses of the Site, including unauthorized
                framing of or linking to the Site will be investigated, and
                appropriate legal action will be taken, including without
                limitation, civil, criminal, and injunctive redress.
            </p>

            <h3>Other Terms of Use for Members.</h3>
            <ol>
                <li>
                    (TnYadavs.com/IndianYadavs.com) reserves the right in it's sole discretion to
                    review the activity and status of each account and block
                    access to the member based on such review.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) reserve the right at its sole discretion to
                    restrict/suspend your free access to its website based on
                    review of the member's activity, status or any such criteria
                    (TnYadavs.com/IndianYadavs.com) may deems fit and proper with due intimation to
                    you.
                </li>
                <br />
                <li>
                    You hereby confirm that as on date of this registration, you
                    do not have any objection to receive emails, SMS/WhatsApp
                    messages and calls from (TnYadavs.com/IndianYadavs.com) and members of (TnYadavs.com/IndianYadavs.com)
                    as long as you are a registered member of (TnYadavs.com/IndianYadavs.com)
                    including SMS permission for authenticating mobile
                    verification wand transactions via OTP, sent by the Payment
                    Gateway. This consent shall supersede any preferences set by
                    you with or registration done with the Do Not Disturb (DND
                    Register)/ National Customer Preference Register (NCPR).
                    This consent extends to emails, messages or calls relating
                    but not limited to phone number verification, the provision
                    of matchmaking advertising service, matchmaking enquiries
                    and promotions.
                </li>
                <br />
                <li>
                    Multiple profiles of the same person are not allowed on our
                    Site. (TnYadavs.com/IndianYadavs.com) reserves the right to deactivate all
                    multiple profiles without any refund of subscription fees.
                </li>
                <br />
                <li>
                    You confirm that the Content, information including the
                    personal information provided by you is correct and
                    accurate. If you are creating the profile on behalf of your
                    friend/sibling/children, you hereby agree that you have the
                    required permission from the particular person whose profile
                    is created on (TnYadavs.com/IndianYadavs.com) by you. Impersonation is crime and
                    punishable under various laws.
                </li>
                <br />
                <li>
                    You are strongly advised to submit copy of your Driving
                    License, Passport or any other Government issued identity
                    and add a trust badge to your profile.
                </li>
                <br />
                <li>
                    Except for the purpose of promoting/advertising your profile
                    for matchmaking purposes, you cannot engage in advertising
                    to, or solicitation of, other Members to buy or sell any
                    products or services through the Service.
                </li>
                <br />
                <li>
                    You will not transmit any chain letters or junk email to
                    other (TnYadavs.com/IndianYadavs.com) Members. Although (TnYadavs.com/IndianYadavs.com) cannot monitor
                    the conduct of its Members off the (TnYadavs.com/IndianYadavs.com) Site, it is
                    also a violation of these rules to use any information
                    obtained from other members in order to harass, abuse, or
                    harm another person, or in order to contact, advertise to,
                    solicit, or sell to any Member without their prior explicit
                    consent.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) reserves the right to take appropriate steps to
                    protect (TnYadavs.com/IndianYadavs.com) and/or its Members from any abuse/misuse
                    as it deems appropriate in its sole discretion.
                </li>
                <br />
                <li>
                    You hereby agree that you shall not use the Services and the
                    platforms to harass any member including the Shaadi
                    meet/Near Me feature.
                </li>
                <br />
                <li>
                    You cannot use any automated processes, including IRC Bots,
                    EXE's, CGI or any other programs/scripts to view content on
                    or communicate / contact / respond / interact with
                    (TnYadavs.com/IndianYadavs.com) and/or its members.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) reserves the right to screen
                    communications/advertisements that you may send to other
                    Member(s) and also regulate the same by deleting
                    unwarranted/obscene communications/advertisements at any
                    time at its sole discretion without prior notice to any
                    Member.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) Members are expected to exercise simple
                    precautions for their privacy and safety. A suggestive
                    guideline of such precautions is enlisted under security
                    tips "<a
                        href="/customer-relations/faq/privacy-tips"
                        class="link"
                        target="_blank"
                        >Be Safe Online</a
                    >". By becoming a Member you hereby agree to read, abide and
                    adhere to the said Tips.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) Members who subscribe to the (TnYadavs.com/IndianYadavs.com) Select
                    Service hereby, unconditionally and irrevocably confirm that
                    you have read terms and conditions and agree to abide by
                    them.
                </li>
            </ol>

            <h3>Proprietary Rights in the Content on (TnYadavs.com/IndianYadavs.com)</h3>
            <p>
                (TnYadavs.com/IndianYadavs.com) owns and retains all proprietary rights in the
                (TnYadavs.com/IndianYadavs.com) Site and the (TnYadavs.com/IndianYadavs.com) Service. The Site contains
                the copyrighted material, trademarks, and other proprietary
                information of (TnYadavs.com/IndianYadavs.com), and its licensors. Except for that
                information which is in the public domain such as member profile
                or for which permission has been obtained from the user, you
                cannot copy, modify, publish, transmit, distribute, perform,
                display, or sell any such proprietary information. Any such act
                or an attempted act on your part shall constitute a violation of
                this Agreement and your membership is liable to be terminated
                forthwith by (TnYadavs.com/IndianYadavs.com) without refund of any of your unused
                Subscription fees. (TnYadavs.com/IndianYadavs.com) reserves right to take legal
                action (civil and/or criminal) wherever applicable for any
                violations.
            </p>

            <h3>Content Posted on the Site.</h3>
            <ol>
                <li>
                    You understand and agree that (TnYadavs.com/IndianYadavs.com) may delete any
                    listing, content, communication, photos or profiles
                    (collectively, "Content") that in the sole judgment of
                    (TnYadavs.com/IndianYadavs.com) violate this Agreement or which might be
                    offensive, illegal, or that might violate the rights, harm,
                    or threaten the safety of either (TnYadavs.com/IndianYadavs.com) and/or its
                    Members.
                </li>
                <br />
                <li>
                    With respect to Content you submit or make available for
                    inclusion on publicly accessible areas of the Site including
                    but not limited to your contact details, you hereby
                    unconditionally and irrevocably grant to (TnYadavs.com/IndianYadavs.com) the
                    consent and license to use, store, distribute, reproduce,
                    prepare derivative works of, modify, adapt, publicly perform
                    and publicly display such Content on the Site and to the
                    members of (TnYadavs.com/IndianYadavs.com) community site(s) from time to time.
                </li>
                <br />
                <li>
                    By submitting your success story you hereby expressly
                    authorize (TnYadavs.com/IndianYadavs.com) to use, publish, display your
                    photographs and testimony on the Site.
                </li>
                <br />
                <li>
                    You understand and hereby agree that all information, data,
                    text, photographs, graphics, communications, tags, or other
                    Content whether publicly posted or privately transmitted or
                    otherwise made available to (TnYadavs.com/IndianYadavs.com) are the sole
                    responsibility of the person from whom such Content
                    originated and shall be at the member's/person's sole risks
                    and consequences. This means that you (and not (TnYadavs.com/IndianYadavs.com))
                    are solely responsible for all Content that you upload,
                    post, email, transmit or otherwise make available via the
                    Service. (TnYadavs.com/IndianYadavs.com) does not control the Content posted via
                    the Service and, as such, does not guarantee the accuracy,
                    integrity or quality of such Content. We advise all User/s
                    to independently verify the content /information posted by
                    the other members before acting upon the same and under no
                    circumstances will (TnYadavs.com/IndianYadavs.com) be liable in any way for any
                    Content, including, but not limited to, any errors or
                    omissions in any Content, or any loss or damage of any kind
                    incurred as a result of the use of any Content posted,
                    emailed, transmitted or otherwise made available via the
                    Service.
                </li>
                <br />
                <li>
                    (TnYadavs.com/IndianYadavs.com) reserves the right to verify the authenticity of
                    Content posted on the Site. In exercising this right,
                    (TnYadavs.com/IndianYadavs.com) may ask you to provide any documentary or other
                    form of evidence supporting the Content you post on the
                    Site. If you fail to produce such evidence to the
                    satisfaction of (TnYadavs.com/IndianYadavs.com), (TnYadavs.com/IndianYadavs.com) may, in its sole
                    discretion, terminate your Membership without a refund.
                </li>
                <br />
                <li>
                    The following is an indicative list of the kind of Content
                    that is illegal or prohibited on the Site. (TnYadavs.com/IndianYadavs.com) will
                    investigate and take appropriate legal action in its sole
                    discretion against anyone who violates this Agreement,
                    including without limitation, removing the offending
                    communication/Content from the Service and terminating the
                    Membership of such violators without a refund. Illegal and
                    prohibitive Content includes Content which:
                    <ul>
                        <li>
                            is blatantly offensive to the community, such as
                            Content that promotes racism, bigotry, hatred or
                            physical harm of any kind against any group or
                            individual;
                        </li>
                        <li>
                            harasses or advocates harassment of another person;
                        </li>
                        <li>
                            involves the transmission of "junk mail", "chain
                            letters," or unsolicited mass mailing or "spamming";
                        </li>
                        <li>
                            promotes information that you know is false,
                            misleading or promotes illegal activities or conduct
                            that is abusive, threatening, obscene, defamatory or
                            libelous;
                        </li>
                        <li>
                            promotes an illegal or unauthorized copy of another
                            person's copyrighted work, such as providing pirated
                            computer programs or links to them, providing
                            information to circumvent manufacture-installed
                            copy-protect devices, or providing pirated music or
                            links to pirated music files;
                        </li>
                        <li>
                            contains restricted or password only access pages,
                            or hidden pages or images (those not linked to or
                            from another accessible page);
                        </li>
                        <li>
                            displays pornographic or sexually explicit material
                            of any kind;
                        </li>
                        <li>
                            provides material that exploits people under the age
                            of 18 in a sexual or violent manner, or solicits
                            personal information from anyone under 18;
                        </li>
                        <li>
                            provides instructional information about illegal
                            activities such as making or buying illegal weapons,
                            violating someone's privacy, or providing or
                            creating computer viruses;
                        </li>
                        <li>
                            solicits passwords or personal identifying
                            information for commercial or unlawful purposes from
                            other users; and
                        </li>
                        <li class="last_element">
                            engages in commercial activities and/or sales
                            without our prior written consent such as contests,
                            sweepstakes, barter, advertising, and pyramid
                            schemes
                        </li>
                    </ul>
                </li>
                <br />
                <li>
                    You must use the (TnYadavs.com/IndianYadavs.com) Service in a manner consistent
                    with any and all applicable local, state, and federal laws,
                    rules and regulations.
                </li>
                <br />
                <li>
                    You cannot include in your Member profile, visible to other
                    members, any telephone numbers, street addresses, URL's, and
                    email addresses.
                </li>
            </ol>

            <h3>Copyright Policy.</h3>
            <p>
                You cannot post, distribute, or reproduce in any way any
                copyrighted material, trademarks, or other proprietary
                information without obtaining the prior written consent of the
                owner of such proprietary rights.
            </p>
            <p>
                Without limiting the foregoing, if you believe that your work
                has been copied and posted on the (TnYadavs.com/IndianYadavs.com) service in a way
                that constitutes copyright infringement, please provide us with
                the following information:
            </p>
            <ul>
                <li>
                    an electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright interest;
                </li>
                <li>
                    a description of the copyrighted work that you claim has
                    been infringed;
                </li>
                <li>
                    a description of where the material that you claim is
                    infringing is located on the Site;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                    a written statement by you that you have a good faith belief
                    that the disputed use is not authorized by the copyright
                    owner, its agent, or the law;
                </li>
                <li>
                    a statement by you, made under penalty of perjury, that the
                    above information in your Notice is accurate and that you
                    are the copyright owner or authorized to act on the
                    copyright owner's behalf.
                </li>
            </ul>
            <p>
                Notice of claims of copyright infringement can be sent to us in
                writing to the Mumbai address located under the Help/Contact
                section on the site.
            </p>

            <h3>Your Interaction with other Members &amp; Member Disputes.</h3>
            <p>
                You are solely responsible for your advertisement/communications
                with other (TnYadavs.com/IndianYadavs.com) Members. (TnYadavs.com/IndianYadavs.com) reserves the right,
                but has no obligation, to monitor disputes between you and other
                Members. (TnYadavs.com/IndianYadavs.com) also reserves the right to take appropriate
                action against errant members. However, (TnYadavs.com/IndianYadavs.com) is not
                obliged to share such action with other members, including
                complainants. (TnYadavs.com/IndianYadavs.com) expressly disclaims any responsibility
                or liability for any transactions or interactions between the
                members inter-se.
            </p>

            <h3>Contributions towards COVID-19 - Mission Immunity</h3>
            <ul>
                <li>
                    Your contribution towards COVID-19 shall be utilized for
                    those who affected by COVID-19.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) is only facilitating the transactions on
                    <a
                        href="https://www.tnyadavs.com"
                        class="link"
                        target="_blank"
                        >www.tnyadavs.com</a
                    >
                    for collecting the contribution towards COVID-19.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) shall not be responsible for the end use of the
                    amounts being contributed.
                </li>
                <li class="last_element">
                    For any reason if the (TnYadavs.com/IndianYadavs.com) agrees to refund the amount
                    paid for subscription, in such an event the amount
                    contributed to Shaadicares shall not be liable for refund.
                </li>
            </ul>

            <h3>Privacy.</h3>
            <p>
                Use of the (TnYadavs.com/IndianYadavs.com) Site and/or the (TnYadavs.com/IndianYadavs.com) Service is
                governed by our
                <a
                    href="/shaadi-info/index/privacy"
                    class="link"
                    target="_blank"
                    >Privacy Policy</a
                >
                and follow guidelines to protect your privacy.
            </p>

            <h3>Disclaimers.</h3>
            <ul>
                <li>
                    (TnYadavs.com/IndianYadavs.com) is not responsible for any incorrect or
                    inaccurate Content/listing posted on the Site or in
                    connection with the (TnYadavs.com/IndianYadavs.com) Service, whether caused by
                    Users, Members or by any of the equipment or programming
                    associated with or utilized in the Service, nor for the
                    conduct of any User and/or Member of the (TnYadavs.com/IndianYadavs.com) Service
                    whether online or offline.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) assumes no responsibility for any error,
                    omission, interruption, deletion, defect, delay in operation
                    or transmission, communications line failure, theft or
                    destruction or unauthorized access to, or alteration of,
                    User and/or Member communications or any communications by
                    (TnYadavs.com/IndianYadavs.com) to its Members.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) is not responsible for any problems or technical
                    malfunction of any telephone network or lines, computer
                    on-line-systems, servers or providers, computer equipment,
                    software, failure of email or players on account of
                    technical problems or traffic congestion on the Internet or
                    at any web site or combination thereof, including injury or
                    damage to Users and/or Members or to any other person's
                    computer related to or resulting and/or in connection with
                    the (TnYadavs.com/IndianYadavs.com) Service.
                </li>
                <li>
                    Under no circumstances will (TnYadavs.com/IndianYadavs.com) be responsible for
                    any loss or damage resulting from anyone's use of the Site
                    or the Service and/or any Content posted on the (TnYadavs.com/IndianYadavs.com)
                    Site or transmitted to (TnYadavs.com/IndianYadavs.com) Members. You should not
                    provide your financial information (for example, your credit
                    card or bank account information), or wire or otherwise send
                    money, to other Members.
                </li>
                <li>
                    The exchange of matchmaking profile(s) through or by
                    "(TnYadavs.com/IndianYadavs.com)" is not a matrimonial offer and/or
                    recommendation from/by (TnYadavs.com/IndianYadavs.com). (TnYadavs.com/IndianYadavs.com) shall not be
                    responsible for any loss or damage to any individual arising
                    out of, or subsequent to, any relations (including but not
                    limited to matrimonial relations) established pursuant to
                    the use of (TnYadavs.com/IndianYadavs.com) service.
                </li>
                <li>
                    The Site and the Service are provided "AS-IS" and (TnYadavs.com/IndianYadavs.com)
                    expressly disclaims any warranty of fitness for a particular
                    purpose or non-infringement. (TnYadavs.com/IndianYadavs.com) cannot guarantee and
                    does not promise any specific results from use of the Site
                    and/or the (TnYadavs.com/IndianYadavs.com) Service. You understand that
                    (TnYadavs.com/IndianYadavs.com) makes no guarantees, either express or implied,
                    regarding compatibility with individuals you meet through
                    the Service.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) expressly disclaims any liability or
                    responsibility whatsoever and howsoever arising as a result
                    of any Content/listing posted on the Site / made available
                    to (TnYadavs.com/IndianYadavs.com) by any Members or the users of the Site or any
                    third party.
                </li>
                <li>
                    (TnYadavs.com/IndianYadavs.com) does not assume any responsibility or liability
                    for any illegal Content posted on the Site by any Members,
                    users or any third party.
                </li>
                <li class="last_element">
                    All liability, whether civil or criminal arising out of any
                    Content that is Posted on the Site will be of that Member /
                    user / third party who has Posted such Content and
                    (TnYadavs.com/IndianYadavs.com) reserves its right to claim damages from such
                    Member/ user / third party that it may suffer as a result of
                    such Content Posted on the Site. (TnYadavs.com/IndianYadavs.com) does not claim
                    ownership of Content you submit or make available for
                    inclusion on the Service.
                </li>
            </ul>

            <h3>Limitation on Liability.</h3>
            <p>
                In no event will (TnYadavs.com/IndianYadavs.com) be liable to you or any third person
                for any indirect, consequential, exemplary, incidental, special
                or punitive damages, including also lost profits arising from
                your use of the Site or the (TnYadavs.com/IndianYadavs.com) Service, even if
                (TnYadavs.com/IndianYadavs.com) has been advised of the possibility of such damages.
                Notwithstanding anything to the contrary contained herein,
                (TnYadavs.com/IndianYadavs.com)'s liability to you for any cause whatsoever, and
                regardless of the form of the action, will at all times be
                limited to the amount paid, if any, by you to (TnYadavs.com/IndianYadavs.com), for
                the Service during the term of membership.
            </p>

            <h3>Jurisdiction.</h3>
            <p>
                If there is any dispute about or involving the Site and/or the
                Service, by using the Site, you unconditionally agree that all
                such disputes and/or differences will be governed by the laws of
                India and shall be subject to the exclusive jurisdiction of the
                Competent Courts in Mumbai, India only.
            </p>

            <h3>Indemnity.</h3>
            <p>
                You agree to indemnify and hold (TnYadavs.com/IndianYadavs.com), its subsidiaries,
                affiliates, officers, agents, and other partners and employees,
                fully indemnified and harmless from any loss, liability, claim,
                or demand, including reasonable attorney's fees, made by any
                third party due to or arising out of your use of the Service in
                violation of this Agreement and/or arising from a breach of
                these Terms of Use and/or any breach of your representations and
                warranties set forth above and/or any fraudulent act on your
                part.
            </p>

            <h3>Other.</h3>
            <ul>
                <li>
                    By becoming a Member of the (TnYadavs.com/IndianYadavs.com) service, you agree to
                    receive certain specific email from (TnYadavs.com/IndianYadavs.com) and/or its
                    group sites. You can unsubscribe at any time for any reason
                    quickly and easily by clicking on the unsubscribe link
                    enclosed in the mail. You can also manage your subscription
                    to these emails using the My Alert Manager option.
                </li>
                <li>
                    This Agreement, accepted upon use of the Site and further
                    affirmed by becoming a Member of the (TnYadavs.com/IndianYadavs.com) service,
                    contains the entire agreement between you and (TnYadavs.com/IndianYadavs.com)
                    regarding the use of the Site and/or the Service. If any
                    provision of this Agreement is held invalid, the remainder
                    of this Agreement shall continue in full force and effect.
                </li>
            </ul>
            <p>
                By becoming a member of (TnYadavs.com/IndianYadavs.com) and/or using the Services of
                the Site, you unconditionally and irrevocably confirm that you
                have read and understood the above provisions and agree to abide
                by them. If you are not agreeing to any of the above terms and
                conditions please refrain yourself from registering on the Site.
                <br />
                (TnYadavs.com/IndianYadavs.com) is a trademark of People Interactive (I) Pvt. Ltd.
                <br />
                This Agreement is an electronic document in terms of the
                Information Technology Act, 2000 and Rules there under
                pertaining to electronic records as applicable and amended. This
                electronic record is generated by a computer system and does not
                require any physical or digital signatures.
            </p>
        </div>
    </div>
</div>
<!-- End Terms & Conditions -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
