import { StorageService } from '../../_services/local-storage/storage.service';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../_services/profile/profile.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUpload } from 'src/app/profile/file-upload/file-upload.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StaticDataService } from 'src/app/_services/static-data/static-data.service';

@Component({
    selector: 'app-edit-top',
    templateUrl: './edit-top.component.html',
    styleUrls: ['./edit-top.component.scss'],
})
export class EditProfileTopComponent implements OnInit {
    public currentUser;
    closeResult: string;
    userId: number = this.storageService.getUser().id;
    profile;
    states = this.staticData.stateList;
    maritalStatusList = this.staticData.maritalStatusList;
    constructor(
        public profileService: ProfileService,
        private modalService: NgbModal,
        private storageService: StorageService,
        private domSanitizer: DomSanitizer,
        private staticData: StaticDataService
    ) {
        this.currentUser = this.profileService.currentActiveUser;
        this.profileService.currentActiveUserSubject.subscribe((user) => {
            this.profileService
                .getProfileByUserId(user.id)
                .subscribe((data) => {
                    this.profile = data;
                });
        });
        this.staticData.stateSubject.subscribe((data) => {
            this.states = data;
        });
        this.staticData.maritalStatusSubject.subscribe(
            (data) => (this.maritalStatusList = data)
        );
    }

    getMaritalStatus(id) {
        return this.maritalStatusList.find((ms) => ms.id === id)?.name;
    }

    getState(stateId) {
        return this.states.find((s) => s.id == stateId)?.name;
    }

    ngOnInit(): void {}

    loadProfileImage() {
        this.currentUser = this.storageService.getUser();
        this.profileService
            .getProfileImageByUserId(this.currentUser.id)
            .subscribe((res: any) => {
                if (res && res.content) {
                    let src = `data:${res.fileType};base64, ${res.content}`;
                    this.profileService.profileImage =
                        this.domSanitizer.bypassSecurityTrustUrl(src) as string;
                } else {
                    this.profileService.profileImage =
                        this.currentUser.gender == 'MALE'
                            ? 'assets/img/home-two/banners/male.svg'
                            : 'assets/img/home-two/banners/female.svg';
                }
            });
    }

    imageUpload() {
        this.open();
    }

    open() {
        const size = { size: 'xl', centered: true };
        const modalRef = this.modalService.open(FileUpload, size);
        modalRef.componentInstance.userId = this.userId;
        modalRef.componentInstance.galleryType = 'PROFILE_IMAGE';
        modalRef.result.then(
            (result) => this.loadProfileImage(),
            (reason) => this.setDismissReason(reason)
        );
    }

    private setDismissReason(reason: ModalDismissReasons): void {
        let reasonStr = '';
        if (reason === ModalDismissReasons.ESC) {
            reasonStr = 'Dismissed By ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            reasonStr = 'Dismissed By Backdrop';
        } else {
            reasonStr = `Dismissed By with: ${reason}`;
        }
        this.closeResult = reasonStr;
    }
}
