import { StorageService } from './../_services/local-storage/storage.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private storageService:StorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUserToken = this.storageService.getToken() || '';
        if (currentUserToken !="" && currentUserToken !=null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUserToken}`,
                    Header:'application/json'
                }
            });
        }

        return next.handle(request);
    }
}
