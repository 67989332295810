<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Employers</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Employers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="e.g UI/UX Design">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Age</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Category</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Location</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Languages</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Qualifications</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <select>
                            <option>Filter By Experiences</option>
                            <option>Another option</option>
                            <option>A option</option>
                            <option>Potato</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <button type="submit" class="btn cmn-btn">Search By Filter <i class='bx bx-plus'></i></button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Filter -->

<!-- Showing -->
<div class="job-showing-area">
    <div class="container">
        <h4>Showing 1 - 8 of 11 results</h4>

        <div class="showing">
            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="left">
                        <div class="form-group">
                            <select>
                                <option>Newest</option>
                                <option>Another option</option>
                                <option>A option</option>
                                <option>Potato</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="right">
                        <ul>
                            <li><a routerLink="/"><i class='bx bx-dots-horizontal'></i></a></li>
                            <li><a routerLink="/"><i class='bx bx-menu'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Showing -->

<!-- Employer -->
<div class="employer-area two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job1.png" alt="Employer">
                        <h3>Product Designer</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Los Angeles, CS, USA</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>12 Ratings</span></li>
                        </ul>
                        <p>We are Looking for a skilled Ul/UX designer amet conscu adiing elitsed do eusmod tempor</p>
                        <span class="span-one">Accounting</span>
                        <span class="span-two">FULL TIME</span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job2.png" alt="Employer">
                        <h3>Sr. Shopify Developer</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Houston, TX, USA</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>15 Ratings</span></li>
                        </ul>
                        <p>Responsible for managing skilled Ul/UX designer amet conscu adiing elitsed do eusmod</p>
                        <span class="span-one">Accounting</span>
                        <span class="span-two two">FULL TIME</span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job3.png" alt="Employer">
                        <h3>Tax Manager</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Ho Chi Minh City, Vietnam</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>2 Ratings</span></li>
                        </ul>
                        <p>International collaborative a skilled Ul/UX designer amet conscu adiing elitsed do eusmod</p>
                        <span class="span-one two">Broardcasting</span>
                        <span class="span-two three">FREELANCER</span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job4.png" alt="Employer">
                        <h3>Senior Data Engineer</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Cardiss, UK</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>5 Ratings</span></li>
                        </ul>
                        <p>International collaborative designer amet conscu adiing elitsed do eusmod tempor</p>
                        <span class="span-one three">Web & Software Dev</span>
                        <span class="span-two four">REMOTE</span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job5.png" alt="Employer">
                        <h3>Construction Worker</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Adelaide SA, Australia</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>4 Ratings</span></li>
                        </ul>
                        <p>We are Looking for a skilled Ul/UX designer amet conscu adiing elitsed do eusmod tempor</p>
                        <span class="span-one">Accounting</span>
                        <span class="span-two">FULL TIME</span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="employer-item">
                    <a routerLink="/job-details">
                        <img src="assets/img/home-one/job6.png" alt="Employer">
                        <h3>Product Manager</h3>
                        <ul>
                            <li><i class="flaticon-send"></i> Warangal, Telangana, India</li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><span>7 Ratings</span></li>
                        </ul>
                        <p>Wind Power Engineering Manager amet conscu adiing elitsed do eusmod tempor</p>
                        <span class="span-one four">Customer Service</span>
                        <span class="span-two">FULL TIME</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="pagination-area">
            <ul>
                <li><a routerLink="/employers">Prev</a></li>
                <li><a routerLink="/employers">1</a></li>
                <li><a routerLink="/employers">2</a></li>
                <li><a routerLink="/employers">3</a></li>
                <li><a routerLink="/employers">Next</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Employer -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->