import { StorageService } from './../../_services/local-storage/storage.service';
import { StaticDataService } from './../../_services/static-data/static-data.service';
import { AlertService } from './../../_alert/alert.service';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
    selector: 'app-home-search',
    templateUrl: './home-search.component.html',
    styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {
    public homeSearchForm !: FormGroup

    submitted = false;
    dropdownList = [];
    token;
    subCasteList: any = [];
    selectedSubCaste: string = "";
    genderList: any = [];
    selectedGender: string = "";
    ageToList: any = [];
    selectedAgeTo: string = "";
    ageFromList: any = [];
    selectedAgeFrom: string = "";
    languageList: any = [];
    selectedLanguage: string = "";
    dropdownSettings: IDropdownSettings = {};
    dropdownSettingSingle: IDropdownSettings = {};
    constructor(private alertService: AlertService, private formBuilder: FormBuilder, private staticDataService: StaticDataService, private storageService: StorageService) { }

    ngOnInit(): void {

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select',
            unSelectAllText: 'UnSelect',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };

        this.dropdownSettingSingle = {
            singleSelection: true,
            closeDropDownOnSelection: true,
            selectAllText: 'Select',
            unSelectAllText: 'UnSelect',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };

        this.homeSearchForm = this.formBuilder.group({
            gender: [''],
            subCaste: [[]],
            language: [[]],
            ageFrom: [''],
            ageTo: ['']
        })

        /* this.token = this.storageService.getToken();
        if (this.token != '' && this.token != null) {
            this.loadAllValues();
        }*/

    }

    get f(): { [key: string]: AbstractControl } {
        return this.homeSearchForm.controls;
    }

    onHomeSearchFormSubmit(): void {
        this.submitted = true;
        if (this.homeSearchForm.invalid) {
            return;
        }

    }

    loadAllValues() {

        this.staticDataService.languageSubject.subscribe(data => {
            this.languageList = data;
        })

        this.staticDataService.subCasteSubject.subscribe(data => {
            this.subCasteList = data;
        })

        this.ageFromList = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45]
        this.ageToList = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45]
        this.genderList = ["Male", "Female"];

    }

}
